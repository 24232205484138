import React from 'react';

import { Routes } from '../../routes/routes';
import { getRoute } from '../../routes/navigate';
import { useDefaultNavigationOptions } from '../../routes/hooks/navigation-options-hook';

import { ChangeEmailContainer } from './change-email-container';

const ChangeEmailScreen = (): JSX.Element => {
  useDefaultNavigationOptions();
  return <ChangeEmailContainer />;
};

export const { action: toChangeEmail, screen: changeEmailScreen } = getRoute({
  routeName: Routes.ChangeEmail,
  screenComponent: ChangeEmailScreen,
});

export default ChangeEmailScreen;
