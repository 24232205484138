import { CountryCodes } from '@taxfix/types';

export const validatePhoneNumber = (phoneNumber: string, countryCode?: CountryCodes): boolean => {
  switch (countryCode) {
    case CountryCodes.IT:
      return /^[+]?[\d\s-()]{6,20}$/.test(phoneNumber);

    case CountryCodes.DE:
      return true;

    default:
      return false;
  }
};
