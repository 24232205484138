import Config from 'react-native-config';
import { getVersion, getApplicationName } from 'react-native-device-info';
import _ from 'lodash';
import { CountryCodes } from '@taxfix/types';

import { FILING_EXPERIENCE } from 'src/taxfix-business-logic/constants/question-answer';
import { getQuestionStoreForYear } from 'src/stores-legacy/util';

import { getStore } from '../../stores/util';
import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { getBrazeWhitelist as getBrazeWhitelistSelector } from '../../stores/selectors/remote-config-firebase';
import { selectors as brazeSelectors } from '../../stores/modules/braze';
import { selectors as codePushSelectors } from '../../stores/modules/code-push';
import { LanguageCode } from '../../types';
import { isWeb, isMobile } from '../../utils/platform';
import { SentryErrorTracking } from '../../services/sentry/sentry-error-tracking';
import { NativeModules } from '../nativeModules';

import { GTM } from './gtm';
import singular, { setupSingular } from './singular';

// XXX: can we do this better with `declare` semantics in flow?
type AnalyticsType = {
  identify: (traits: Record<string, any> | null | undefined, options: any) => void;
  identifyWithUser: (
    userId: number,
    traits: Record<string, any> | null | undefined,
    options: any,
  ) => void;
  getAdvertisingId: () => Promise<string>;
  getAnonymousId: () => Promise<string>;
  track: (event: string, properties: Record<string, any>, options: Record<string, any>) => void;
  flush: () => Promise<boolean>;
  enable: () => void;
  disable: () => void;
  reset?: () => void;
  getBrazeDeviceId: () => Promise<string>;
};
// test stub:
const AnalyticsStub = {
  identify: () => {},
  track: () => {},
  flush: () => {},
  enable: () => {},
  disable: () => {},
  getBrazeDeviceId: () => {},
};
const AnalyticsNative = (NativeModules.Analyticsify as AnalyticsType) || AnalyticsStub;
enum AnalyticsEvent {
  taxYearStatus = 'taxYearStatus',
  sectionCategories = 'sectionCategories',
  sectionDeclarationResult = 'sectionDeclarationResult',
  sectionDeclarationResultMehrInfoPressed = 'sectionDeclarationResultMehrInfoPressed',
  sectionReceiptsInitiated = 'sectionReceiptsInitiated',
  sectionLogIn = 'sectionLogIn',
  sectionSignUp = 'sectionSignUp',
  sectionSignUpPhone = 'sectionSignUpPhone',
  sectionOnboarding = 'sectionOnboarding',
  sectionNotifications = 'sectionNotifications',
  sectionNotificationsVariant = 'sectionNotificationsVariant',
  sectionPayment = 'sectionPayment',
  sectionPaymentSlip = 'sectionPaymentSlip',
  sectionSubmission = 'sectionSubmission',
  sectionSubmissionSteps = 'sectionSubmissionSteps',
  sectionTermsAndConditions = 'sectionTermsAndConditions',
  sectionVerifyYourIdentity = 'sectionVerifyYourIdentity',
  sectionWhoAreYou = 'sectionWhoAreYou',
  sectionPostSubmission = 'sectionPostSubmission',
  sectionAccount = 'sectionAccount',
  sectionBankAccount = 'sectionBankAccount',
  categoryStarted = 'categoryStarted',
  categoryFinished = 'categoryFinished',
  categorySelected = 'categorySelected',
  categoryReviewed = 'categoryReviewed',
  allCategoriesCompleted = 'allCategoriesCompleted',
  questionFlowCompleted = 'questionFlowCompleted',
  appOpen = 'appOpen',
  appLanding = 'appLanding',
  logInSuccess = 'logInSuccess',
  logInFailed = 'logInFailed',
  question = 'question',
  serviceContinueSuccess = 'serviceContinueSuccess',
  serviceContinueFailed = 'serviceContinueFailed',
  serviceContinueNotSupported = 'serviceContinueNotSupported',
  signUpSuccess = 'signUpSuccess',
  signUpFailed = 'signUpFailed',
  submissionSuccess = 'submissionSuccess',
  submissionFailed = 'submissionFailed',
  identificationUploadFailed = 'identificationUploadFailed',
  identificationUploadSuccessful = 'identificationUploadSuccessful',
  // identification
  identificationUploadInitiated = 'identificationUploadInitiated',
  identificationCloneDocumentSucceed = 'identificationCloneDocumentSucceed',
  identificationCloneDocumentFailed = 'identificationCloneDocumentFailed',
  identificationInfoButtonPressed = 'identificationInfoButtonPressed',
  wrongAddressPressed = 'wrongAddressPressed',

  // Data request
  dataFetchFailed = 'dataFetchFailed',
  // OTC
  otcEntryRequested = 'otcEntryRequested',
  otcEntrySuccessful = 'otcEntrySuccessful',
  otcEntryFailed = 'otcEntryFailed',
  emailResendInitiated = 'emailResendInitiated',
  questionAnswered = 'questionAnswered',
  questionEdited = 'questionEdited',
  questionSkipped = 'questionSkipped',
  questionHelp = 'questionHelp',
  registrationInitiated = 'registrationInitiated',
  requestSupportInitiated = 'requestSupportInitiated',
  sectionNotificationAccepted = 'sectionNotificationAccepted',
  sectionNotificationNotAccepted = 'sectionNotificationNotAccepted',
  sectionPostSubmissionNotification = 'sectionPostSubmissionNotification',
  signUpInitiated = 'signUpInitiated',
  signUpProgress = 'signUpProgress',
  submissionInitiated = 'submissionInitiated',
  termsAndConditionsSuccess = 'termsAndConditionsSuccess',
  verifyYourIdentityInitiated = 'verifyYourIdentityInitiated',
  isEmailVerified = 'isEmailVerified',
  // Payslip
  navigationBackButtonPressed = 'navigationBackButtonPressed',
  sectionPayslipUploadInstructions = 'sectionPayslipUploadInstructions',
  payslipScanInitiated = 'payslipScanInitiated',
  payslipImportInitiated = 'payslipImportInitiated',
  payslipManualInitiated = 'payslipManualInitiated',
  screenPayslipInfo1 = 'screenPayslipInfo1',
  screenPayslipInfo2 = 'screenPayslipInfo2',
  screenPayslipInfo3 = 'screenPayslipInfo3',
  scanbotCameraOpened = 'scanbotCameraOpened',
  scanbotCameraClosed = 'scanbotCameraClosed',
  scanbotCameraPictureTaken = 'scanbotCameraPictureTaken',
  scanPayslipTryAgain = 'scanPayslipTryAgain',
  sectionImagePreview = 'sectionImagePreview',
  screenCameraOverlay = 'screenCameraOverlay',
  cameraClickButtonPressed = 'cameraClickButtonPressed',
  screenPayslipPreview = 'screenPayslipPreview',
  payslipUploadInitiated = 'payslipUploadInitiated',
  screenPayslipReview = 'screenPayslipReview',
  payslipConfirmationInitiated = 'payslipConfirmationInitiated',
  payslipScanValuesConfirmationInitiated = 'payslipScanValuesConfirmed',
  screenPayslipPendingSummary = 'screenPayslipPendingSummary',
  ocrPayslipGenerated = 'ocrPayslipGenerated',
  'payslip.uploading.not-paylsip' = 'documentNotIdentifiedAsPayslipError',
  'payslip.uploading.unable-to-read-image' = 'unreadablePayslipError',
  'payslip.uploading.exceeds-limit' = 'payslipFileIsTooBig',
  'payslip.uploading.corrupted-payslip' = 'corruptedPayslipError',
  'payslip.uploading.more-than-one-page' = 'moreThanOnePagePayslipError',
  'payslip.uploading.not-2016' = 'payslipNot2016Error',
  'payslip.uploading.connection-lost' = 'payslipConnectionLost',
  'payslip.uploading.something-went-wrong' = 'payslipUploadFailedError',
  'payslip.uploading.blurry-payslip' = 'blurryPayslipError',
  'uploading.file-size-limit-error' = 'payslipFileExceedSize',
  payslipGoBack = 'payslipGoBack',
  payslipGoBackCameraTutorial = 'payslipGoBackCameraTutorial',
  payslipGoBackManualTutorial = 'paylipGoBackManualTutorial',
  payslipValidationFailed = 'payslipValidationFailed',
  payslipErrorsShown = 'payslipErrorsShown',
  payslipWarningsShown = 'payslipWarningsShown',
  payslipErrorsIgnored = 'payslipErrorsIgnored',
  payslipWarningsIgnored = 'payslipWarningsIgnored',
  payslipFormInfoNotChanged = 'payslipFormInfoNotChanged',
  payslipFormInfoChanged = 'payslipFormInfoChanged',
  payslipCompletionHappyMomentShown = 'payslipCompletionHappyMomentShown',
  positiveResult = 'positiveResult',
  termsAndConditionsPressed = 'termsAndConditionsPressed',
  privacyPolicyPressed = 'privacyPolicyPressed',
  elsterInformationPressed = 'elsterInformationPressed',
  detailsButtonPressed = 'detailsButtonPressed',
  pdfRequestButtonPressed = 'pdfRequestButtonPressed',
  pdfShareButtonPressed = 'pdfShareButtonPressed',
  emailNotVerifiedPaymentInitiated = 'emailNotVerifiedPaymentInitiated',
  emailNotVerifiedAlreadyPaidInitiated = 'emailNotVerifiedAlreadyPaidInitiated',
  alreadPaidPressed = 'alreadPaidPressed',
  postSubmissionAnotherYearChosenPressed = 'postSubmissionAnotherYearChosenPressed',
  postSubmissionViewRequiredDocuments = 'postSubmissionViewRequiredDocuments',
  postSubmissionContactSupportPressed = 'postSubmissionContactSupportPressed',
  postSubmissionAnotherYearAvailable = 'postSubmissionAnotherYearAvailable',
  postSubmissionFAQPressed = 'postSubmissionContactSupportPressed',
  postSubmissionSupportPressed = 'postSubmissionSupportPressed',
  notSupportedScreenAnotherYearChosenPressed = 'notSupportedScreenAnotherYearChosenPressed',
  sectionChooseFirstTaxYear = 'sectionChooseFirstTaxYear',
  sectionTaxYearOverview = 'sectionTaxYearOverview',
  firstTaxYearChosen = 'firstTaxYearChosen',
  anotherTaxYearChosen = 'anotherTaxYearChosen',
  continueTaxYearChosen = 'continueTaxYearChosen',
  submissionBannerPressed = 'submissionBannerPressed',
  sectionToDo = 'sectionToDo',
  sectionLock = 'sectionLock',
  chatClicked = 'chatClicked',
  categoryMoreInfoPressed = 'categoryMoreInfoPressed',
  dismissFeedbackModalPressed = 'dismissFeedbackModalPressed',
  feedbackModalInitiated = 'feedbackModalInitiated',
  feedbackModalHappyInitiated = 'feedbackModalHappyInitiated',
  feedbackDisappointedInitiated = 'feedbackDisappointedInitiated',
  feedbackScoreGiven = 'feedbackScoreGiven',
  openEmaillInitiated = 'openEmaillInitiated',
  nativeStoreReviewInitiated = 'nativeStoreReviewInitiated',
  openStoreInitiated = 'openStoreInitiated',
  sectionCalculationSteps = 'sectionCalculationSteps',
  sectionCalculationStepToggle = 'sectionCalculationStepToggle',
  backButtonClicked = 'backButtonClicked',
  calculatedDistance = 'calculatedDistance',
  nonStreetAddressCoordinates = 'nonStreetAddressCoordinates',
  taxNumberFormatError = 'taxNumberFormatError',
  ibanValidationError = 'ibanValidationError',
  bicValidationError = 'bicValidationError',
  distanceInMetresMigration = 'distanceInMetresMigration',
  refundBannerClicked = 'refundBannerClicked',
  refundBannerShown = 'refundBannerShown',
  bioAuthenticationOptIn = 'bioAuthenticationOptIn',
  bioAuthenticationOptOut = 'bioAuthenticationOptOut',
  bioAuthenticationLoginSuccess = 'bioAuthenticationLoginSuccess',
  bioAuthenticationLoginFailed = 'bioAuthenticationLoginFailed',
  bioAuthenticationError = 'bioAuthenticationError',
  optOutTracking = 'optOutTracking',
  toDoComplete = 'toDoComplete',
  toDoEmptyShown = 'toDoEmptyShown',
  paymentInformationChanged = 'paymentInformationChanged',
  sectionEndCard = 'sectionEndCard',
  endCardCTAPressed = 'endCardCTAPressed',
  todoOverviewPressed = 'todoOverviewPressed',
  resultOverviewPressed = 'resultOverviewPressed',
  nextCategoryOverviewPressed = 'nextCategoryOverviewPressed',
  tacChangeScreenShown = 'tacChangeScreenShown',
  tacChangeUpdateSuccessful = 'tacChangeUpdateSuccessful',
  tacChangeUpdateFailed = 'tacChangeUpdateFailed',
  privacyChangeScreenShown = 'B_inapp_fr_policyChangeScreenShown',
  privacyChangeUpdateSuccessful = 'B_inapp_fr_policyChangeUpdateSuccessful',
  privacyChangeUpdateFailed = 'B_inapp_fr_policyChangeUpdateFailed',
  questionFlowTransition = 'questionFlowTransition',
  validateCouponSuccess = 'validateCouponSuccess',
  validateCouponFailed = 'validateCouponFailed',
  //payslip improvements
  payslipWarningButtonClick = 'payslipWarningButtonClick',
  payslipWarningListScroll = 'payslipWarningListScroll',
  payslipEditIconClicked = 'payslipEditIconClicked',
  payslipTextFieldClicked = 'payslipTextFieldClicked',
  payslipErrorDetected = 'payslipErrorDetected',
  // data rollover
  dataRolloverExperimentActivated = 'dataRolloverExperimentActivated',
  rolledOverTaxYearStarted = 'rolledOverTaxYearStarted',
  rolledOverTaxYearDismissed = 'rolledOverTaxYearDismissed',
  rolledOverAnswerChanged = 'rolledOverAnswerChanged',
  // onboarding
  onboardingInfoHowto = 'onboardingInfoHowto',
  onboardingInfoFees = 'onboardingInfoFees',
  onboardingInfoSecurity = 'onboardingInfoSecurity',
  onboardingFaqContinue = 'onboardingFaqContinue',
  // checkout - personal category
  checkoutStateMachineStateChanged = 'checkoutStateMachineStateChanged',
  sectionPersonalDetailsScreen = 'sectionPersonalDetailsScreen',
  personalDetailsSuccess = 'personalDetailsSuccess',
  personalDetailsFailed = 'personalDetailsFailed',
  sectionCurrentAddressLegacy = 'sectionCurrentAddressLegacy',
  sectionTaxOffice = 'sectionTaxOffice',
  taxOfficeSuccess = 'taxOfficeSuccess',
  taxOfficeFailed = 'taxOfficeFailed',
  sectionTaxId = 'sectionTaxId',
  taxIdEntered = 'taxIdEntered',
  taxIdFailed = 'taxIdFailed',
  sectionBankDetails = 'sectionBankDetails',
  bankDetailsSuccess = 'bankDetailsSuccess',
  bankDetailsFailed = 'bankDetailsFailed',
  personalDetailsEdited = 'personalDetailsEdited',
  taxOfficeEdited = 'taxOfficeEdited',
  bankDetailsEdited = 'bankDetailsEdited',
  // FastLane
  completeIncomeAndFastlaneEligible = 'completeIncomeAndFastlaneEligible',
  fastlaneFileNowClicked = 'fastlaneFileNowClicked',
  fastlaneResultScreenSession = 'fastlaneResultScreenSession',
  fastlaneAdressCheckoutEdited = 'fastlaneAdressCheckoutEdited',
  fastlaneAddMoreExpensesClicked = 'fastlaneAddMoreExpensesClicked',
  fastlaneDeleteAndSubmitClicked = 'fastlaneDeleteAndSubmitClicked',
  fastlaneCompleteCategoryClicked = 'fastlaneCompleteCategoryClicked',
  fastlaneSaveTimeAndSubmitClicked = 'fastlaneSaveTimeAndSubmitClicked',
  fastlaneIncompleteCategoriesPopupShown = 'fastlaneIncompleteCategoriesPopupShown',
  deviceHasConnection = 'deviceHasConnection',
  connectionType = 'connectionType',
  effectiveConnectionType = 'effectiveConnectionType',
  applyingWayDataFromServer = 'applyingWayDataFromServer',
  applyingWayDataFromServerFail = 'applyingWayDataFromServerFail',
  sendDiagnosticSuccess = 'sendDiagnosticSuccess',
  sendDiagnosticFail = 'sendDiagnosticFail',
  answerSyncSave = 'answerSyncSave',
  answerSyncRetrieve = 'answerSyncRetrieve',
  reIdentifyPressed = 'reIdentifyPressed',
  editAddressPressed = 'editAddressPressed',

  // Tax Card Shown experiment
  taxTypeCardShown = 'taxTypeCardShown',
  firstOnboardingCardShown = 'firstOnboardingCardShown',
  secondOnboardingCardShown = 'secondOnboardingCardShown',
  thirdOnboardingCardShown = 'thirdOnboardingCardShown',
  preseasonScreenShown = 'preseasonScreenShown',
  preseasonEmailNotificationScreenShown = 'preseasonEmailNotificationScreenShown',
  notificationSignupInitiated = 'notificationSignupInitiated',
  notificationSignupCompleted = 'notificationSignupCompleted',
  taxCountryChanged = 'taxCountryChanged',
  taxIdFieldError = 'taxIdFieldError',
  taxIdFieldTouched = 'taxIdFieldTouched',
  // donation
  sectionDonation = 'sectionDonation',
  donationPreferencesAnswered = 'donationPreferencesAnswered',
  donationConfirmationChecked = 'donationConfirmationChecked',
  donationPermissionClicked = 'donationPermissionClicked',
  donationHelp = 'donationHelp',
  donationFailed = 'donationFailed',
  donationSuccess = 'donationSuccess',
  // consent
  sectionConsent = 'sectionConsent',
  consentExpandClicked = 'consentExpandClicked',
  consentAgreementChecked = 'consentAgreementChecked',
  consentPermissionClicked = 'consentPermissionClicked',
  consentFailed = 'consentFailed',
  consentSuccess = 'consentSuccess',
  amlConsentSuccess = 'amlConsentSuccess',
  spidConsentSuccess = 'spidConsentSuccess',
  // submit
  sectionSubmitForReview = 'sectionSubmitForReview',
  reSubmissionFlowFailed = 'reSubmissionFlowFailed',
  reSubmissionReviewInstructions = 'reSubmissionReviewInstructions',
  // prefill
  sectionIdUpload = 'sectionIdUpload',
  documentTypeChosen = 'documentTypeChosen',
  cardTypeChosen = 'cardTypeChosen',
  docViewFinder = 'docViewFinder',
  docPhotoTaken = 'docPhotoTaken',
  docUploadInitiated = 'docUploadInitiated',
  docUploadSuccess = 'docUploadSuccess',
  docUploadFailed = 'docUploadFailed',
  docUploadAgain = 'docUploadAgain',
  sectionIdSecondSide = 'sectionIdSecondSide',
  section7303Intro = 'section7303Intro',
  continue7303Pressed = 'continue7303Pressed',
  sectionMandate = 'sectionMandate',
  sectionYourEmployer = 'sectionYourEmployer',
  mandateSubmissionFailed = 'mandateSubmissionFailed',
  mandateSubmissionSuccess = 'mandateSubmissionSuccess',
  fetchPrefillFailed = 'fetchPrefillFailed',
  fetchPrefillSuccess = 'fetchPrefillSuccess',
  fetchPrefillRetrySuccess = 'fetchPrefillRetrySuccess',
  fetchPrefillRetryFailed = 'fetchPrefillRetryFailed',
  prefillFinished = 'prefillFinished',
  prefillCreationFailed = 'prefillCreationFailed',
  prefillCreationSuccess = 'prefillCreationSuccess',
  // prefill DE
  prefillCTAPressed = 'prefillCTAPressed',
  prefillCTASeen = 'prefillCTASeen',
  prefillOnboardingOpened = 'prefillOnboardingOpened',
  sectionPrefillOverview = 'sectionPrefillOverview',
  prefillRevokeStarted = 'prefillRevokeStarted',
  prefillRevokeSuccess = 'prefillRevokeSuccess',
  prefillRevokeFailed = 'prefillRevokeFailed',
  mandateDownloadPressed = 'mandateDownloadPressed',
  sectionPrefillStatus = 'sectionPrefillStatus',
  prefillPrimarySingleCodeEntered = 'prefillPrimarySingleCodeEntered',
  prefillPrimaryJointCodeEntered = 'prefillPrimaryJointCodeEntered',
  prefillPartnerJointCodeEntered = 'prefillPartnerJointCodeEntered',
  sectionPrefillActivation = 'sectionPrefillActivation',
  sectionPrefillPreview = 'sectionPrefillPreview',
  prefillDataViewed = 'prefillDataViewed',
  prefillCategoryViewed = 'prefillCategoryViewed',
  sectionPrefillActivationCodeEntry = 'sectionPrefillActivationCodeEntry',
  prefillDataEdited = 'prefillDataEdited',
  prefillPayslipDataRemoved = 'prefillPayslipDataRemoved',
  prefillFaqOpened = 'prefillFaqOpened',
  prefillFaqSectionOpened = 'prefillFaqSectionOpened',
  prefillFaqArticleOpened = 'prefillFaqArticleOpened',
  prefillFaqSeen = 'prefillFaqSeen',
  prefillRequestNewCodePressed = 'prefillRequestNewCodePressed',
  payslipSkippedForPrefill = 'payslipSkippedForPrefill',
  prefillCTAIgnored = 'prefillCTAIgnored',
  prefillRegistrationOptinAbTestActivated = 'prefillRegistrationOptinAbTestActivated',
  prefillCtaContinued = 'prefillCtaContinued',
  prefillFetchSuccessTime = 'prefillFetchSuccessTime',
  prefillFetchingFailedAfter60Seconds = 'prefillFetchingFailedAfter60Seconds',

  // prefill ES
  prefillMethodRequested = 'prefillMethodRequested',
  clavePinRequestSuccess = 'clavePinRequestSuccess',
  clavePinRequestFailed = 'clavePinRequestFailed',
  clavePinInputSubmitted = 'clavePinInputSubmitted',
  clavePinInputSuccess = 'clavePinInputSuccess',
  clavePinInputFailed = 'clavePinInputFailed',
  clavePinResendRequested = 'clavePinResendRequested',
  box505InputFailed = 'box505InputFailed',
  userDetailsCreateRequestFailed = 'userDetailsCreateRequestFailed',
  userDetailsUpdateRequestFailed = 'userDetailsUpdateRequestFailed',
  saveRefNumberFailed = 'saveRefNumberFailed',

  // payment
  paymentTermsClicked = 'paymentTermsClicked',
  paymentSuccess = 'paymentSuccess',
  paymentFailed = 'paymentFailed',
  paymentInitiated = 'paymentInitiated',
  paymentUpdatedClient = 'paymentUpdatedClient',
  paymentUpdateErrorClient = 'paymentUpdateErrorClient',
  paymentCreatedClient = 'paymentCreatedClient',
  paymentCreateErrorClient = 'paymentCreateErrorClient',
  // required-documents (receipt)
  sectionUploadReceipts = 'sectionUploadReceipts',
  receiptCategorySelected = 'receiptCategorySelected',
  receiptConfirmationChecked = 'receiptConfirmationChecked',
  receiptConfirmedClicked = 'receiptConfirmedClicked',
  receiptCategoryStarted = 'receiptCategoryStarted',
  addReceiptClicked = 'addReceiptClicked',
  deleteReceiptClicked = 'deleteReceiptClicked',
  deleteReceiptConfirmed = 'deleteReceiptConfirmed',
  deleteReceiptCancelled = 'deleteReceiptCancelled',
  receiptViewFinder = 'receiptViewFinder',
  receiptPhotoTaken = 'receiptPhotoTaken',
  receiptUploadInitiated = 'receiptUploadInitiated',
  receiptUploadSuccess = 'receiptUploadSuccess',
  receiptUploadFailed = 'receiptUploadFailed',
  uploadOverlayClicked = 'uploadOverlayClicked',
  filesRejectionReasons = 'filesRejectionReasons',
  fileReadingError = 'fileReadingError',
  documentFolderUpdate = 'documentFolderUpdate',
  // status screen
  sectionJourney = 'sectionJourney',
  sectionJourneyFailed = 'sectionJourneyFailed',
  sectionJourneyTryAgainClicked = 'sectionJourneyTryAgainClicked',
  prefillStarted = 'prefillStarted',
  questionFlowStarted = 'questionFlowStarted',
  receiptsUploadInitiated = 'receiptsUploadInitiated',
  filingFlowStarted = 'filingFlowStarted',
  downloadDeclarationPDFInitiated = 'downloadDeclarationPDFInitiated',
  commercilistaPolicyPressed = 'commercilistaPolicyPressed',
  sectionJourneyInfoboxClicked = 'sectionJourneyInfoboxClicked',
  // app init - app-blocking screen
  sectionForceUpdate = 'sectionForceUpdate',
  sectionMaintenance = 'sectionMaintenance',
  forceUpdateCTAPressed = 'forceUpdateCTAPressed',
  // Firebase Dynamic Link
  AppOpenedFromDynamicLink = 'AppOpenedFromDynamicLink',
  AppOpenedFromDynamicLinkError = 'AppOpenedFromDynamicLinkError',
  // Referral Program
  sectionReferral = 'sectionReferral',
  referralVoucherCreationPressed = 'referralVoucherCreationPressed',
  referralVoucherCreationSuccess = 'referralVoucherCreationSuccess',
  referralVoucherCreationError = 'referralVoucherCreationError',
  referralDynamicLinkSent = 'referralDynamicLinkSent',
  referralPopupDynamicLinkSent = 'referralPopupDynamicLinkSent',
  referralDynamicLinkCancel = 'referralDynamicLinkCancel',
  referralVoucherApplySuccess = 'referralVoucherApplySuccess',
  referralVoucherApplyError = 'referralVoucherApplyError',
  referralVoucherBecameReady = 'referralVoucherBecameReady',
  referralVoucherBecameReadyFailed = 'referralVoucherBecameReadyFailed',
  referralPopup = 'referralPopup',
  referralPopupContinue = 'referralPopupContinue',
  referralStatusScreenSeen = 'referralStatusScreenSeen',
  referralVoucherFetchSuccess = 'referralVoucherFetchSuccess',
  referralVoucherFetchFailed = 'referralVoucherFetchFailed',
  // Support Center
  b_inapp_ContactSupportClosed = 'b_inapp_ContactSupportClosed',
  b_inapp_ContactSupportPressCall = 'b_inapp_ContactSupportPressCall',
  b_inapp_ContactSupportPressEmail = 'b_inapp_ContactSupportPressEmail',
  b_inapp_ContactSupportPressWhatsapp = 'b_inapp_ContactSupportPressWhatsapp',
  contactFormSubmitPressed = 'contactFormSubmitPressed',
  contactSupportChatButtonPressed = 'contactSupportChatButtonPressed',
  supportButtonClicked = 'supportButtonClicked',

  // In App Rating
  inAppRatingSubmitted = 'inAppRatingSubmitted',
  inAppStoreRatingPressed = 'inAppStoreRatingPressed',

  // Experiments
  payslipUploadInstructionsExperimentActivation = 'payslipUploadInstructionsExperimentActivation',
  onboardingExperimentsTaxYearPopupOpened = 'onboardingExperimentsTaxYearPopupOpened',
  onboardingExperimentsTaxYearPopupPressed = 'onboardingExperimentsTaxYearPopupPressed',
  onboardingExperimentsTaxTypeCardConditionMet = 'onboardingExperimentsTaxTypeCardConditionMet',
  onboardingTaxTypeCardConditionMet = 'onboardingTaxTypeCardConditionMet',
  questionAnsweredEmployed = 'questionAnsweredEmployed',
  payslipGuidanceStep = 'payslipGuidanceStep',
  payslipGuidanceOnNextClick = 'payslipGuidanceOnNextClick',
  resultScreenButtonClicked = 'resultScreenButtonClicked',
  resultScreenLinkClicked = 'resultScreenLinkClicked',
  resultScreenExperimentActivated = 'resultScreenExperimentActivated',
  reviewAnswersButtonPressed = 'reviewAnswersButtonPressed',
  languageSelected = 'languageSelected',
  sectionOnboardingNonGermanDevice = 'sectionOnboardingNonGermanDevice',
  registrationNotificationActivation = 'registrationNotificationActivation',
  installationToRegistrationExperimentActivated = 'installationToRegistrationExperimentActivated',

  // refund banner
  headerLiveRefundBannerShown = 'headerLiveRefundBannerShown',
  headerLiveRefundBannerDisplayedAmount = 'headerLiveRefundBannerDisplayedAmount',
  headerLiveRefundBannerAmountShown = 'headerLiveRefundBannerAmountShown',
  incomeCategoryFinishedFirst = 'incomeCategoryFinishedFirst',
  // short-work, unemployment tracking (keeping the name used for KAFF campaign to not break the data)
  specialFreeEnabled = 'specialFreeEnabled',
  // EB experiment
  sectionEarlyBirdExperimentRouter = 'sectionEarlyBirdExperimentRouter',
  // submission whats next buttons
  whatsNextClicked = 'whatsNextClicked',
  // mandatory filer
  sectionMandatoryFilerReason = 'sectionMandatoryFilerReason',
  mandatoryCardShown = 'mandatoryCardShown',
  isUserMandatoryFiler = 'isUserMandatoryFiler',
  sectionForeignAddressDetails = 'sectionForeignAddressDetails',
  sectionAddressSearch = 'sectionAddressSearch',
  // info button tooltip
  infoButtonTooltipClosed = 'infoButtonTooltipClosed',
  infoButtonTooltipOpened = 'infoButtonTooltipOpened',
  infoButtonTooltipViewed = 'infoButtonTooltipViewed',
  // tax office contact experiement
  taxOfficeContacted = 'taxOfficeContacted',
  taxOfficeContactModalPressed = 'taxOfficeContactModalPressed',
  // reset submission experiment
  resetSubmissionRequested = 'resetSubmissionRequested',
  // in-app plausibility checks experiment
  singleQuestionPlausibilityCheckShown = 'singleQuestionPlausibilityCheckShown',
  singleQuestionPlausibilityCheckAnswered = 'singleQuestionPlausibilityCheckAnswered',
  // in-app multiple question validation experiment
  multipleQuestionValidationVisited = 'multipleQuestionValidationVisited',
  multipleQuestionValidationModified = 'multipleQuestionValidationModified',
  multipleQuestionValidationIgnored = 'multipleQuestionValidationIgnored',
  // New payslip validations experiment.
  payslipFieldHasBeenEdited = 'payslipFieldHasBeenEdited',
  payslipFieldWithErrorHasBeenEdited = 'payslipFieldWithErrorHasBeenEdited',
  payslipFieldWithWarningHasBeenEdited = 'payslipFieldWithWarningHasBeenEdited',
  // change email experiment
  changeEmailModalOpened = 'changeEmailModalOpened',
  requestEmailChangeButtonPressed = 'requestEmailChangeButtonPressed',
  // identification rejection reasons experiment
  viewRejectReasonsPressed = 'viewRejectReasonsPressed',
  // User closure
  accountClosed = 'accountClosed',
  accountClosureStepShown = 'accountClosureStepShown',
  // User deletion
  accountDeletedPrivacySettings = 'accountDeletedPrivacySettings',
  accountDeletionPrivacySettingsStepShown = 'accountDeletionPrivacySettingsStepShown',

  // Payslip Identification Experiment
  payslipIdentificationScreen = 'payslipIdentificationScreen',
  isCorrectAddressAnswered = 'isCorrectAddressPressed',
  isCorrectAddressOnPayslipAnswered = 'isCorrectAddressOnPayslipPressed',

  // Instant Refund
  instantRefundTeaserDismissed = 'instantRefundTeaserDismissed',
  instantRefundTeaserBannerShown = 'instantRefundTeaserBannerShown',
  instantRefundTermsScreenCheckbox = 'instantRefundTermsScreenCheckbox',
  instantRefundTermsScreenInstantRefundCTA = 'instantRefundTermsScreenInstantRefundCTA',
  instantRefundTermsScreenRemainingRefundCTA = 'instantRefundTermsScreenRemainingRefundCTA',
  instantRefundTermsScreenFeeCTA = 'instantRefundTermsScreenFeeCTA',
  instantRefundTermsScreenAboutPricingCTA = 'instantRefundTermsScreenAboutPricingCTA',
  instantRefundTermsScreenCancelPressed = 'instantRefundTermsScreenCancelPressed',
  instantRefundSanctionCheckStarted = 'instantRefundSanctionCheckStarted',
  instantRefundSanctionCheckPassed = 'instantRefundSanctionCheckPassed',
  instantRefundSanctionCheckRejected = 'instantRefundSanctionCheckRejected',
  instantRefundCreditScoreStarted = 'instantRefundCreditScoreStarted',
  instantRefundCreditScorePassed = 'instantRefundCreditScorePassed',
  instantRefundCreditScoreRejected = 'instantRefundCreditScoreRejected',
  instantRefundKYCIntroScreen = 'instantRefundKYCIntroScreen',
  instantRefundKYCIntroScreenCTA = 'instantRefundKYCIntroScreenCTA',
  instantRefundApplicationSubmit = 'instantRefundApplicationSubmit',
  instantRefundPayoutSuccess = 'instantRefundPayoutSuccess',
  instantRefundKORulesRejected = 'instantRefundKORulesRejected',
  instantRefundKYCIdnowStarted = 'instantRefundKYCIdnowStarted',
  instantRefundKYCIdnowFinished = 'instantRefundKYCIdnowFinished',
  instantRefundKYCIdnowClosed = 'instantRefundKYCIdnowClosed',
  instantRefundKYCIdnowFailed = 'instantRefundKYCIdnowFailed',
  instantRefundKYCIdnowRealtimeRejected = 'instantRefundKYCIdnowRealtimeRejected',
  instantRefundSoftOptInEligibilityInfoCTA = 'instantRefundSoftOptInEligibilityInfoCTA',
  instantRefundEligibilityInfoShown = 'instantRefundEligibilityInfoShown',
  instantRefundEligibilityInfoClose = 'instantRefundEligibilityInfoClose',
  refundTypeOptInMoreInformation = 'refundTypeOptInMoreInformation',
  refundTypeOptInEligibilityInformation = 'refundTypeOptInEligibilityInformation',
  refundTypeBottomSheetShown = 'refundTypeBottomSheetShown',
  refundTypeBottomSheetClose = 'refundTypeBottomSheetShown',
  instantRefundTermsScreenTermsCTA = 'instantRefundTermsScreenTermsCTA',
  // Instant refund V2 events
  instantRefundEligible = 'instantRefundEligible',
  instantRefundAutoSettlement = 'instantRefundAutoSettlement',
  instantRefundStepSucceeded = 'instantRefundStepSucceeded',
  instantRefundSectionShownOptin = 'instantRefundSectionShownOptin',
  instantRefundReviewAndSubmit = 'instantRefundReviewAndSubmit',
  instantRefundPostSubmission = 'instantRefundPostSubmission',
  instantRefundWAYcardExperimentTap = 'instantRefundWayCardExperimentTap',
  baselineWAYcardExperimentTap = 'baselineWayCardExperimentTap',
  instantRefundIncomeCategoryContinue = 'instantRefundIncomeCategoryContinue',
  // Specific category events, used for proper Intercom user segmentation
  // as we cannot use generic event's params for filtering in Intercom.
  categoryFamilyStarted = 'categoryFamilyStarted',
  categoryFamilyFinished = 'categoryFamilyFinished',
  categoryFinanceStarted = 'categoryFinanceStarted',
  categoryFinanceFinished = 'categoryFinanceFinished',
  categoryBenefitsStarted = 'categoryBenefitsStarted',
  categoryBenefitsFinished = 'categoryBenefitsFinished',
  categoryPersonalInformationStarted = 'categoryPersonalInformationStarted',
  categoryPersonalInformationFinished = 'categoryPersonalInformationFinished',
  categoryIncomeStarted = 'categoryIncomeStarted',
  categoryIncomeFinished = 'categoryIncomeFinished',
  categoryHomeStarted = 'categoryHomeStarted',
  categoryHomeFinished = 'categoryHomeFinished',
  // Generic events
  bannerPressed = 'bannerPressed',
  popupShown = 'popupShown',
  sectionShown = 'sectionShown',
  sectionClosed = 'sectionClosed',
  estimatedResultShown = 'estimatedResultShown',
  linkClicked = 'linkClicked',
  modalOpened = 'modalOpened',
  modalClosed = 'modalClosed',
  buttonPressed = 'buttonPressed',
  answerStateChanged = 'answerStateChanged',
  resultCalculated = 'resultCalculated',
  postSubmissionDelayPassed = 'postSubmissionDelayPassed',
  switchValueChanged = 'switchValueChanged',
  // Documents
  sectionDocumentPreview = 'sectionDocumentPreview',
  sectionDocuments = 'sectionDocuments',

  // Italy specific
  expertReviewAltViewExperimentActivated = 'expertReviewAltViewExperimentActivated',
  expertReviewAlternativeViewViewed = 'expertReviewAlternativeViewViewed',
  italyAbTestExperimentActivated = 'italyAbTestExperimentActivated',
  submissionComplexity = 'submissionComplexity',

  // Italy Expert Page
  expertLinkedinProfileVisited = 'expertLinkedinProfileVisited',

  // Used in Italy web pre-app
  webAppLoading = 'webAppLoading',

  // Italy temp, TODO: delete after experiment ends
  calendlyPrecompiladaDatetimeSelected = 'calendlyPrecompiladaDatetimeSelected',
  calendlyPrecompiladaAppointmentBooked = 'calendlyPrecompiladaAppointmentBooked',

  // ATT
  consentNativeModalResponse = 'consentNativeModalResponse',

  // GDPR Sensitive Data Consent
  createUserConsentRecordSuccess = 'createUserConsentRecordSuccess',
  createUserConsentRecordFailed = 'createUserConsentRecordFailed',
  getUserConsentRecordFailed = 'getUserConsentRecordFailed',
  emailShareCheck = 'emailShareCheck',
  termsAndConditionsCheck = 'termsAndConditionsCheck',
  certainEventsPressed = 'certainEventsPressed',
  gdppConsentPressed = 'gdppConsentPressed',
  spidConsentPressed = 'spidConsentPressed',
  amlConsentPressed = 'amlConsentPressed',

  // SPID
  identityProviderSelected = 'identityProviderSelected',
  spidCredentialsErrorShown = 'spidCredentialsErrorShown',
  spidCredentialsValidationFailed = 'spidCredentialsValidationFailed',
  spidFlowStatus = 'spidFlowStatus',
  spidTimeoutOperationalRedirect = 'spidTimeoutOperationalRedirect',

  // Main Screen
  sensitiveDataConsentAccepted = 'sensitiveDataConsentAccepted',
  sensitiveDataConsentRejected = 'sensitiveDataConsentRejected',

  // Info Screen
  sensitiveDataInfoboxClosed = 'sensitiveDataInfoboxClosed',
  sensitiveDataInfoboxOpened = 'sensitiveDataInfoboxOpened',

  // Retry Screen
  sensitiveDataRetryContinuePressed = 'sensitiveDataRetryContinuePressed',
  sensitiveDataRetryQuitPressed = 'sensitiveDataRetryQuitPressed',
  sensitiveDataRetryOptions = 'sensitiveDataRetryOptions',

  // Explore App
  requiredDocumentsRemindLaterPressed = 'requiredDocumentsRemindLaterPressed',
  prefillDocumentUploadRemindLaterPressed = 'prefillDocumentUploadRemindLaterPressed',
  retryRequiredDocumentsReminderPressed = 'retryRequiredDocumentsReminderPressed',
  retryPrefillDocumentUploadReminderPressed = 'retryPrefillDocumentUploadReminderPressed',
  skippedStepsReminderClosed = 'skippedStepsReminderClosed',
  // Banners
  bannerShown = 'bannerShown',

  // SSO related
  ssoSignInSucceed = 'ssoSignInSucceed',
  ssoSignInFailed = 'ssoSignInFailed',
  ssoSignInCanceled = 'ssoSignInCanceled',
  // WAY marketing attribution survey
  WAYAttributionSurveyShown = 'WAYAttributionSurveyShown',
  WAYAttributionSurveyAnswered = 'WAYAttributionSurveyAnswered',

  // Email confirmation
  confirmEmailSuccess = 'confirmEmailSuccess',
  confirmEmailFailed = 'confirmEmailFailed',

  // Ukraine donation banner
  ukraineDonationSeen = 'ukraineDonationSeen',
  ukraineDonationClicked = 'ukraineDonationClicked',

  // Sign up phone
  signUpPhoneSubmitted = 'signUpPhoneSubmitted',

  // Do it For Me
  DIFMApproveFiling = 'DIFMApproveFiling',
  productOptionChosen = 'productOptionChosen',
  DIFMDocumentUploadStarted = 'DIFMDocumentUploadStarted',
  DIFMModuleUploadCompleted = 'DIFMModuleUploadCompleted',
  DIFMAllDocumentsUploaded = 'DIFMAllDocumentsUploaded',
  DIFMRemoved = 'difmRemoved',
  DIFMEligible = 'difmEligible',
  DIFMLaunchExperiment = 'difmLaunchExperiment',
  DIFMProductOptionChosenExperimentPriceComm = 'DIFMProductOptionChosenExperimentPriceComm',
  DIYProductOptionChosenExperimentPriceComm = 'DIYProductOptionChosenExperimentPriceComm',
  DIFMProductOptionChosenExpPercentBasedPrice = 'difmProductOptionChosenExpPercentBasedPrice',
  DIYProductOptionChosenExpPercentBasedPrice = 'diyProductOptionChosenExpPercentBasedPrice',
  DIFMOptInScreenExperimentActivation = 'DIFMOptInScreenExperimentActivation',
  DIFMOptInExpPercentBasedActivation = 'difmOptInExpPercentBasedActivation',
  // Comments
  submitCommentPressed = 'submitCommentPressed',

  // Eppo event
  eppoAssignment = 'eppoAssignment',

  // Submission review status change
  reviewStatusChanged = 'reviewStatusChanged',
}

export type Params = {
  singularDeviceId?: string;
  screenName?: string;
  locale?: LanguageCode;
  buttonName?: string;
  addLocale?: boolean;
  [index: string]: any;
};

export type EventName = keyof typeof AnalyticsEvent;

const { LocaleInfo } = NativeModules;

function getReduxState(): any {
  const reduxStore = getStore();
  return reduxStore && reduxStore.getState();
}

function getQuestionStore(countryCode: CountryCodes, year: number): any {
  if (countryCode && year) {
    return getQuestionStoreForYear(countryCode, year);
  }
  return {};
}

// Additional parameters from store should be added here
function getSpecificEventParams(eventName: AnalyticsEvent): Params {
  const result: Params = {};
  const state = getReduxState();
  if (!state) return result;

  switch (eventName) {
    case AnalyticsEvent.question:
    case AnalyticsEvent.questionHelp:
    case AnalyticsEvent.questionEdited:
    case AnalyticsEvent.questionAnswered:
    case AnalyticsEvent.serviceContinueFailed:
    case AnalyticsEvent.sectionJourneyFailed:
      break;

    case AnalyticsEvent.singleQuestionPlausibilityCheckShown:
    case AnalyticsEvent.singleQuestionPlausibilityCheckAnswered:
    case AnalyticsEvent.checkoutStateMachineStateChanged:
    case AnalyticsEvent.prefillRegistrationOptinAbTestActivated:
    case AnalyticsEvent.prefillCtaContinued:
      result.userId = userAuthSelectors.getUserId(state);
      break;

    default:
      break;
  }

  return result;
}

const getParamsFromState = (): Params => {
  const result: Params = {};
  const state = getReduxState();
  if (!state) return result;

  /* Cannot use the settings selectors,
   * otherwise will create a dependency cycle around the app.
   */
  const year = state.settings.data.selectedYear;
  const taxCountry = state.settings.data.selectedCountry || CountryCodes.IT;

  const questionStore = getQuestionStore(taxCountry, year);

  const productLine = questionStore.responses
    ? questionStore.responses[FILING_EXPERIENCE.questionID]
    : undefined;

  result.taxCountry = taxCountry;
  result.year = year;
  result.selectedLocale = state.settings.data.selectedLocale;
  result.productLine = productLine?.answer ?? undefined;

  if (isWeb) {
    result.isMobile = isMobile;
    result.version = Config.APP_VERSION;

    const singularDeviceId = singular.getSingularDeviceId();
    if (singularDeviceId) {
      result.singularDeviceId = singularDeviceId;
    }
  }

  return result;
};

const getDefaultEventParams = (): Params => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { selectedLocale, ...defaultEventParams } = getParamsFromState();
  return defaultEventParams;
};

const getCRMEventsFilterWhitelist = (): Array<string> => {
  const state = getReduxState();
  return state ? getBrazeWhitelistSelector(state) : [];
};

const getBrazeIdFromState = (): string | null => {
  const state = getReduxState();
  return state ? brazeSelectors.selectBrazeId(state) : null;
};

const getLanguageFromState = (): LanguageCode => {
  const state = getReduxState();
  return state.settings.data.selectedLocale;
};

const getCodePushVersionFromState = (): string | undefined => {
  const state = getReduxState();
  return state ? codePushSelectors.getVersion(state) : undefined;
};

const buildIdentifyAttributes = (params: Record<string, any>) => {
  const deviceLocale = LocaleInfo ? LocaleInfo.deviceLocale : null;
  const { taxCountry, selectedLocale } = getParamsFromState();
  const codePushVersion = getCodePushVersionFromState();
  return {
    taxCountry,
    'taxfix.language': selectedLocale || deviceLocale,
    codepush: codePushVersion || '',
    ...params,
  };
};

const performIdentifySegment = async (
  user: { userId: number } | null | undefined,
  params: Record<string, any>,
  segmentCustomOptions: Record<string, any>,
) => {
  const defaultOptions = {
    integrations: {
      Intercom: false,
      Braze: false,
      Appboy: false,
      Singular: false,
    },
  };

  const traits = buildIdentifyAttributes(params);

  const segmentOptions = _.merge(defaultOptions, segmentCustomOptions);

  if (user) {
    const { userId } = user;
    AnalyticsNative.identifyWithUser(userId, traits, segmentOptions);
  } else {
    AnalyticsNative.identify(traits, segmentOptions);
  }

  await AnalyticsNative.flush();
};

const performIdentifySentry = async (user: { userId: number }) => {
  SentryErrorTracking.setUser({ id: user.userId });
};

// Initialize Singular SDK
setupSingular();

// Add Singular as a tracking destination after initialization

const Analytics = {
  identifyRegisteredUser: async (
    user: { userId: number; accessToken: string },
    customParams: Record<string, any> = {},
    customSegmentOptions: Record<string, any> = {},
  ): Promise<void> => {
    singular.login(user.userId.toString());
    await Promise.all([
      performIdentifySegment(user, customParams, customSegmentOptions),
      performIdentifySentry(user),
    ]);
  },
  // only use it when you need to identify user before login
  // this will skip Intercom identify since it requires access token for identity check
  identifyRegisteredUserWithoutAccessToken: async (
    user: { userId: number },
    customParams: Record<string, any> = {},
    customSegmentOptions: Record<string, any> = {},
  ): Promise<void> => {
    singular.login(user.userId.toString());
    await Promise.all([
      performIdentifySegment(user, customParams, customSegmentOptions),
      performIdentifySentry(user),
    ]);
  },
  identifyAnonymousUser: async (
    customParams: Record<string, any> = {},
    customSegmentOptions: Record<string, any> = {},
  ): Promise<void> => {
    await Promise.all([performIdentifySegment(null, customParams, customSegmentOptions)]);
  },
  getAdvertisingId: async (): Promise<string | null> => {
    let advertisingId = null;

    try {
      advertisingId = await AnalyticsNative.getAdvertisingId();
    } catch (e) {}

    // eslint-disable-line
    return advertisingId;
  },
  getAnonymousId: async (): Promise<string> => AnalyticsNative.getAnonymousId(),
  getBrazeDeviceId: async (): Promise<string> => AnalyticsNative.getBrazeDeviceId(),
  log: (eventName: AnalyticsEvent, customParams: Params = {}): void => {
    if (customParams?.addLocale) {
      customParams.locale = getLanguageFromState();
      delete customParams.addLocale;
    }

    const params = {
      ...getDefaultEventParams(),
      ...getSpecificEventParams(eventName),
      ...customParams,
    };

    // uncomment these lines to log tracking events
    // console.groupCollapsed(`>> Analytics.track::${eventName}`);
    // console.log('>>', params);
    // console.trace();
    // console.groupEnd();

    const CRMEventsWhitelist = getCRMEventsFilterWhitelist();
    const brazeId = getBrazeIdFromState();
    const context = {
      integrations: { Intercom: false, Singular: false },
      context: {
        integrations: {
          Braze: {
            braze_id: brazeId,
          },
        },
      },
    };
    if (CRMEventsWhitelist && CRMEventsWhitelist.includes(eventName)) {
      AnalyticsNative.track(eventName, params, context);
    } else {
      AnalyticsNative.track(eventName, params, {
        ...context,
        integrations: {
          ...context.integrations,
          Braze: false,
          Appboy: false,
        },
      });
    }

    // GTM tracking (noop for mobile)
    GTM.track(eventName, params);
    // Singular tracking
    singular.track(eventName, params);
  },
  optOutTracking: (isOptedOut: boolean, track?: () => void): void => {
    if (isOptedOut) {
      singular.logout();
      singular.limitDataSharing(true);
      if (track) track();
      AnalyticsNative.disable();
    } else {
      singular.limitDataSharing(false);
      AnalyticsNative.enable();
      if (track) track();
    }
  },
  reset: (): void => {
    singular.logout();
    singular.limitDataSharing(true);
    if (typeof AnalyticsNative.reset === 'function') AnalyticsNative.reset();
  },
};
export default Analytics;
export { AnalyticsEvent };
