/* global __DEV__ */
import { Buffer } from 'buffer';

import { Dispatch } from 'redux';
import { createSelector } from 'reselect';
import { Platform } from 'react-native';
import { CountryCodes } from '@taxfix/types';

import { ProductBundleValues } from 'src/common/constants-it';

import { ConfigKey, configKeys, defaultConfig } from '../utils/remote-config-firebase';
import { Configs, MaintenanceConfig } from '../../utils/remote-config-helpers';
import { getRemoteConfig as getRemoteConfigFromFirebase } from '../../services/remote-config-firebase';
import { Translations } from '../../containers/root-types';

import { selectors as settingsSelector, State as Settings } from './settings';

export const REQUEST_UPDATE = 'remote-config/REQUEST_UPDATE';
export const UPDATE_CONFIG = 'remote-config/UPDATE_CONFIG';
export const UPDATE_CONFIG_ERROR = 'remote-config/UPDATE_CONFIG_ERROR';
export type State = {
  configs: Configs | null | undefined;
  dirty: boolean;
  error?: string | null;
};
export const initial: State = {
  configs: null,
  dirty: false,
  error: null,
};
type RequestUpdateAction = {
  type: typeof REQUEST_UPDATE;
};
type UpdateAction = {
  type: typeof UPDATE_CONFIG;
  newConfig?: Configs;
};
type UpdateErrorAction = {
  type: typeof UPDATE_CONFIG_ERROR;
  error: string;
};
type Action = RequestUpdateAction | UpdateAction | UpdateErrorAction;

const requestUpdate = (): RequestUpdateAction => ({
  type: REQUEST_UPDATE,
});

const retrieveSuccessAction = (newConfig: Configs): UpdateAction => ({
  type: UPDATE_CONFIG,
  newConfig,
});

const retrieveErrorAction = (error: string): UpdateErrorAction => ({
  type: UPDATE_CONFIG_ERROR,
  error,
});

const retrieveConfig =
  () =>
  async (dispatch: Dispatch<any>): Promise<void> => {
    try {
      const config: Configs = await getRemoteConfigFromFirebase();
      dispatch(retrieveSuccessAction(config));
    } catch (err) {
      dispatch(retrieveErrorAction('Firebase Remote-config has a problem.'));

      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.warn('Firebase Remote-config has a problem: ', err?.message);
      }
    }
  };

export const actions = {
  requestUpdate,
  retrieveConfig,
};
export const reducer = (state: State = initial, action: Action): State => {
  switch (action.type) {
    case REQUEST_UPDATE:
      return { ...state, dirty: true, error: null };

    case UPDATE_CONFIG:
      return {
        ...state,
        dirty: false,
        error: null,
        // FYI "configs: action.newConfig" didn't work, the reason is unclear.
        configs: Object.assign({}, defaultConfig, action.newConfig),
      };

    case UPDATE_CONFIG_ERROR:
      return {
        ...state,
        dirty: false,
        error: action.error,
        configs: defaultConfig,
      };

    default:
      return state;
  }
};
export type RootState = {
  remoteConfigFirebase: State;
  settings: Settings;
};

type TaxYear = string;

export type UnsupportedCase = {
  questionId: string;
  answers: [string];
};
type TaxSeasonMetadata = {
  unsupportedCases?: UnsupportedCase[];
  trafficBlocked?: boolean;
  taxYearEndBannerStartDate?: Date;
  taxYearAuthorityDeadlineBannerStartDate?: Date;
  availableProducts?: string[];
};

export type TaxSeason = {
  startDateSoft: Date;
  startDateHard: Date;
  endDateSoft: Date;
  endDateHard: Date;
  endFilingDate?: Date;
  directDebitEndDate?: Date;
  enabled: boolean;
  metadata?: TaxSeasonMetadata;
};
export type TaxSeasonYear = Record<TaxYear, TaxSeason>;
export type TaxSeasonConfig = Record<CountryCodes, TaxSeasonYear>;

type PlatformEntity = {
  url: string;
  img?: string;
};
export type AppCountryProp = {
  enabled: boolean;
  countryIcon?: string;
  downloadApp: Record<string, PlatformEntity>;
};
export type AppCountrySplit = Record<CountryCodes, AppCountryProp>;

export type GenericSupportConfig = {
  mobEnabled: boolean;
  webEnabled: boolean;
  cardIndex?: number;
};

export type ChatSupportConfig = GenericSupportConfig & {
  workingHours: {
    start: number;
    end: number;
  }[];
  timeZone: string;
};

type MessagesType = {
  [countryCode: string]: string;
};

type PhoneSupportConfig = GenericSupportConfig & {
  workingHours: {
    start: number;
    end: number;
  }[];
  timeZone: string;
  contactWebUrl?: string;
  unauthenticatedDisabled?: boolean;
  messages?: MessagesType;
  shortMessages?: MessagesType;
  phoneNumber: string;
};

export type WhatsappSupportConfig = GenericSupportConfig & {
  whatsappBusinessUrl: string;
};

export type FormSupportConfig = GenericSupportConfig & {
  topics: { text: string; enabled: boolean }[];
};

export type FAQSupportConfig = GenericSupportConfig & {
  url: {
    es: string;
    en: string;
  };
};

export type EmailSupportConfig = GenericSupportConfig & {
  email: {
    es: string;
    en: string;
  };
  subjectLine: {
    es: string;
    en: string;
  };
};

export type FAQsConfig = {
  collection: string;
  article: string;
};

export type FAQsTestConfig = {
  enable: boolean;
  articles: FAQsConfig[];
};

export type CustomerSupportConfig = {
  chatSupport: ChatSupportConfig;
  phoneSupport: PhoneSupportConfig;
  whatsappSupport: WhatsappSupportConfig;
  formSupport: FormSupportConfig;
  helpCenterButtonOrder: string[];
  FAQS_TEST: FAQsTestConfig;
  emailActiveMethod: number;
  searchEnabled: boolean;
  articleViewEnabled: boolean;
  isCollectionsViewEnabled: boolean;
  articleRatingEnabled: boolean;
  articleBodyOnSearchEnabled: boolean;

  // ES only
  faqSupport: FAQSupportConfig;
  emailSupport: EmailSupportConfig;
};

const getRemoteConfig = (state: RootState): State => state.remoteConfigFirebase;

const isDirty: (state: RootState) => boolean = createSelector(
  getRemoteConfig,
  ({ dirty }) => dirty,
);
const isConfigRetrieved: (state: RootState) => boolean = createSelector(
  getRemoteConfig,
  ({ configs }) => configs !== null,
);
const getTaxSeason: (state: RootState) => TaxSeasonConfig = createSelector(
  getRemoteConfig,
  ({ configs }) => configs?.taxSeason,
);

const getTaxSeasonByCountryCodes = (state: RootState, countryCode: CountryCodes): TaxSeasonYear =>
  getTaxSeason(state)[countryCode];

/*
  * Note for the selectors for experiment variant (AB test).
  state.remoteConfigFirebase.configs[__EXPERIMENT_KEY__] will become 'undefined',
  when the experiment is finished or the remote-config is deleted from Firebase.
  Thus, we should provide the 'default value' for that case.
  Usually you would want to set it as 'original' variant. (e.g 'false')
  But when your team is so sure that the experiment will win, set it as 'experiment' variant. (e.g 'true')
*/
const safeGetRemoteConfigValue = (
  state: RootState,
  key: ConfigKey,
  defaultReturn: any = false, // eslint-disable-next-line no-prototype-builtins
) =>
  state.remoteConfigFirebase.configs && key in state.remoteConfigFirebase.configs
    ? state.remoteConfigFirebase.configs[key]
    : defaultReturn;

const getMaintenance: (state: RootState) => MaintenanceConfig = createSelector(
  getRemoteConfig,
  ({ configs }) => configs?.maintenance,
);

const isMaintenanceEnabled: (state: RootState) => boolean | undefined = createSelector(
  settingsSelector.selectedCountry,
  getMaintenance,
  (countryCode, maintenance) =>
    Object.keys(maintenance).includes(countryCode) ? maintenance[countryCode] : undefined,
);

const getCountriesInMaintenance: (state: RootState) => CountryCodes[] = createSelector(
  getMaintenance,
  (maintenance) =>
    (Object.keys(maintenance) as (keyof typeof maintenance)[]).filter(
      (countryCode) => maintenance[countryCode],
    ),
);

const getMinimumVersion: (state: RootState) => string | null | undefined = createSelector(
  settingsSelector.selectedCountry,
  getRemoteConfig,
  (countryCode, { configs }) => configs?.minimumVersion?.[countryCode]?.[Platform.OS] || undefined,
);

const getSupportConfigByCountry: (state: RootState) => CustomerSupportConfig = createSelector(
  settingsSelector.selectedCountry,
  getRemoteConfig,
  (countryCode, { configs }) =>
    configs?.customerSupport?.[countryCode] || defaultConfig.customerSupport['IT'],
);

const getPhoneSupportByCountry = (state: RootState): PhoneSupportConfig =>
  getSupportConfigByCountry(state).phoneSupport;

const getChatSupportByCountry = (state: RootState): ChatSupportConfig =>
  getSupportConfigByCountry(state).chatSupport;

const getWhatsappSupportByCountry = (state: RootState): WhatsappSupportConfig =>
  getSupportConfigByCountry(state).whatsappSupport;

const getFormSupportByCountry = (state: RootState): FormSupportConfig =>
  getSupportConfigByCountry(state).formSupport;

const getHelpCenterButtonOrder = (state: RootState): string[] =>
  getSupportConfigByCountry(state).helpCenterButtonOrder;

const getHelpCenterFAQs = (state: RootState): FAQsTestConfig =>
  getSupportConfigByCountry(state).FAQS_TEST;

const getHelpCenterEmailActiveMethod = (state: RootState): number =>
  getSupportConfigByCountry(state).emailActiveMethod;

const isEnabledCodePush = (state: RootState): boolean => {
  return safeGetRemoteConfigValue(state, configKeys.enabledCodePush, defaultConfig.enabledCodePush)[
    'IT'
  ];
};

const isEnabledNewPostSubmissionScreen: (state: RootState) => boolean = createSelector(
  getRemoteConfig,
  ({ configs }: any) => !!configs?.enabledPostSubmissionStepsScreen,
);

const getTranslations: (state: RootState) => Translations = createSelector(
  getRemoteConfig,
  ({ configs }) => configs?.translations || {},
);

const getBlacklistDynamicLink = (state: RootState): Array<string> =>
  state.remoteConfigFirebase.configs?.blacklistDynamicLink?.values || [];

const getMapsKey: (state: RootState) => string = createSelector(getRemoteConfig, ({ configs }) =>
  Buffer.from(configs?.mapsKey, 'base64').toString('utf-8'),
);

const isEnabledPrivacySettingsRequestData: (state: RootState) => boolean = createSelector(
  getRemoteConfig,
  ({ configs }: any) => !!configs?.enabledPrivacySettingsRequestData,
);

const shouldSkipLegalChange = createSelector(
  (state: RootState) =>
    safeGetRemoteConfigValue(state, configKeys.skipLegalChange, defaultConfig.skipLegalChange),
  settingsSelector.selectedCountry,
  (skipLegalChangeConfig, countryCode) => skipLegalChangeConfig[countryCode],
);

const getItalySkipMandatoryDocumentsEnabled = (state: RootState): boolean => {
  const defaultValue = { enabled: false };
  return safeGetRemoteConfigValue(state, configKeys.italySkipMandatoryDocuments, defaultValue)
    .enabled;
};

const getItalyPrefillRedditiTest = (
  state: RootState,
): {
  urlVerificationHash: string;
  enabled: boolean;
  completeTestOvelayExternalLink: string;
} => {
  return safeGetRemoteConfigValue(
    state,
    configKeys.italyPrefillRedditiTest,
    defaultConfig.italyPrefillRedditiTest,
  );
};

const getItalyEndOfSeasonBanner = (state: RootState): { enabled: boolean; articleLink: string } => {
  return safeGetRemoteConfigValue(
    state,
    configKeys.italyEndOfSeasonBanner,
    defaultConfig.italyEndOfSeasonBanner,
  );
};

type ItalyTaxGuideArticles = {
  basics: Record<string, string>;
  goodToKnow: Record<string, string>;
  curiosities: Record<string, string>;
};

const getItalyTaxGuideArticles = (state: RootState): ItalyTaxGuideArticles => {
  return safeGetRemoteConfigValue(
    state,
    configKeys.italyTaxGuideArticles,
    defaultConfig.italyTaxGuideArticles,
  );
};

const getAppCountrySplitConfig = (state: RootState): AppCountrySplit =>
  safeGetRemoteConfigValue(state, configKeys.appCountrySplit, defaultConfig.appCountrySplit);

const isItalyDataDeletionRequestEnabled = (state: RootState): boolean => {
  const defaultValue = { enabled: false };
  return safeGetRemoteConfigValue(state, configKeys.dataDeletionRequestItaly, defaultValue).enabled;
};

type ItalyTaxExpertsLinks = {
  alessandra: string;
  stephan: string;
} & Record<string, string>;

const getItalyTaxExpertsLinks = (state: RootState): ItalyTaxExpertsLinks =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italyTaxExpertsLinks,
    defaultConfig.italyTaxExpertsLinks,
  );

const getCwbiPrivacyPolicyUrlIdentifier = (state: RootState): { value: string } =>
  safeGetRemoteConfigValue(
    state,
    configKeys.cwbiPrivacyPolicyUrlIdentifier,
    defaultConfig.cwbiPrivacyPolicyUrlIdentifier,
  );

type DocumentList = {
  documentList: string[];
};

const getCWBIFolderRequestFirebase = (state: RootState): DocumentList =>
  safeGetRemoteConfigValue(state, configKeys.cwbiFolderRequest, defaultConfig.cwbiFolderRequest);

const isSelfCertificateEnabled = (state: RootState): boolean =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italySelfCertificateEnabled,
    defaultConfig.italySelfCertificateEnabled,
  );

type PricingConfig = Record<
  ProductBundleValues.instant | ProductBundleValues.guided,
  {
    voucher: string;
    fullPrice: number;
    discountPrice: number;
    isFree?: boolean;
  }
>;

export type PaymentConfig = Record<string, PricingConfig>;

const getItalyProductsPaymentConfig = (state: RootState): PaymentConfig =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italyProductsPaymentConfig,
    defaultConfig.italyProductsPaymentConfig,
  );

const getInstantProductVouchers = (state: RootState): Record<string, string> =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italyInstantProductVouchers,
    defaultConfig.italyInstantProductVouchers,
  );

const isModelloRedditiSignEnabled = (state: RootState): boolean =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italyModelloRedditiSignEnabled,
    defaultConfig.italyModelloRedditiSignEnabled,
  );

const isDeclarationTypeUNI = (state: RootState): boolean =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italyIsDeclarationTypeUNI,
    defaultConfig.italyIsDeclarationTypeUNI,
  );

const isPaypalEnabled = (state: RootState): { enabled: boolean } =>
  safeGetRemoteConfigValue(state, configKeys.enableItalyPaypal, defaultConfig.enableItalyPaypal);

type CwbiMockServerConfig = {
  mockServerEnabled: boolean;
  whitelistedTaxIds: string[];
  mockServerURLIframeEvent: string;
};

const getCwbiMockServerConfig = (state: RootState): CwbiMockServerConfig =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italyCwbiMockServerConfig,
    defaultConfig.italyCwbiMockServerConfig,
  );

const isNewPaymentScreenEnabled = (state: RootState): boolean =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italyPaymentScreen2024Enabled,
    defaultConfig.italyPaymentScreen2024Enabled,
  );

// the types below are compatible with downloadPrefillDocuments request in italy-sdk
type CWBIDocTypes =
  | 'MOD_CUK'
  | 'MOD_730_PRE'
  | 'MOD_UNI_PRE'
  | 'VISURA_CATASTALE_ANALITICA'
  | 'SPESA_SANITARIA'
  | 'DATI_SINTESI_PRE';

export type CWBIRequestDocumentConfig = {
  cwbiDocType: CWBIDocTypes;
  taxfixPdfType?: string;
  taxfixJsonType: string;
  fromYear?: number;
  toYear?: number;
  userEmailRequired: boolean;
  translation: string;
};

export type ItalyCWBIDocumentsDownloadConfig = {
  syncDownload: CWBIRequestDocumentConfig[];
  asyncDownload: CWBIRequestDocumentConfig[];
};

const getItalyCWBIDocumentsDownloadConfig = (state: RootState): ItalyCWBIDocumentsDownloadConfig =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italyCWBIDocumentsDownloadConfig,
    defaultConfig.italyCWBIDocumentsDownloadConfig,
  );

export type ItalyDashboardInfoBanner = {
  enabled: boolean;
  text: string;
};

const getItalyDashboardInfoBanner = (state: RootState): ItalyDashboardInfoBanner =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italyDashboardInfoBanner,
    defaultConfig.italyDashboardInfoBanner,
  );

const isPaymentScreenBannerEnabled = (state: RootState): boolean =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italyPaymentScreenBannerEnabled,
    defaultConfig.italyPaymentScreenBannerEnabled,
  );

const getItalySPIDUrlErrorTimeout = (state: RootState): number =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italySPIDUrlErrorTimeout,
    defaultConfig.italySPIDUrlErrorTimeout,
  );

type NoSPIDExperienceThresholds = {
  initialLoadTimeout: number;
  providerSelectionTimeout: number;
  loginWithProviderTimeout: number;
};

const getNoSPIDExperienceThresholds = (state: RootState): NoSPIDExperienceThresholds =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italyNoSPIDExperienceThresholds,
    defaultConfig.italyNoSPIDExperienceThresholds,
  );

type ItalyLoadingOverlayConfig = {
  messages: string[];
  messageInterval: number;
};

const getItalyLoadingOverlayConfig = (state: RootState): ItalyLoadingOverlayConfig =>
  safeGetRemoteConfigValue(
    state,
    configKeys.italyLoadingOverlayConfig,
    defaultConfig.italyLoadingOverlayConfig,
  );

const getItalyCWBIBaseUrl = (state: RootState): string =>
  safeGetRemoteConfigValue(state, configKeys.italyCWBIBaseURL, defaultConfig.italyCWBIBaseURL);

export const selectors = {
  getBlacklistDynamicLink,
  getCountriesInMaintenance,
  getCwbiPrivacyPolicyUrlIdentifier,
  getMaintenance,
  getMapsKey,
  getMinimumVersion,
  getSupportConfigByCountry,
  getPhoneSupportByCountry,
  getWhatsappSupportByCountry,
  getRemoteConfig,
  getTaxSeason,
  getTaxSeasonByCountryCodes,
  getTranslations,
  isConfigRetrieved,
  isDirty,
  isEnabledCodePush,
  isEnabledNewPostSubmissionScreen,
  isMaintenanceEnabled,
  safeGetRemoteConfigValue,
  isEnabledPrivacySettingsRequestData,
  shouldSkipLegalChange,
  getAppCountrySplitConfig,
  isItalyDataDeletionRequestEnabled,
  getChatSupportByCountry,
  getHelpCenterButtonOrder,
  getItalySkipMandatoryDocumentsEnabled,
  getItalyPrefillRedditiTest,
  getItalyEndOfSeasonBanner,
  getHelpCenterFAQs,
  getHelpCenterEmailActiveMethod,
  getItalyTaxGuideArticles,
  getItalyTaxExpertsLinks,
  getFormSupportByCountry,
  getCWBIFolderRequestFirebase,
  isSelfCertificateEnabled,
  getItalyProductsPaymentConfig,
  getInstantProductVouchers,
  isModelloRedditiSignEnabled,
  isDeclarationTypeUNI,
  isPaypalEnabled,
  getCwbiMockServerConfig,
  isNewPaymentScreenEnabled,
  getItalyCWBIDocumentsDownloadConfig,
  getItalyDashboardInfoBanner,
  isPaymentScreenBannerEnabled,
  getItalySPIDUrlErrorTimeout,
  getItalyLoadingOverlayConfig,
  getNoSPIDExperienceThresholds,
  getItalyCWBIBaseUrl,
};
