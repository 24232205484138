import React from 'react';
import { compose, bindActionCreators } from 'redux';
import { ConnectedProps, connect } from 'react-redux';

import { selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { actions as userEmailChangeActions } from '../../stores/modules/user-email-change';
import { WithNavigation, withNavigation } from '../../hocs/with-navigation';
import { withInternetConnection } from '../../hocs/with-internet-connection';

import { RequestEmailChange } from './RequestEmailChange';

const mapStateToProps = (state: any) => ({
  currentEmail: userAuthSelectors.getEmailAddress(state),
  accessToken: userAuthSelectors.getAccessToken(state),
});

const mapDispatchToProps = (dispatch: any) => ({
  requestEmailChange: bindActionCreators(userEmailChangeActions.requestEmailChange, dispatch),
});

const withStoreConnection = connect(mapStateToProps, mapDispatchToProps);
type ReduxProps = ConnectedProps<typeof withStoreConnection>;
type Props = {
  currentEmail: string;
} & ReduxProps &
  WithNavigation;
type ConfirmCallback = (arg0: { email: string; password?: string }) => Promise<any>;

const UndecoratedChangeEmailContainer = ({
  currentEmail,
  accessToken,
  navigationActions,
  requestEmailChange,
}: Props) => {
  const handleConfirm: ConfirmCallback = async ({ email: newEmail, password }): Promise<any> => {
    await requestEmailChange(newEmail, password, accessToken);
    navigationActions.back();
  };

  return <RequestEmailChange currentEmail={currentEmail} onConfirmChange={handleConfirm} />;
};

export const ChangeEmailContainer = compose<any>(
  withNavigation,
  withInternetConnection,
  withStoreConnection,
)(UndecoratedChangeEmailContainer);
