import { reducer as settings } from '../modules/settings';
import { reducer as identifications } from '../modules/identifications';
import { reducer as userConsents } from '../modules/user-consents';
import { reducer as places } from '../modules/places';
import { reducer as overlay } from '../modules/overlay';
import { reducer as user } from '../modules/user';
import { reducer as partner } from '../modules/partner';
import { reducer as bankDetails } from '../modules/bank-details';
import { reducer as syncAnswers } from '../modules/sync-answers';
import { reducer as internetConnection } from '../modules/internet-connection';
import { reducer as submission } from '../modules/submission';
import { reducer as prefill } from '../modules/prefill';
import { reducer as status } from '../modules/status';
import { reducer as requiredDocuments } from '../modules/required-documents';
import { reducer as userLegal } from '../modules/user-legal';
import { reducer as remoteConfigFirebase } from '../modules/remote-config-firebase';
import { reducer as migration } from '../modules/migration';
import { reducer as devTools } from '../modules/dev-tools';
import { reducer as flow } from '../modules/flow-legacy';
import { reducer as referralData } from '../modules/referral-data';
import { reducer as dynamicLink } from '../modules/dynamic-link';
import { reducer as userAuth } from '../modules/user-auth';
import { reducer as codepush } from '../modules/code-push';
import { reducer as referralHistory } from '../modules/referral-history';
import { reducer as userProfile } from '../modules/user-profile';
import { reducer as checkoutFlow } from '../modules/checkout-flow';
import { reducer as userEmailChange } from '../modules/user-email-change';
import { reducer as lock } from '../modules/lock';
import { reducer as plausibilityChecks } from '../modules/plausibility-checks';
import { reducer as braze } from '../modules/braze';
import { reducer as taxSystem } from '../modules/tax-system';
import { reducer as payment } from '../modules/payment';
import { reducer as payslips } from '../modules/payslips';
import { reducer as questions } from '../modules/questions';

export default {
  settings,
  identifications,
  userConsents,
  places,
  overlay,
  user,
  partner,
  bankDetails,
  syncAnswers,
  internetConnection,
  submission,
  prefill,
  status,
  requiredDocuments,
  userLegal,
  remoteConfigFirebase,
  migration,
  devTools,
  flow,
  referralData,
  dynamicLink,
  userAuth,
  codepush,
  referralHistory,
  userProfile,
  userEmailChange,
  lock,
  plausibilityChecks,
  braze,
  taxSystem,
  checkoutFlow,
  payment,
  payslips,
  questions,
};
