import { $Values } from 'utility-types';
import moment from 'moment';
import { createSelector } from 'reselect';
import Config from 'react-native-config';
import { User } from '@taxfix/taxfix-sdk';
import { GetEmailChangeStatusResponse } from '@taxfix/taxfix-sdk/dist/user/get-email-change-status';
import { CountryCodes } from '@taxfix/types';

import { actions as userAuthActions } from './user-auth';
import { selectors as settingsSelectors } from './settings';

export const GET_EMAIL_CHANGE_STATUS = 'userEmailChange/GET_EMAIL_CHANGE_STATUS';
export const GET_EMAIL_CHANGE_STATUS_SUCCESS = 'userEmailChange/GET_EMAIL_CHANGE_STATUS_SUCCESS';
export const GET_EMAIL_CHANGE_STATUS_ERROR = 'userEmailChange/GET_EMAIL_CHANGE_STATUS_ERROR';
export const RESET_EMAIL_CHANGE_STATUS = 'userEmailChange/RESET_EMAIL_CHANGE_STATUS';
export const REQUEST_EMAIL_CHANGE = 'userEmailChange/REQUEST_EMAIL_CHANGE';
export const REQUEST_EMAIL_CHANGE_SUCCESS = 'userEmailChange/REQUEST_EMAIL_CHANGE_SUCCESS';
export const REQUEST_EMAIL_CHANGE_ERROR = 'userEmailChange/REQUEST_EMAIL_CHANGE_ERROR';
const states = Object.freeze({
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  CONFIRMED: 'CONFIRMED',
  EXPIRED: 'EXPIRED',
  ERROR: 'ERROR',
});
type EmailChangeStates = $Values<typeof states>;
export type State = {
  status: EmailChangeStates;
  expiresIn?: number;
  newEmail?: string;
};
type GetEmailChangeStatusSuccessAction = {
  type: typeof GET_EMAIL_CHANGE_STATUS_SUCCESS;
  payload: GetEmailChangeStatusResponse;
};
type GetEmailChangeStatusErrorAction = {
  type: typeof GET_EMAIL_CHANGE_STATUS_ERROR;
};
type ResetEmailChangeStatusAction = {
  type: typeof GET_EMAIL_CHANGE_STATUS_ERROR;
};
type RequestEmailChangeSuccessAction = {
  type: typeof REQUEST_EMAIL_CHANGE_SUCCESS;
  payload: {
    newEmail: string;
  };
};
type RequestEmailChangeErrorAction = {
  type: typeof REQUEST_EMAIL_CHANGE_ERROR;
};
type Action =
  | GetEmailChangeStatusSuccessAction
  | GetEmailChangeStatusErrorAction
  | ResetEmailChangeStatusAction
  | RequestEmailChangeSuccessAction
  | RequestEmailChangeErrorAction;

const getEmailChangeStatusSuccess = (state: State) => ({
  type: GET_EMAIL_CHANGE_STATUS_SUCCESS,
  payload: state,
});

const getEmailChangeStatusError = () => ({
  type: GET_EMAIL_CHANGE_STATUS_ERROR,
});

const resetEmailChangeStatus = () => ({
  type: RESET_EMAIL_CHANGE_STATUS,
});

const fetchEmailChangeStatus = (accessToken: string) => async (dispatch: any) => {
  try {
    const status = await User.getEmailChangeStatus(Config.API_BASE_URL, accessToken);
    dispatch(getEmailChangeStatusSuccess(status));
  } catch (error) {
    dispatch(getEmailChangeStatusError());
  }
};

const resetStoredCredentials =
  (email: string, country: CountryCodes, accessToken: string) => async (dispatch: any) => {
    const userResponse = await User.current(Config.API_BASE_URL, accessToken, {
      countryCode: country,
    });
    dispatch(userAuthActions.updateUserFromResponse(userResponse, email, accessToken));
  };

const requestEmailChangeSuccess = (payload: any) => ({
  type: REQUEST_EMAIL_CHANGE_SUCCESS,
  payload,
});

const requestEmailChangeError = () => ({
  type: REQUEST_EMAIL_CHANGE_ERROR,
});

const requestEmailChange =
  (newEmail: string, password: string | undefined, accessToken: string) =>
  async (dispatch: any, getState: () => any) => {
    try {
      await User.requestEmailChange(Config.API_BASE_URL, accessToken, {
        newEmail,
        password,
        taxCountry: settingsSelectors.selectedCountry(getState()),
      });
      dispatch(
        requestEmailChangeSuccess({
          newEmail,
        }),
      );
    } catch (error) {
      dispatch(requestEmailChangeError());
      throw error;
    }
  };

export const actions = {
  fetchEmailChangeStatus,
  resetEmailChangeStatus,
  resetStoredCredentials,
  requestEmailChange,
};
export const initial: State = {
  status: 'IDLE',
};
export const reducer = (state: State = initial, action: Action | any): State => {
  switch (action.type as any) {
    case GET_EMAIL_CHANGE_STATUS_SUCCESS:
      return {
        ...state,
        status: action.payload.status,
        expiresIn: moment.duration(action.payload.expiresIn).asMilliseconds(),
        newEmail: action.payload.newEmail,
      };

    case GET_EMAIL_CHANGE_STATUS_ERROR:
      return { ...state, status: states.ERROR };

    case REQUEST_EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        status: states.PENDING,
        newEmail: action.payload.newEmail,
        expiresIn: moment.duration('P1D').asMilliseconds(),
        requestedAt: new Date(),
      } as any;

    case REQUEST_EMAIL_CHANGE_ERROR:
      return { ...state, status: states.ERROR };

    case RESET_EMAIL_CHANGE_STATUS:
      return { ...initial };

    default:
      return state;
  }
};
type RootState = {
  userEmailChange: State;
};

const getEmailChange = (state: RootState) => state.userEmailChange;

const getEmailChangeStatus = createSelector(getEmailChange, ({ status }: State) => status);
const isIdle = createSelector(getEmailChangeStatus, (status) => status === states.IDLE);
const isPending = createSelector(getEmailChangeStatus, (status) => status === states.PENDING);
const isNotSettled = createSelector([isIdle, isPending], (idle, pending) => idle || pending);
const isConfirmed = createSelector(getEmailChangeStatus, (status) => status === states.CONFIRMED);
const hasExpired = createSelector(getEmailChangeStatus, (status) => status === states.EXPIRED);
const isSettled = createSelector(
  [isConfirmed, hasExpired],
  (confirmed, expired) => confirmed || expired,
);
const hasFailed = createSelector(getEmailChangeStatus, (status) => status === states.ERROR);
const getEmailChangeMeta = createSelector(getEmailChange, ({ status, ...rest }: State) => rest);
const getNewEmail = createSelector<any, any, any>(
  getEmailChangeMeta,
  ({ newEmail }: State) => newEmail,
);
const getExpirationTimeLeft = createSelector<any, any, any>(
  getEmailChangeMeta,
  ({ expiresIn }: State) => expiresIn,
);
const getExpirationDate = createSelector(getExpirationTimeLeft, (timeLeft) =>
  timeLeft ? moment().add(timeLeft).toDate() : null,
);
export const selectors = {
  getEmailChange,
  getEmailChangeStatus,
  getEmailChangeMeta,
  isIdle,
  isPending,
  isConfirmed,
  hasExpired,
  hasFailed,
  isNotSettled,
  isSettled,
  getNewEmail,
  getExpirationDate,
  getExpirationTimeLeft,
};
