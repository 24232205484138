export enum ButtonName {
  HeaderLanguageSelectorButton = 'HeaderLanguageSelectorButton',
  AccountMenuItemButton = 'AccountMenuItemButton',
  Start = 'Start',
  CopyVoucher = 'CopyVoucher',
  SeeMoreDetailsButton = 'SeeMoreDetailsButton',
  ActivateBoth = 'ActivateBoth',
  DIFMExploreToOptIn = 'ExploreToOptIn',
  DIFMBackToChooseProduct = 'BackToChooseProduct',
  DIFMUploadReceiptsSmallbutton = 'DIFMUploadReceiptsSmallbutton',
  RemoveDIFMIntention = 'RemoveDIFMIntention',
  KeepDIFM = 'KeepDIFM',
  RemoveDIFM = '',
  DIFMContinueToMandateMiddleCard = 'DIFMContinueToMandateMiddleCard',
  DIFMTaxQFStartSmallButton = 'DIFMTaxQFStartSmallButton',
  DIFMTaxQFStartMiddleCard = 'DIFMTaxQFStartMiddleCard',
  DIFMGoToQuestionFlow = 'DIFMGoToQuestionFlow',
  DIFMContinueToMandateOverview = 'DIFMContinueToMandateOverview',
  DIFMContinueToPayment = 'DIFMContinueToPayment',
  DIFMCommentsSaved = 'DIFMCommentsSaved',
  DIFMOpenChat = 'OpenChat',
  DIFMTapToSignMandate = 'DIFMTapToSignMandate',
  DIFMConfirmMandate = 'DIFMConfirmMandate',
  DIFMtaxDeclarationLooksGood = 'DIFMtaxDeclarationLooksGood',
  DIFMSomethingWrong = 'DIFMSomethingWrong',
  DIFMSeeDocuments = 'DIFMSeeDocuments',
  DIFMsubmitToAdvisor = 'DIFMsubmitToAdvisor',
  ItPremiumTierScheduleMeet = 'ItPremiumTierScheduleMeet',
  ItPremiumTierPaymentButton = 'ItPremiumTierPaymentButton',
  ItExpertCheckPaymentButton = 'ItExpertCheckPaymentButton',
  DIFMUploadFinishedIntention = 'DIFMUploadFinishedIntention',
  DIFMSendMyIdentification = 'SendMyIdentification',
  DIFMWhatProductChoose = 'WhatProductChoose',
  DIFMWhatProductChooseExperimentPriceComm = 'WhatProductChooseExperimentPriceComm',
  DIYSliderExpPercentBasedPrice = 'DIYSliderExpPercentBasedPrice',
  DIFMSliderExpPercentBasedPrice = 'DIFMSliderExpPercentBasedPrice',
  DIFMUnderstoodExpPercentBasedPrice = 'UnderstoodExpPercentBasedPrice',
  ActivateNotification = 'ActivateNotification',
  MaybeLater = 'MaybeLater',
  ModelloRedditiSign = 'ModelloRedditiSign',
  PreseasonReminderOptIn = 'PreSeasonReminderOptIn',
  PreseasonReminderOptOut = 'PreSeasonReminderOptOut',
  StartPreseasonCuDownload = 'StartPreseasonCuDownload',
  ChangePlan = 'ChangePlan',
  Continue = 'Continue',
  Close = 'Close',
  WatchVideo = 'WatchVideo',
}
//more info evnt WhatProductChooseExperimentPriceComm
