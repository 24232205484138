import Config from 'react-native-config';
// @ts-ignore
import { singularSdk, SingularConfig } from 'singular-sdk';

export const setupSingular = (): void => {
  const singularConfig = new SingularConfig(
    Config.SINGULAR_SDK_KEY,
    Config.SINGULAR_SDK_PASSPHRASE,
    Config.PRODUCT_ID,
  );

  singularSdk.init(singularConfig);
};

export default {
  login: (userId: string): void => {
    singularSdk.login(userId);
  },
  logout: (): void => {
    singularSdk.logout();
  },
  track: (eventName: string, params: Record<string, string>): void => {
    singularSdk.event(eventName, params);
  },
  // Not available in singular-sdk for web
  limitDataSharing: (limitDataSharing: boolean): void => {},
  getSingularDeviceId: (): string | undefined => singularSdk.getSingularDeviceId(),
};
