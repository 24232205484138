import Config from 'react-native-config';

import { navigation } from '../../services/navigation-service';
import { toHome, homeScreen } from '../../screens/home/home-route';
import { toLogin, loginScreen } from '../../screens/login/login-route';
import { toCamera, cameraScreen } from '../../screens/android-camera-upload/camera-route';
import {
  toImagePreview,
  imagePreviewScreen,
} from '../../screens/image-preview/image-preview-route';
import { toLegalChange, legalChangeScreen } from '../../screens/legal-change/legal-change-route';
import {
  toOtcValidation,
  otcValidationScreen,
} from '../../screens/otc-validation/otc-validation-route';
import { toSignup, signupScreen } from '../../screens/signup/signup-route';
import {
  toUserLegalCheck,
  userLegalCheckScreen,
} from '../../screens/user-legal-check/user-legal-check-route';
import { toTerms, termsScreen } from '../../screens/terms/terms-route';
import { toOpenPDF, openPDFScreen } from '../../screens/open-pdf/open-pdf-route';
import {
  toNotifications,
  notificationsScreen,
} from '../../screens/notifications/notifications-route';
import { toStatus, statusScreen } from '../../screens/status-screen/status-route';
import { toAccount, accountScreen } from '../../screens/account-menu/account-route';
import {
  toForgotPassword,
  forgotPasswordScreen,
} from '../../screens/forgot-password/forgot-password-route';
import {
  toPrivacyPolicy,
  privacyPolicyScreen,
} from '../../screens/privacy-policy/privacy-policy-route';
import {
  toVoucherInput,
  voucherInputScreen,
} from '../../screens/voucher-input/voucher-input-route';
import { toChangeEmail, changeEmailScreen } from '../../screens/change-email/change-email-route';
import { toScanbotMock, scanbotMockScreen } from '../../services/scanbot/index.e2e';
import {
  toFirebaseRemoteConfig,
  firebaseRemoteConfigScreen,
} from '../../screens/firebase-remote-config-info/firebase-remote-config-info';
import { toLock, lockScreen } from '../../screens/lock/lock-route';

const showDebugScreen = Config.SHOW_DEBUG_SCREEN === 'true';

export const navigationActions = {
  back: navigation.back,
  reset: navigation.reset,
  replace: navigation.replace,
  setParams: navigation.setParams,
  popToTop: navigation.popToTop,
  toHome,
  toLock,
  toLogin,
  toCamera,
  toImagePreview,
  toLegalChange,
  toOtcValidation,
  toSignup,
  toUserLegalCheck,
  toTerms,
  toOpenPDF,
  toStatus,
  toAccount,
  toForgotPassword,
  toPrivacyPolicy,
  toNotifications,
  toVoucherInput,
  toChangeEmail,
  toFirebaseRemoteConfig,
  ...(showDebugScreen
    ? {
        toScanbotMock,
      }
    : {}),
};

/**
 * The home screen has to always be the first screen.
 * I don't know why, but I am guessing that would be
 * because the first screen is used as the default screen.
 */
export const screens = [
  homeScreen,
  lockScreen,
  loginScreen,
  cameraScreen,
  imagePreviewScreen,
  legalChangeScreen,
  otcValidationScreen,
  signupScreen,
  userLegalCheckScreen,
  termsScreen,
  openPDFScreen,
  statusScreen,
  accountScreen,
  forgotPasswordScreen,
  privacyPolicyScreen,
  notificationsScreen,
  voucherInputScreen,
  changeEmailScreen,
  firebaseRemoteConfigScreen,
  ...(showDebugScreen ? [scanbotMockScreen] : []),
];
