import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Linking } from 'react-native';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ProductBundleValues, flagsQuestionIds, YT_TUTORIAL_URL } from 'src/common/constants-it';
import { ScreenName } from 'src/types/screen-name';
import { useNavigation } from 'src/hooks/navigation-hook';
import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';
import {
  selectors as paymentSelectors,
  actions as paymentActions,
} from 'src/stores/modules/payment';
import { isWeb } from 'src/utils/platform';
import { GTM } from 'src/biz-logic/analytics/gtm';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { ButtonName } from 'src/types/button-name';

import { useQuizmasterLight } from '../_hooks';

import { ProductBundleComponent } from './product-bundle-component';
import { useProductBundleRecommendation } from './use-product-bundle-recommendation';
import { useProductPrices } from './use-product-prices';
import { SingleProductBundle } from './single-product-bundle-component';

enum ProductBundleScreenMode {
  prefillStart = 'prefillStart',
}

const runAnalytics = (
  selectedProductBundle: ProductBundleValues,
  recommendedProductBundle: ProductBundleValues,
): void => {
  Analytics.log(AnalyticsEvent.buttonPressed, {
    buttonName: selectedProductBundle,
    screenName: ScreenName.ProductBundle,
    bundleRecommendation: recommendedProductBundle,
  });
  if (isWeb) {
    GTM.trackBundleProduct('buttonPressed', {
      category: ProductBundleScreenMode.prefillStart,
      value: selectedProductBundle,
    });
  }
  return;
};

export const ProductBundleContainer = (): JSX.Element => {
  const { instant: instantConfig, guided: guidedConfig } = useSelector(
    firebaseSelectors.getItalyProductsPaymentConfig,
  ).default;
  const couponResult = useSelector(paymentSelectors.getCouponResult);
  const productBundlesPrices = useProductPrices(instantConfig.fullPrice, guidedConfig.fullPrice);
  const recommendedProductBundle = useProductBundleRecommendation();
  const quizmasterLight = useQuizmasterLight([flagsQuestionIds.productBundleSelection]);
  const {
    getNavigationActions,
    navigation,
    navigationActions,
    safeResetNavigation,
    navigationParams,
  } = useNavigation();
  const dispatch = useDispatch();
  const { isSingleGuidedBundle } = navigationParams;

  const checkAndRemoveCoupon = (selectedProductBundle: ProductBundleValues) => {
    if (couponResult) {
      const previousProductSelection =
        quizmasterLight[flagsQuestionIds.productBundleSelection]?.answer;

      if (previousProductSelection !== selectedProductBundle) {
        dispatch(paymentActions.setCouponResult(undefined));
      }
    }
  };

  const onProductSelect = async (selectedProductBundle: ProductBundleValues) => {
    runAnalytics(selectedProductBundle, recommendedProductBundle);
    const savePromises = [];

    checkAndRemoveCoupon(selectedProductBundle);

    savePromises.push(
      quizmasterLight[flagsQuestionIds.productBundleSelection].saveAnswer(selectedProductBundle),
    );

    await Promise.all(savePromises);

    if (selectedProductBundle === ProductBundleValues.instant) {
      navigationActions.toInstantFilingNextStepsScreen('screen', {
        onNext: () => navigationActions.toItalyPayment('screen'),
      });
    } else {
      navigationActions.toItalyPayment('screen');
    }
  };

  const onPressWatchVideo = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ProductBundle,
      buttonName: ButtonName.WatchVideo,
    });
    dispatch(
      overlayActions.show('TaxGuideOverlay', {
        url: YT_TUTORIAL_URL,
        onClose: () => {
          Analytics.log(AnalyticsEvent.buttonPressed, {
            screenName: ScreenName.ProductBundle,
            buttonName: ButtonName.Close,
          });
          dispatch(overlayActions.hide());
        },
        openLinkExternally: () => Linking.openURL(YT_TUTORIAL_URL),
      }),
    );
  };

  const onPressSingleProductCta = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.ProductBundle,
      buttonName: ButtonName.Continue,
    });
    navigationActions.toItalyPayment('screen');
  };

  useEffect(() => {
    Analytics.log(AnalyticsEvent.sectionShown, {
      screenName: ScreenName.ProductBundle,
      bundleRecommendation: recommendedProductBundle,
    });
  }, [recommendedProductBundle]);

  const onPressBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      safeResetNavigation([getNavigationActions().toDashboard('screen')]);
    }
  };

  return isSingleGuidedBundle ? (
    <SingleProductBundle
      productBundlesPrices={productBundlesPrices}
      recommendedProductBundle={recommendedProductBundle}
      onPressBack={onPressBack}
      onPressPrimary={onPressSingleProductCta}
      onPressSecondary={onPressWatchVideo}
    />
  ) : (
    <ProductBundleComponent
      onProductSelect={onProductSelect}
      onBackButtonPress={onPressBack}
      productBundlesPrices={productBundlesPrices}
      recommendedProductBundle={recommendedProductBundle}
      isExperiment={false}
    />
  );
};
