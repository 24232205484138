import React, { useEffect } from 'react';
import { ScrollView, useBreakpointValue } from 'native-base';
import { Documents } from '@taxfix/taxfix-sdk';
import { Prefill } from '@taxfix/italy-types';
import { ImageBackground } from 'react-native';

import { ProductBundleValues } from 'src/common/constants-it';
import {
  Container,
  NavButton,
  Text,
  Footer,
  StepIconType,
  Stepper,
  StepperProps,
  STEP_STATUS,
} from 'src/taxfix-components/src';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { isWeb } from 'src/utils/platform';

import { IdleHelpPopupProps } from '../../screens/components/result-screen-idle-help-popup';
import confetti from '../../assets/Confetti.png';

import { FORCED_ADVANCED_BUNDLE } from './constants';
import { mapCreditResultForAnalytics } from './utils';
import { useIdlePopup } from './useIdlePopup';
import { ScreenState } from './types';
import {
  ResultCardExperiment,
  TaxExpertCardExperiment,
} from './components/prefill-documents-result-components-experiment';

type PrefillResultExperimentComponentProps = {
  taxScenario: Prefill.PrefillResultScenarios;
  recommendedBundle: typeof FORCED_ADVANCED_BUNDLE | ProductBundleValues;
  estimatedResult: number;
  totalOptimization: number;
  reasonWhyRefundIsZero: string | undefined;
  hasPrefillDocuments: boolean;
  prefillDocumentList: Documents.types.Document[];
  screenContent: ScreenState;
  setBackgroundColor: (color: string) => void;
  handleOnContinuePress: () => Promise<void>;
  handleOnBackButtonPress: () => void;
  onIdlePopupButtonPress: () => void;
  onIdlePopupShown: () => void;
  onIdlePopupClose: () => void;
  onSecondaryButtonFooterPress?: () => void | undefined;
};

export const PrefillResultScreenExperimentComponent: React.FC<PrefillResultExperimentComponentProps> =
  ({
    taxScenario,
    recommendedBundle,
    estimatedResult,
    totalOptimization,
    reasonWhyRefundIsZero,
    hasPrefillDocuments,
    prefillDocumentList,
    screenContent,
    setBackgroundColor,
    handleOnContinuePress,
    handleOnBackButtonPress,
    onIdlePopupButtonPress,
    onIdlePopupShown,
    onIdlePopupClose,
    onSecondaryButtonFooterPress,
  }) => {
    const { getTranslationText } = useItalyIntl();
    const isLargeScreen = useBreakpointValue({ base: false, md: true });
    const {
      screenTitle,
      cardBgColor,
      cardBgColorSmallScreen,
      cardDescription,
      taxExpertCardHeaderText,
      linkTranslationKey,
      taxExpertCardTitle,
      taxExpertCardDescription,
      buttonTranslationKey,
      taxExpertCardWarning,
      taxExpertCardLinkText,
      taxExpertAverageRefundChipText,
      showConfetti,
      showEstimatedResult,
    } = screenContent;
    const popupParams: IdleHelpPopupProps = {
      onButtonPress: onIdlePopupButtonPress,
      onLoadCallback: onIdlePopupShown,
      onClose: onIdlePopupClose,
    };

    useEffect(() => {
      if (!isLargeScreen) {
        setBackgroundColor(cardBgColor);
      }
    }, []);

    useEffect(() => {
      Analytics.log(AnalyticsEvent.sectionDeclarationResult, {
        documentsAmount: prefillDocumentList.length,
        documentList: prefillDocumentList.map((document) => document.type),
        resultType: mapCreditResultForAnalytics(estimatedResult),
        taxReturned: isNaN(estimatedResult) ? null : estimatedResult,
        resultScenario: taxScenario,
        bundleRecommendation: recommendedBundle,
        reasonWhyRefundIsZero,
      });
    }, []);

    useIdlePopup(popupParams);

    const handleOnSecondaryButtonFooterPress = hasPrefillDocuments
      ? onSecondaryButtonFooterPress
      : undefined;

    const secondaryButtonTranslationKey = linkTranslationKey || taxExpertCardLinkText;

    const steps: StepperProps['steps'] = [
      {
        stepIcon: 'success' as StepIconType,
        titleText: getTranslationText('prefill-result-screen.stepper.step1'),
        status: STEP_STATUS.IN_PROGRESS,
        active: true,
        titleFont: 'bodyXSBook',
      },
      {
        active: false,
        titleText: getTranslationText(
          recommendedBundle === FORCED_ADVANCED_BUNDLE
            ? 'prefill-result-screen.stepper.step2.forcedAdvanced'
            : 'prefill-result-screen.stepper.step2',
        ),
        titleFont: 'bodyXSBook',
      },
      {
        active: false,
        titleText: getTranslationText('prefill-result-screen.stepper.step3'),
        titleFont: 'bodyXSBook',
      },
      {
        active: false,
        titleText: getTranslationText('prefill-result-screen.stepper.step4'),
        titleFont: 'bodyXSBook',
      },
    ];

    return (
      <Container flex={1} width="100%" backgroundColor={!isLargeScreen ? cardBgColor : 'white'}>
        {isLargeScreen && (
          <NavButton
            text={getTranslationText('it.navigation.back-to-home')}
            onPress={handleOnBackButtonPress}
          />
        )}
        <ScrollView
          flex={1}
          contentContainerStyle={{
            paddingTop: 14,
          }}
          width="100%"
          alignSelf="center"
        >
          <Container justifyContent="center" alignItems="center" paddingX="16px">
            <ImageBackground
              source={showConfetti ? confetti : undefined}
              style={{
                width: '100%',
                maxWidth: 680,
                justifyContent: 'center',
                alignItems: 'center',
              }}
              resizeMode="repeat"
            >
              <Stepper
                steps={steps}
                borderStyle="solid"
                numberStyles={{
                  numberBackgroundColor: isLargeScreen ? 'greytones.graphicsLight' : 'white',
                  numberColor: 'greytones.textLight',
                  numberFont: 'bodyXSBook',
                }}
                borderColor={isLargeScreen ? 'greytones.graphicsLight' : 'white'}
                style={{ width: '100%', maxWidth: 680 }}
                mb={isWeb ? '20px' : 0}
              />
              <Text
                marginBottom={['24px', '32px']}
                variant={isLargeScreen ? 'titleLBold' : 'subtitleLMedium'}
                //we do not have in the theme the font style for subtitleLMedium but with size 's'
                fontWeight="bold"
                fontSize={isLargeScreen ? 'l' : 's'}
                textAlign="center"
                value={getTranslationText(screenTitle)}
              />
            </ImageBackground>
          </Container>
          <Container
            alignSelf="center"
            width="100%"
            maxWidth="580px"
            paddingX={isLargeScreen ? '0px' : '25px'}
          >
            <ResultCardExperiment
              showResult={showEstimatedResult}
              estimatedResult={estimatedResult}
              description={cardDescription}
              bg={isLargeScreen ? cardBgColor : cardBgColorSmallScreen}
              marginBottom={isLargeScreen ? '32px' : '16px'}
            />
            <TaxExpertCardExperiment
              taxExpertCardHeaderText={taxExpertCardHeaderText}
              taxExpertCardTitle={taxExpertCardTitle}
              taxExpertCardDescription={taxExpertCardDescription}
              averageRefundChipText={taxExpertAverageRefundChipText}
              optimizationValue={totalOptimization}
              marginBottom={isLargeScreen ? '48px' : '27px'}
            />
          </Container>
          {taxExpertCardWarning && (
            <Text
              variant={isLargeScreen ? 'bodySBook' : 'bodyXSBook'}
              value={getTranslationText(taxExpertCardWarning, {
                bold: (text: string) => <Text fontWeight="bold" value={text} />,
              })}
              bg={isLargeScreen ? cardBgColor : cardBgColorSmallScreen}
              alignSelf="center"
              color="greytones.textLight"
              textAlign="center"
              width="100%"
              paddingY="4px"
              maxW={isLargeScreen ? '580px' : undefined}
            />
          )}
        </ScrollView>
        <Footer
          px="32px"
          primaryButtonTitle={getTranslationText(buttonTranslationKey)}
          bg={isLargeScreen ? undefined : cardBgColor}
          secondaryButtonTitle={
            secondaryButtonTranslationKey && getTranslationText(secondaryButtonTranslationKey)
          }
          secondaryButtonProps={{ variant: 'tertiary' }}
          onPressPrimary={handleOnContinuePress}
          onPressSecondary={handleOnSecondaryButtonFooterPress}
        />
      </Container>
    );
  };
