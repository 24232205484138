import { Actionsheet, Modal } from 'native-base';
import React, { useEffect, useState } from 'react';
import { Platform, StyleSheet } from 'react-native';

import { useItalyIntl } from 'src/_italy/_hooks';
import {
  Button,
  Container,
  Icon,
  icons,
  InputField,
  Text,
  TextLink,
} from 'src/taxfix-components/src';
import { isNative } from 'src/utils/platform';

import { useKeyboardBottomInset } from './hooks/use-keyboard-bottom-inset';

const styles = StyleSheet.create({
  hoverable: {
    display: 'flex',
    ...Platform.select({
      web: {
        cursor: 'pointer',
      },
    }),
  },
});

type ApplyVoucherResponse = {
  code: string;
  amountCents: number;
};

type DiscountCardProps = {
  onApplyVoucher: (couponCode: string) => Promise<ApplyVoucherResponse>;
  onRemoveVoucher: () => void;
  couponResult: ApplyVoucherResponse | undefined;
};

const DiscountCard: React.FC<DiscountCardProps> = ({
  onApplyVoucher,
  onRemoveVoucher,
  couponResult,
}) => {
  const { getTranslationText } = useItalyIntl();
  const bottomInset = useKeyboardBottomInset();

  const [errorMessage, setErroMessage] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [hasCoupon, setHasCoupon] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => setIsOpen(true);
  const onClose = () => {
    if (!hasCoupon) {
      setCouponCode('');
    }
    if (errorMessage) {
      setErroMessage('');
    }
    setIsOpen(false);
  };

  const onTextChange = (text: string) => {
    if (errorMessage) {
      setErroMessage('');
    }
    setCouponCode(text);
  };

  const applyVoucher = async () => {
    setIsLoading(true);
    try {
      await onApplyVoucher(couponCode);
      setHasCoupon(true);
      setIsOpen(false);
    } catch (error) {
      setErroMessage('submission.payment.coupon.not-valid');
    } finally {
      setIsLoading(false);
    }
  };

  const handleOnPressLink = () => {
    if (hasCoupon) {
      onRemoveVoucher();
      setCouponCode('');
      setHasCoupon(false);
    } else {
      onOpen();
    }
  };

  useEffect(() => {
    if (couponResult) {
      setHasCoupon(true);
      setCouponCode(couponResult.code);
    }
  }, [couponResult]);

  const renderActionSheet = () => (
    <Actionsheet
      isOpen={isOpen}
      onClose={onClose}
      onTouchCancel={onClose}
      _backdrop={{ margin: -24 }}
    >
      <Actionsheet.Content background="white" borderRadius={10} bottom={bottomInset}>
        <Container
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          mb={18}
        >
          <Text variant="titleMMedium">{getTranslationText('it.payment.discount.card.title')}</Text>
        </Container>
        <InputField
          label={getTranslationText('it.payment.discount.label')}
          value={couponCode}
          onChangeText={onTextChange}
          isInvalid={!!errorMessage}
          errorMessage={errorMessage && getTranslationText(errorMessage)}
        />
        <Button
          width={{ base: 'xl', md: '1/4' }}
          title={getTranslationText('it.payment.button.link.add')}
          onPress={applyVoucher}
          variant="primary"
          disabled={!couponCode}
          isLoading={isLoading}
          m={0}
        />
      </Actionsheet.Content>
    </Actionsheet>
  );

  const renderModal = () => (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content borderRadius={25} maxW={640}>
        <Modal.CloseButton />
        <Modal.Header borderBottomWidth={0} pb={0}>
          <Text variant="titleLBold">{getTranslationText('it.payment.discount.card.title')}</Text>
        </Modal.Header>
        <Modal.Body pb={0}>
          <InputField
            label={getTranslationText('it.payment.discount.label')}
            value={couponCode}
            onChangeText={onTextChange}
            isInvalid={!!errorMessage}
            errorMessage={errorMessage && getTranslationText(errorMessage)}
          />
        </Modal.Body>
        <Modal.Footer backgroundColor="unset">
          <Button
            width={{ base: 'xl', md: '1/4' }}
            title={getTranslationText('it.payment.button.link.add')}
            onPress={applyVoucher}
            variant="primary"
            disabled={!couponCode}
            isLoading={isLoading}
            m={0}
          />
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );

  return (
    <Container flexDir="row" py="16px" justifyContent="space-between" alignItems="center">
      <Container flexDir="row" alignItems="center">
        <Icon path={icons.discount} size="20px" color="greytones.textLight" mr={12} />
        <Text
          variant="bodyMBook"
          value={getTranslationText(
            hasCoupon && couponCode
              ? 'it.payment.discount.card.with.code.title'
              : 'it.payment.discount.card.title',
          )}
        />
      </Container>
      <TextLink
        variant="linkM"
        isUnderlined
        onPress={handleOnPressLink}
        text={getTranslationText(
          hasCoupon && couponCode
            ? 'payment.credit-card.coupon.remove'
            : 'it.payment.button.link.add',
        )}
        style={styles.hoverable}
      />
      {isNative ? renderActionSheet() : renderModal()}
    </Container>
  );
};

export { DiscountCard };
