import * as React from 'react';

import { theme } from 'src/components/core/theme';
import { Container, Icon, icons, Text } from 'src/taxfix-components/src';

export const RecommendationBadge = ({ text }: { text: string }): JSX.Element => (
  <Container
    flexDirection="row"
    alignItems="center"
    px="8px"
    py="4px"
    background={theme.color.secondaryBackgroundLight}
    borderRadius="10px"
  >
    <Text mr="4px" variant="bodyXSBook" value={text} />
    <Icon size="12px" path={icons.faStar} color="secondaryCategory.skyFull" />
  </Container>
);
