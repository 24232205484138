import React from 'react';

import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useLargeScreenQuery } from 'src/components/core';
import navigate, { Action } from 'src/routes/navigate';
import { useDefaultNavigationOptions } from 'src/routes/hooks/navigation-options-hook';
import { ScreenName } from 'src/types/screen-name';

import { ProductBundleDetailsContainer } from './product-bundle-details-container';

export const toProductBundleDetails: Action = navigate(ScreenName.ProductBundleDetails, {
  name: AnalyticsEvent.sectionShown,
  params: {
    sectionName: ScreenName.ProductBundleDetails,
  },
});

export const ProductBundleDetails: React.FC = () => {
  const isLargeScreen = useLargeScreenQuery();

  useDefaultNavigationOptions(
    isLargeScreen ? { id: 'it.product-bundle-details-screen.nav.title' } : undefined,
    {
      headerBackTitleVisible: false,
    },
  );

  return <ProductBundleDetailsContainer />;
};
