import * as React from 'react';
import { useBreakpointValue } from 'native-base';
import { useSelector } from 'react-redux';
import { SubmissionWithId } from '@taxfix/submissions-types';
import { useWindowDimensions } from 'react-native';

import { theme, ScrollView } from 'src/components/core';
import { WithSubmissionPDFDownloadProps } from 'src/hocs/with-submission-pdf-download';
import { getImageAsset } from 'src/assets/image-assets';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import { CardSetting, FlowStage, useDashboard } from 'src/_italy/_hooks/use-dashboard';
import { testID } from 'src/common/testID';
import { ItalyTabs, ITALY_TABS_HEIGHT } from 'src/routes/italy-navigation/italy-tabs';
import { TabNames } from 'src/common/constants-it';
import { useShowSideTabs } from 'src/_italy/_hooks/use-screen-size';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import {
  Container,
  DownloadYourDeclarationCard,
  TaxAdvisorCard,
  TaxYearCard,
  TaxYearCardCompleted,
  Text,
  Toast,
} from 'src/taxfix-components/src';
import { getTaxSeasonInfo } from 'src/services/tax-season';
import { usePreseasonDashboardContent } from 'src/_italy/_hooks/use-dashboard-content-preseason';
import { ItalyDashboardInfoBanner } from 'src/stores/modules/remote-config-firebase';
import { ToastBannerLevel } from 'src/taxfix-components/src/components/general/Toast/types';

import { Loading } from '../../../components/loading';
import {
  NotificationBannerLegacy,
  NotificationBannerLegacyType,
} from '../../../components/notification-banner-legacy';

import { Testimonials } from './testimonials';

const groupAvatarImages = [
  getImageAsset('commercialista.commercialistaStephan'),
  getImageAsset('commercialista.commercialistaLaura'),
  getImageAsset('commercialista.commercialistav2'),
];

type Props = {
  userName: string;
  previousYearSubmissions: SubmissionWithId[];
  fetchAndOpenPDF: WithSubmissionPDFDownloadProps['fetchAndOpenPDF'];
  mainCardSetting: CardSetting;
  handleTaxAdvisorCardOnPress: (buttonName: string, stageName: string) => void;
  isLoading: boolean;
  hasError: boolean;
  shouldShowEOSBanner: boolean;
  onEOSBannerLinkPress: () => void;
  preseasonMainCardSetting: CardSetting;
  infoBannerConfig: ItalyDashboardInfoBanner;
};

const getBoldText = (text: string) => <Text variant="bodySBook" fontWeight="bold" value={text} />;

export const Dashboard: React.FC<Props> = ({
  userName,
  previousYearSubmissions,
  fetchAndOpenPDF,
  mainCardSetting,
  handleTaxAdvisorCardOnPress,
  isLoading,
  hasError,
  shouldShowEOSBanner,
  onEOSBannerLinkPress,
  preseasonMainCardSetting,
  infoBannerConfig,
}) => {
  const isSubmissionCompleted = mainCardSetting.stage === FlowStage.submissionCompleted;

  const isPendingSelfSigning = mainCardSetting.stage === FlowStage.pendingSelfSigningDocument;
  const isSelfSigned = mainCardSetting.stage === FlowStage.signedSelfSigningDocument;
  const isModdeloRedditSigned = mainCardSetting.stage === FlowStage.signedModelloRedditiDocument;
  const isPreseason = useSelector(getTaxSeasonInfo).isPreSeason;

  const { getCardContent, getDashboardScreenContent } = useDashboard();
  const { getTranslationText } = useItalyIntl();
  const { getPreseasonCardContent } = usePreseasonDashboardContent();

  const isLargeScreen = useBreakpointValue({ base: false, lg: true });

  const dir = useBreakpointValue({ base: 'column', lg: 'row' });
  const width = useBreakpointValue({ base: '100%', lg: '80%' });
  const mainCardWidth = useBreakpointValue({ base: '100%', lg: '580px' });
  const upgradeCardWith = useBreakpointValue({ base: '100%', lg: '300px' });
  const placeholderHeight = useBreakpointValue({ base: '439px', md: '599px' });
  const bannerMarginX = [0, 0, 0, 60];

  const showSideTabs = useShowSideTabs();
  const selectedYear = useSelector(settingsSelectors.selectedYear) as number;
  const taxSeason = selectedYear + 1;

  const dashboardScreenSubtitle = isSubmissionCompleted
    ? getTranslationText('it.dashboard.subtitle.submission-completed')
    : getDashboardScreenContent(mainCardSetting.stage).dashboardSubtitle;

  const taxAdvisorCardButtonTitle = getTranslationText('it.dashboard.tax-advisor-card.button');

  const getCardComponent = React.useCallback(() => {
    const cardContent = getCardContent(mainCardSetting) ?? {};

    const commonProps = {
      ...cardContent,
      onCtaPress: () => mainCardSetting.handleOnPressCTA?.(cardContent.buttonText!),
      mainButtonTestId: 'mainCTA',
      key: cardContent.sourceImageName,
    };

    if (isLoading || hasError) {
      return (
        <Container
          backgroundColor="greytones.greyBlue"
          maxWidth="580px"
          height={placeholderHeight}
          borderRadius="15px"
        >
          {isLoading && <Loading />}
        </Container>
      );
    }

    if (isPendingSelfSigning) {
      return (
        <TaxYearCard
          {...commonProps}
          collapsableTitle={getTranslationText('it.dashboard.collapsable-title', {
            taxSeason,
          })}
          collapsableSubtitle={getTranslationText(
            'it.dashboard-submissionCompleted.collapsable-subtitle',
          )}
          bgColor={theme.color.primary}
          titleColor={theme.color.overlayWhite}
          buttonVariant="primaryWhite"
          sourceImageName={cardContent.sourceImageName}
        />
      );
    }

    if (isSelfSigned) {
      return (
        <TaxYearCardCompleted
          collapsableTitle={getTranslationText('it.dashboard.collapsable-title', {
            taxSeason,
          })}
          collapsableSubtitle={getTranslationText(
            'it.dashboard-submissionCompleted.collapsable-subtitle',
          )}
          labelText={getTranslationText('it.dashboard-submissionSigned.title')}
          source="peopleTaxExpert"
          content={getTranslationText('it.dashboard-submissionSigned.description')}
        />
      );
    }

    if (isModdeloRedditSigned) {
      return (
        <TaxYearCard
          {...commonProps}
          collapsableTitle={getTranslationText('it.dashboard.collapsable-title', {
            taxSeason,
          })}
          collapsableSubtitle={getTranslationText(
            'it.dashboard-submissionCompleted.collapsable-subtitle',
          )}
          bgColor={theme.color.primary}
          titleColor={theme.color.lightText}
          buttonVariant="primaryWhite"
          sourceImageName={cardContent.sourceImageName}
        />
      );
    }

    if (!isSubmissionCompleted) {
      return <TaxYearCard {...commonProps} />;
    }

    return (
      <TaxYearCardCompleted
        innerCursor="default"
        collapsableTitle={getTranslationText('it.dashboard.collapsable-title', {
          taxSeason,
        })}
        collapsableSubtitle={getTranslationText(
          'it.dashboard-submissionCompleted.collapsable-subtitle',
        )}
        labelText={getTranslationText('it.dashboard-submissionCompleted.title')}
        source="peopleTaxExpert"
        content={getTranslationText('it.dashboard-submissionCompleted.description')}
        onPress={() => mainCardSetting.handleOnPressCTA?.('SubmissionInReview')}
      />
    );
  }, [
    isSubmissionCompleted,
    taxSeason,
    isLoading,
    hasError,
    mainCardSetting,
    getCardContent,
    getTranslationText,
    placeholderHeight,
  ]);

  const { height } = useWindowDimensions();
  const endOfSeasonBannerText = getTranslationText('it.dashboard.end-of-season-banner.text', {
    bold: getBoldText,
  });

  const getPreseasonCardComponent = React.useCallback(() => {
    const cardContent = getPreseasonCardContent(preseasonMainCardSetting) ?? {};

    return (
      <TaxYearCard
        {...cardContent}
        onCtaPress={() => preseasonMainCardSetting.handleOnPressCTA?.(cardContent.buttonText!)}
        key={cardContent.sourceImageName}
      />
    );
  }, [preseasonMainCardSetting, getPreseasonCardContent]);

  const shouldShowInfoBanner = Boolean(infoBannerConfig.enabled && infoBannerConfig.text);

  return (
    <Container h={height - ITALY_TABS_HEIGHT} overflow="hidden">
      {hasError && (
        <NotificationBannerLegacy
          notificationType={NotificationBannerLegacyType.ConnectionFailed}
        />
      )}
      <ScrollView {...testID('dashboard-scrollview')}>
        <Container mx={2}>
          {shouldShowInfoBanner && (
            <Toast
              alignItems={{ md: 'center', base: 'flex-start' }}
              mx={bannerMarginX}
              text={infoBannerConfig.text}
              iconName="Bell"
              level={ToastBannerLevel.Info}
              isHtmlContent
            />
          )}
          {shouldShowEOSBanner && (
            <Toast
              mx={bannerMarginX}
              text={endOfSeasonBannerText}
              linkText={getTranslationText('it.dashboard.end-of-season-banner.link-text')}
              onLinkPress={onEOSBannerLinkPress}
              iconName="Bell"
              marginTop={shouldShowInfoBanner ? '16px' : ''}
            />
          )}
          {/* Dashboard Title & Stepper */}
          <Container
            width={width}
            justifyContent={['space-evenly', 'flex-start']}
            flexDirection={dir}
            ml={[0, 0, 0, 60]}
          >
            <Container my={1}>
              <Text
                variant={'titleLBold'}
                value={getTranslationText('it.dashboard.title', { userName })}
              />
              <Text variant="bodyLBook" value={dashboardScreenSubtitle} />
            </Container>
          </Container>

          {/* Dashboard Main Content */}
          <Container py={1} flexDirection={dir} justifyContent={'space-around'}>
            <Container>
              {isPreseason && (
                <Container my={2} width={mainCardWidth}>
                  {getPreseasonCardComponent()}
                </Container>
              )}
              <Container my={2} width={mainCardWidth}>
                {getCardComponent()}
              </Container>
              <Container my={0}>
                {previousYearSubmissions.map((submission, index) => {
                  const year = submission.year;
                  return (
                    <Container key={index} mt={1}>
                      <DownloadYourDeclarationCard
                        cardTitle={getTranslationText(
                          'it.dashboard.stage-download-previousSubmission.card-title',
                          {
                            year: year + 1,
                          },
                        )}
                        handleTaxDeclarationDownload={() => fetchAndOpenPDF(submission)}
                        cardMainNote={getTranslationText(
                          'it.dashboard.stage-download-previousSubmission.title',
                          {
                            year: submission.year,
                          },
                        )}
                        secondarySubmissionNote={getTranslationText(
                          'it.dashboard.stage-download-previousSubmission.description',
                        )}
                        btnTitle={getTranslationText(
                          'it.dashboard.stage-download-previousSubmission.button.title',
                        )}
                      />
                    </Container>
                  );
                })}
              </Container>
            </Container>
            {!isLargeScreen && (
              <Container my={2} overflow="hidden">
                <Testimonials />
              </Container>
            )}
            <Container my={2}>
              <Container>
                <Text
                  variant="titleSBold"
                  value={getTranslationText('it.dashboard.tax-advisor-title')}
                />
                <TaxAdvisorCard
                  my={1}
                  width={upgradeCardWith}
                  content={getTranslationText('it.dashboard.tax-advisor-card.description', {
                    bold: getBoldText,
                  })}
                  buttonText={taxAdvisorCardButtonTitle}
                  avatarSources={groupAvatarImages}
                  onPress={() =>
                    handleTaxAdvisorCardOnPress(taxAdvisorCardButtonTitle, mainCardSetting.stage)
                  }
                />
              </Container>
            </Container>
          </Container>
          {isLargeScreen && (
            <Container width="612px" ml={[0, 0, 0, 60]} mb={4}>
              <Testimonials />
            </Container>
          )}
        </Container>
      </ScrollView>
      {!showSideTabs && <ItalyTabs highlighedTab={TabNames.Dashboard} />}
    </Container>
  );
};
