import { useState, useRef, useCallback } from 'react';

const usePolling = (pollFn: () => void, intervalMs: number): [boolean, () => void, () => void] => {
  const fetchInterval: any = useRef(null);
  const [isPolling, togglePolling] = useState(false);
  const start = useCallback(() => {
    if (fetchInterval.current) {
      clearInterval(fetchInterval.current);
    }

    pollFn();
    fetchInterval.current = setInterval(pollFn, intervalMs);
    togglePolling(true);
  }, [pollFn, intervalMs]);
  const stop = useCallback(() => {
    if (!fetchInterval.current) {
      return;
    }

    clearInterval(fetchInterval.current);
    fetchInterval.current = null;
    togglePolling(false);
  }, []);
  return [isPolling, start, stop];
};

export default usePolling;
