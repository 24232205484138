import React from 'react';
import { Pressable, ScrollView } from 'react-native';
import { Stack, useBreakpointValue } from 'native-base';

import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { Container, Icon, icons, Text } from 'src/taxfix-components/src';
import { ScreenName } from 'src/types/screen-name';
import { setItem, getItem } from 'src/utils/asyncStorage';

const ASYNC_STORAGE_KEY = 'testimonialsScrollUnblocked';

const Rating = (): JSX.Element => {
  return (
    <Stack direction="row" space="6px">
      {Array.from({ length: 5 }, (_, index) => (
        <Icon key={index} path={icons.faStar} size="12px" color="secondaryCategory.orangeFull" />
      ))}
    </Stack>
  );
};

const Cards = (): JSX.Element => {
  const { getTranslationText } = useItalyIntl();

  return (
    <Stack direction="row" space={6} alignItems="flex-start" pb={{ base: '0px', lg: 2 }}>
      {Array.from({ length: 4 }, (_, index) => (
        <Stack
          key={index}
          space="16px"
          width="294px"
          padding="24px"
          backgroundColor="greytones.greyBlue"
          borderRadius="15px"
        >
          <Rating />
          <Container paddingLeft="6px" borderLeftWidth="1px" borderColor="greytones.graphicsDark">
            <Text
              variant="bodySBold"
              value={getTranslationText(`it.dashboard.testimonials.${index + 1}.title`)}
            />
            <Text
              variant="bodySBook"
              color="greytones.textLight"
              value={getTranslationText(`it.dashboard.testimonials.${index + 1}.text`)}
            />
          </Container>
          <Text
            variant="titleXXSMedium"
            value={getTranslationText(`it.dashboard.testimonials.${index + 1}.author`)}
          />
        </Stack>
      ))}
    </Stack>
  );
};

const ViewMoreButton = ({ handleOnPress }: { handleOnPress: () => void }) => {
  return (
    <Container
      position="absolute"
      right="0px"
      width="48px"
      height="100%"
      backgroundColor="transparencyLight.light80"
    >
      <Pressable
        onPress={handleOnPress}
        style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}
      >
        <Icon path={icons.chevronRight} size="20px" color="secondaryFeedback.greenDark" />
      </Pressable>
    </Container>
  );
};

export const Testimonials: React.FC = () => {
  const [allowScrolling, setAllowScrolling] = React.useState(false);
  const scrollViewRef = React.useRef<ScrollView>(null);
  const isLargeScreen = useBreakpointValue({ base: false, lg: true });
  const { getTranslationText } = useItalyIntl();

  const onViewMorePress = async () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      sectionName: ScreenName.Dashboard,
      buttonName: 'viewMoreTestimonials',
    });

    await setItem(ASYNC_STORAGE_KEY, 'true');
    setAllowScrolling(true);

    scrollViewRef.current?.scrollTo({ x: 318, y: 0, animated: true });
  };

  React.useEffect(() => {
    const checkScrollUnblocked = async () => {
      const scrollUnblocked = await getItem(ASYNC_STORAGE_KEY);

      if (scrollUnblocked) {
        setAllowScrolling(true);
      }
    };

    checkScrollUnblocked();
  }, []);

  return (
    <>
      <Text
        mb="4px"
        variant="titleSBold"
        value={getTranslationText('it.dashboard.testimonials.title')}
      />
      <Text
        mb={1}
        variant="bodyXSBook"
        value={getTranslationText('it.dashboard.testimonials.subtitle')}
      />

      <Container position="relative">
        <ScrollView
          ref={scrollViewRef}
          snapToInterval={318}
          scrollEnabled={allowScrolling}
          showsHorizontalScrollIndicator={isLargeScreen}
          horizontal
        >
          <Cards />
        </ScrollView>

        {!allowScrolling && <ViewMoreButton handleOnPress={onViewMorePress} />}
      </Container>
    </>
  );
};
