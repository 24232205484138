import * as React from 'react';

import { Container, Image, Text } from 'src/taxfix-components/src';

export const ListItem = ({ text }: { text: string }): JSX.Element => {
  return (
    <Container>
      <Image
        name="checkMarkThin"
        resizeMode="contain"
        position="absolute"
        width="16px"
        height="16px"
      />
      <Text marginLeft="22px" value={text} variant="bodySBook" />
    </Container>
  );
};
