import * as React from 'react';
import { useSelector } from 'react-redux';

import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { useNavigation } from 'src/hooks/navigation-hook';
import { useTimeout } from 'src/hooks/use-timeout';
import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';

type TimeoutHandlerNames =
  | 'startInitialLoadTimeout'
  | 'clearInitialLoadTimeout'
  | 'startProviderSelectionTimeout'
  | 'clearProviderSelectionTimeout'
  | 'startLoginWithProviderTimeout'
  | 'clearLoginWithProviderTimeout';

type UseNoSPIDExperience = Record<TimeoutHandlerNames, () => void>;

export function useNoSPIDExperience(): UseNoSPIDExperience {
  const { getNavigationActions, navigationActions } = useNavigation();
  const {
    initialLoadTimeout: initialLoadTimeoutDelay,
    providerSelectionTimeout: providerSelectionTimeoutDelay,
    loginWithProviderTimeout: loginWithProviderTimeoutDelay,
  } = useSelector(firebaseSelectors.getNoSPIDExperienceThresholds);

  const logTimeoutEventAndNavigate = React.useCallback(
    (spidFlowScreenName: string) => {
      Analytics.log(AnalyticsEvent.spidTimeoutOperationalRedirect, {
        screenName: spidFlowScreenName,
      });
      navigationActions.reset({
        index: 1,
        actions: [
          getNavigationActions().toDashboard('screen'),
          getNavigationActions().toSpidTimeout('screen'),
        ],
      });
    },
    [getNavigationActions, navigationActions],
  );

  const { start: startInitialLoadTimeout, clear: clearInitialLoadTimeout } = useTimeout(() => {
    logTimeoutEventAndNavigate('SPIDIframeInit');
  }, initialLoadTimeoutDelay);

  const { start: startProviderSelectionTimeout, clear: clearProviderSelectionTimeout } = useTimeout(
    () => {
      logTimeoutEventAndNavigate('SPIDProviderSelection');
    },
    providerSelectionTimeoutDelay,
  );

  const { start: startLoginWithProviderTimeout, clear: clearLoginWithProviderTimeout } = useTimeout(
    () => {
      logTimeoutEventAndNavigate('SPIDProviderLogin');
    },
    loginWithProviderTimeoutDelay,
  );

  return {
    startInitialLoadTimeout,
    clearInitialLoadTimeout,
    startProviderSelectionTimeout,
    clearProviderSelectionTimeout,
    startLoginWithProviderTimeout,
    clearLoginWithProviderTimeout,
  };
}
