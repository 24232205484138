import * as React from 'react';

type Ref = null | {
  scrollViewRef: {
    getScrollResponder: () => {
      scrollTo: (arg0: { y: number }) => void;
    };
  };
};
type Event = {
  nativeEvent: {
    layout: {
      y: number;
      height: number;
    };
  };
};
export const useInputScrollHelper = () => {
  const scrollViewRef = React.useRef<Ref>(null);
  const [rects, setRects]: any = React.useState({});
  const [scrollViewHeight, setScrollViewHeight] = React.useState(0);

  const registerLabel =
    (label: string) =>
    ({ nativeEvent: { layout } }: Event) => {
      setRects((current: any) => ({
        ...current,
        [label]: {
          y: layout.y,
          height: layout.height,
        },
      }));
    };

  const registerScrollViewRef = (ref: Ref) => {
    scrollViewRef.current = ref;
  };

  const registerContainer = ({ nativeEvent: { layout } }: Event) => {
    setScrollViewHeight(layout.height);
  };

  const withScrollToLabel = React.useCallback(
    (onFocus: (...args: Array<any>) => any, label: string) => () => {
      onFocus();
      const { y, height } = rects[label];
      const targetY = y - scrollViewHeight + height;

      if (scrollViewRef.current) {
        scrollViewRef.current.scrollViewRef.getScrollResponder().scrollTo({
          y: targetY,
        });
      }
    },
    [rects, scrollViewHeight],
  );
  return {
    scrollViewRef,
    registerLabel,
    registerContainer,
    registerScrollViewRef,
    withScrollToLabel,
  };
};
