import { useCallback, useEffect, useMemo, useState } from 'react';
import Config from 'react-native-config';
import { Payment as PaymentSDK, Voucher as VoucherSdk } from '@taxfix/payment-sdk';
import { useDispatch, useSelector } from 'react-redux';
import { CountryCodes } from '@taxfix/types';
import { useRoute } from '@react-navigation/native';

import { useQuizmasterLight } from 'src/_italy/_hooks';
import {
  ProductBundleValues,
  flagsQuestionIds,
  prefillQuestionIds,
  wayQuestionIds,
} from 'src/common/constants-it';
import { PaymentCustomer, PaymentStates } from 'src/types/payment';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import {
  selectors as paymentSelectors,
  actions as paymentActions,
} from 'src/stores/modules/payment';
import { actions as OverlayActions } from 'src/stores/modules/overlay';
import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { getPaymentFromPaymentId } from 'src/services/submissions';
import { useNavigation } from 'src/hooks/navigation-hook';
import { ScreenName } from 'src/types/screen-name';

import { StripePaymentError, StripePaymentErrorMessages } from '../errors';
import {
  PaymentError,
  clearPaymentUrlParams,
  getBillingAddress,
  sendAnalyticsOnPaymentFailure,
} from '../utils';
import {
  ApplyVoucherResponse,
  ConfirmPaymentCallback,
  ItalyPaymentType,
  StripeRedirectStatus,
} from '../stripe/types';

import { useProductInfo } from './use-productInfo';

type Params = {
  isModal: boolean;
  redirectStatus: StripeRedirectStatus;
  tracking: string;
};

type UsePaymentResult = {
  handleOnSubmit: (
    confirmPayment: ConfirmPaymentCallback,
    paymentMethod?: ItalyPaymentType,
  ) => Promise<void>;
  handleFinished: (paymentId: number, paymentMethod?: ItalyPaymentType) => Promise<void>;
  productPrice: number;
  productOriginalPrice: number;
  onApplyVoucher: (couponCode: string) => Promise<ApplyVoucherResponse>;
  onRemoveVoucher: () => void;
  validateForm: () => boolean;
  paypalError: string | null;
};

const usePayment = (): UsePaymentResult => {
  const { product } = useProductInfo();
  const [paypalError, setPaypalError] = useState<string | null>(null);
  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const paymentConfig = useSelector(firebaseSelectors.getItalyProductsPaymentConfig);
  const couponResult = useSelector(paymentSelectors.getCouponResult);

  const dispatch = useDispatch();
  const { getNavigationActions, safeResetNavigation } = useNavigation();
  const route = useRoute();
  const params = route.params as Params;

  const questionIds: string[] = Object.values({
    ...flagsQuestionIds,
    ...prefillQuestionIds,
    ...wayQuestionIds,
  });
  const quizmasterLight = useQuizmasterLight(questionIds);
  const selectedProductBundle = quizmasterLight[flagsQuestionIds.productBundleSelection].answer;
  const taxId: string = quizmasterLight[prefillQuestionIds.taxId]?.answer;
  const isSPIDTimeoutFlow = quizmasterLight[flagsQuestionIds.SPIDTimeoutFlow].answer;
  const isInstantProductBundle = selectedProductBundle === ProductBundleValues.instant;
  const billingAddress = quizmasterLight[prefillQuestionIds.billingAddress].answer?.displayName;
  const year = useSelector(settingsSelectors.selectedYear) as number;
  const userEmail = useSelector(userAuthSelectors.getEmailAddress);

  const voucherSPIDTimeoutFlow = paymentConfig.SPIDTimeout[ProductBundleValues.guided].voucher;

  const customer: PaymentCustomer = useMemo(
    () => ({
      firstName: quizmasterLight[wayQuestionIds.firstName].answer,
      lastName: quizmasterLight[wayQuestionIds.lastName].answer,
      email: userEmail,
      ...getBillingAddress(),
    }),
    [userEmail, quizmasterLight],
  );

  const nextNavigationActionDefault = useCallback(() => {
    dispatch(
      OverlayActions.show('SuccessOverlay', {
        icon: 'interface-icons-svg.icon-checkmark-white',
        titleId: 'it.success-overlay.payment.title',
        descriptionId: 'it.success-overlay.payment.description',
        buttonId: 'it.success-overlay.payment.button',
        exitDirection: 'top',
        onNext: () => {
          dispatch(OverlayActions.hide());
          safeResetNavigation([
            getNavigationActions().toDashboard('screen'),
            getNavigationActions().toStatus('screen'),
          ]);
        },
      }),
    );
  }, [dispatch, getNavigationActions, safeResetNavigation]);

  const onApplyVoucher = useCallback(
    async (couponCode: string) => {
      if (!product) {
        throw new Error('cannot create coupon without product');
      }
      try {
        Analytics.log(AnalyticsEvent.buttonPressed, {
          buttonName: 'AddCouponCode',
          screenName: ScreenName.PaymentItaly,
        });

        const couponResponse = await VoucherSdk.apply(Config.API_BASE_URL, accessToken, {
          code: couponCode,
          productId: product.id,
          metadata: {
            order: {
              productId: product.id,
            },
          },
        });
        dispatch(paymentActions.setCouponResult(couponResponse));
        Analytics.log(AnalyticsEvent.buttonPressed, {
          buttonName: 'CouponCodeAdded',
          screenName: ScreenName.PaymentItaly,
        });

        Analytics.log(AnalyticsEvent.validateCouponSuccess, {
          year: year,
          paymentType: 'direct-debit',
          couponCode: couponCode,
        });
        return couponResponse;
      } catch (error: any) {
        Analytics.log(AnalyticsEvent.validateCouponFailed, {
          errorType: error?.response?.data?.statusCode,
          errorMessage: error?.response?.data?.message,
          year,
          paymentType: 'direct-debit',
          couponCode: couponCode,
        });
        throw error;
      }
    },
    [accessToken, dispatch, product, year],
  );

  const onRemoveVoucher = () => {
    dispatch(paymentActions.setCouponResult(undefined));
  };

  const assignAddressState = (customer: PaymentCustomer) => {
    const customerWithAddressState: PaymentCustomer & { addressState?: string } = { ...customer };

    if (customer && customer.state) {
      customerWithAddressState.addressState = customer.state;
      delete customerWithAddressState.state;
    }

    return customerWithAddressState;
  };

  const currentProductPrice = useCallback(() => {
    if (!product) return null;
    const { amount } = product;

    if (couponResult) {
      return Math.max(0, amount - couponResult.amountCents) / 100;
    }
    return amount / 100;
  }, [product, couponResult]);

  const getFallbackPrice = () => {
    const fallbackPrice = isInstantProductBundle
      ? paymentConfig.default[ProductBundleValues.instant].fullPrice
      : paymentConfig.default[ProductBundleValues.guided].fullPrice;

    return fallbackPrice;
  };

  const productOriginalPrice = product ? product.amount / 100 : getFallbackPrice();

  const createPayment = useCallback(
    async (
      paymentMethod: PaymentSDK.PaymentType.Card | PaymentSDK.PaymentType.PayPal = PaymentSDK
        .PaymentType.Card,
    ) => {
      if (!product) {
        throw new Error('cannot proceed payment, no product present');
      }

      const voucherCode = couponResult && couponResult.code;

      try {
        const { id } = await PaymentSDK.create(Config.API_BASE_URL, accessToken, {
          taxId,
          customer: assignAddressState(customer),
          type: paymentMethod,
          productId: product.id,
          countryCode: CountryCodes.IT,
          ...(voucherCode && { coupon: { code: voucherCode } }),
        });

        Analytics.log(AnalyticsEvent.paymentCreatedClient, {
          year,
          paymentId: id,
          taxCountry: CountryCodes.IT,
          productId: product.id,
          amount: currentProductPrice(),
          currency: product.currency,
          paymentMethod,
          voucherCode,
        });
        return id as unknown as number;
      } catch (error) {
        Analytics.log(AnalyticsEvent.paymentCreateErrorClient, {
          year,
          taxCountry: CountryCodes.IT,
          productId: product.id,
          amount: currentProductPrice(),
          currency: product.currency,
          paymentMethod,
          voucherCode,
          errorMessage: (error as Error).message,
        });
        throw error;
      }
    },
    [accessToken, couponResult, currentProductPrice, customer, product, taxId, year],
  );

  const updatePayment = useCallback(
    async (customer: PaymentCustomer, paymentId: number) => {
      const voucherCode = couponResult && couponResult.code;

      try {
        await PaymentSDK.update(Config.API_BASE_URL, accessToken, paymentId, {
          customer: assignAddressState(customer),
          ...(voucherCode && { coupon: { code: voucherCode } }),
        });
        Analytics.log(AnalyticsEvent.paymentUpdatedClient, {
          paymentId,
          year,
          taxCountry: CountryCodes.IT,
          productId: product && product.id,
          amount: currentProductPrice(),
          currency: product && product.currency,
          voucherCode,
        });
      } catch (error) {
        Analytics.log(AnalyticsEvent.paymentUpdateErrorClient, {
          paymentId,
          year,
          taxCountry: CountryCodes.IT,
          productId: product && product.id,
          amount: currentProductPrice(),
          currency: product && product.currency,
          voucherCode,
          errorMessage: (error as Error).message,
        });
        throw error;
      }
    },
    [accessToken, couponResult, currentProductPrice, product, year],
  );

  const handleOnSubmit = useCallback(
    async (
      confirmPayment: ConfirmPaymentCallback,
      paymentMethod: ItalyPaymentType = PaymentSDK.PaymentType.Card,
    ) => {
      if (!product) {
        throw new Error('Product doest exist');
      }
      let paymentId = product.paymentId;
      if (paymentId) {
        Analytics.log(AnalyticsEvent.paymentInitiated, {
          paymentType: paymentMethod,
        });
      }

      try {
        if (paymentId) {
          await updatePayment(customer, paymentId);
        } else {
          paymentId = await createPayment(paymentMethod);
        }
        const payment = await getPaymentFromPaymentId(accessToken, paymentId);

        if (payment.type !== paymentMethod) {
          throw new Error(PaymentError.InvalidMethod);
        }

        if (payment.amountCents === 0) {
          nextNavigationActionDefault();
        } else if (confirmPayment && payment.clientSecret) {
          await confirmPayment(payment.clientSecret, paymentId);
        }
      } catch (error) {
        if (error?.message === PaymentError.InvalidMethod) {
          logger.warn(new StripePaymentError(StripePaymentErrorMessages.invalidMethod), {
            lastAttemptedMethod: paymentMethod,
          });
        } else {
          logger.error(new StripePaymentError(StripePaymentErrorMessages.failed), {
            error,
            lastAttemptedMethod: paymentMethod,
          });
        }
        throw error;
      }
    },
    [accessToken, createPayment, customer, updatePayment, product, nextNavigationActionDefault],
  );

  const handleFinished = useCallback(
    async (paymentId: number, paymentMethod: ItalyPaymentType = PaymentSDK.PaymentType.Card) => {
      try {
        if (!product) {
          throw new Error('Could not handle finish, because there is no product');
        }

        try {
          await PaymentSDK.update(Config.API_BASE_URL, accessToken, paymentId, {
            clientFinished: true,
          });
        } catch (error: any) {
          // Sometimes, if webhook is faster than client and payment is already
          // processed before we indicate client finished, we will have payment_has_been_processed
          // conflict error so we can just continue.
          // This was added to bypass delays in processing payments on the back-end side and avoid
          // blocking users that had already paid.
          if (error?.response?.data?.message !== 'payment_has_been_processed') {
            logger.warn(new StripePaymentError(StripePaymentErrorMessages.default), {
              message: error?.message,
            });
          } else {
            throw error;
          }
        }

        Analytics.log(AnalyticsEvent.paymentSuccess, {
          productId: product.id,
          paid_amount: currentProductPrice(),
          currency: product.currency,
          year,
          paymentType: paymentMethod,
        });
      } catch (error) {
        logger.error(new StripePaymentError(StripePaymentErrorMessages.updateFailed), {
          message: error?.message,
        });
      }

      nextNavigationActionDefault();
    },
    [accessToken, currentProductPrice, product, year, nextNavigationActionDefault],
  );

  useEffect(() => {
    const checkAndFinishPayPal = async (paymentId: number) => {
      const payment = await getPaymentFromPaymentId(accessToken, paymentId);
      if (
        payment.state === PaymentStates.Completed &&
        params.redirectStatus === StripeRedirectStatus.succeeded
      ) {
        handleFinished(payment.id, PaymentSDK.PaymentType.PayPal);
      } else if (params.redirectStatus === StripeRedirectStatus.failed) {
        setPaypalError('payment.paymentError');
        sendAnalyticsOnPaymentFailure(
          PaymentSDK.PaymentType.PayPal,
          'stripe_payment_failed',
          'paypal_payment_failed',
        );
      } else if (PaymentError) {
        setPaypalError(null);
      }
      clearPaymentUrlParams();
    };
    if (product?.paymentId) {
      checkAndFinishPayPal(product?.paymentId);
    }
  }, [nextNavigationActionDefault, accessToken, params.redirectStatus, product, handleFinished]);

  useEffect(() => {
    if (!product || !selectedProductBundle) return;
    const defaultVoucher = isSPIDTimeoutFlow
      ? voucherSPIDTimeoutFlow
      : paymentConfig.default[selectedProductBundle as ProductBundleValues].voucher;

    if (!defaultVoucher) return;

    onApplyVoucher(defaultVoucher);
  }, [
    product,
    selectedProductBundle,
    paymentConfig,
    onApplyVoucher,
    isSPIDTimeoutFlow,
    voucherSPIDTimeoutFlow,
  ]);

  const validateForm = (): boolean => {
    return Boolean(billingAddress);
  };

  return {
    handleOnSubmit,
    handleFinished,
    productOriginalPrice,
    productPrice: currentProductPrice() ?? productOriginalPrice,
    onApplyVoucher,
    onRemoveVoucher,
    validateForm,
    paypalError,
  };
};

export { usePayment };
