import React from 'react';
import { View, StyleSheet, ImageStyle } from 'react-native';

import { isMobileWeb, isNative } from '../utils/platform';

import { Icon, theme, Button, BigDisplayTitleText, DisplayPrimaryText } from './core';

const styles = StyleSheet.create({
  componentContainer: {
    flex: 1,
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
  },
  componentContainerBig: {
    maxWidth: 500,
  },
  textContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  textContainerSmall: {
    justifyContent: 'center',
  },
  image: {
    marginBottom: 36,
  },
  titleText: {
    textAlign: 'center',
    fontSize: 32,
    lineHeight: 40,
    marginBottom: 15,
    marginTop: 0,
  },
  titleTextSmall: {
    marginTop: 36,
    marginHorizontal: 20,
  },
  descriptionText: {
    textAlign: 'center',
  },
  buttonContainer: {
    width: 218,
    padding: 0,
    marginTop: 0,
  },
  buttonContainerSmall: {
    width: '100%',
    marginTop: 'auto',
    marginBottom: 20,
  },
});
export type SuccessOverlayContentProps = {
  icon: string;
  iconStyles?: ImageStyle;
  titleId: string;
  descriptionId: string;
  buttonId: string;
  onNext: () => void;
  translationValues?: {
    userName: string;
  };
};
export const SuccessOverlayContent = ({
  icon,
  iconStyles,
  titleId,
  descriptionId,
  buttonId,
  translationValues,
  onNext,
}: SuccessOverlayContentProps) => {
  const isMobileScreen = isMobileWeb || isNative;
  return (
    <View
      style={
        isMobileScreen
          ? styles.componentContainer
          : [styles.componentContainer, styles.componentContainerBig]
      }
    >
      <View
        style={
          isMobileScreen ? [styles.textContainer, styles.textContainerSmall] : styles.textContainer
        }
      >
        <Icon
          name={icon}
          style={StyleSheet.flatten([isMobileScreen ? undefined : styles.image, iconStyles])}
          resizeMode="stretch"
        />
        <BigDisplayTitleText
          color={theme.color.lightText}
          id={titleId}
          values={translationValues}
          style={isMobileScreen ? [styles.titleText, styles.titleTextSmall] : styles.titleText}
        />
        <DisplayPrimaryText
          color={theme.color.lightText}
          id={descriptionId}
          style={styles.descriptionText}
        />
      </View>
      <View
        style={
          isMobileScreen
            ? [styles.buttonContainer, styles.buttonContainerSmall]
            : styles.buttonContainer
        }
      >
        <Button
          fillColor={theme.color.lightFill}
          textColor={theme.color.primary}
          onPress={onNext}
          translationKey={buttonId}
          testId="test.button.success-overlay.continue"
        />
      </View>
    </View>
  );
};
