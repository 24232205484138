import React from 'react';
import { StyleSheet } from 'react-native';

import { Text } from 'src/taxfix-components/src';
import { AddDocumentButton } from 'src/components/add-document-button';
import { Box, Image, theme, useLargeScreenQuery } from 'src/components/core';
import { MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB } from 'src/utils/constants';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';

type Props = {
  onPress: () => void;
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 156,
    borderWidth: 1,
    borderStyle: 'dashed',
    borderColor: theme.color.disabledText,
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
    zIndex: 1,
    padding: '16px 20px',
  },
  containerSmallScreen: {
    flexDirection: 'column',
    height: 104,
    justifyContent: 'center',
    width: '100%',
  },
});

export const FileUploadWeb: React.FC<Props> = ({ onPress }) => {
  const allowedTypesTranslationKey = 'it.digital-caf.required-documents.web.dropzone.allowed-types';
  const { getTranslationText } = useItalyIntl();
  const isLargeScreen = useLargeScreenQuery();

  return (
    <Box
      style={isLargeScreen ? styles.container : [styles.container, styles.containerSmallScreen]}
      alignVertically="center"
    >
      {isLargeScreen && <Image maxWidth={120} name="upload-first-doc.upload-first-doc" />}

      <AddDocumentButton onPress={onPress} />

      <Box direction="column" alignVertically="center">
        {isLargeScreen && (
          <Text
            variant="bodyMBook"
            color="greytones.textLight"
            value={getTranslationText('it.digital-caf.required-documents.add-files-intial.title')}
            marginBottom={11}
          />
        )}
        <Text
          value={getTranslationText(allowedTypesTranslationKey, {
            mb: MAX_FILE_SIZE_FOR_IMAGE_RECOGNITION_MB,
          })}
          variant="bodyXSBook"
          testID={allowedTypesTranslationKey}
          color="greytones.graphicsDark"
          marginTop={!isLargeScreen ? 16 : 0}
        />
      </Box>
    </Box>
  );
};
