import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'src/stores/store/initial';

import {
  BannerSize,
  NotificationBannerLegacy,
  NotificationBannerLegacyType,
} from '../components/notification-banner-legacy';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import {
  actions as userAuthActions,
  Provider,
  selectors as userAuthSelectors,
} from '../stores/modules/user-auth';
import {
  actions as emailChangeActions,
  selectors as emailChangeSelectors,
} from '../stores/modules/user-email-change';
import usePolling from '../hooks/usePolling';

type Props = {
  shouldPoll?: boolean;
  showWhenPending?: boolean;
  bannerSize?: BannerSize;
  fallbackElement?: React.ReactElement<any>;
};

const resolveBannerType = (status: any) => {
  switch (status) {
    case 'PENDING':
      return NotificationBannerLegacyType.EmailChangePending;

    case 'CONFIRMED':
      return NotificationBannerLegacyType.EmailChangeConfirmed;

    default:
      return null;
  }
};

const EmailChangeBanner = ({
  shouldPoll,
  bannerSize,
  // @ts-ignore
  fallbackElement = null,
  showWhenPending = true,
}: Props) => {
  const pollingFreq = 5000;
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector(userAuthSelectors.isAuthenticated);
  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);
  const isStillPending = useSelector(emailChangeSelectors.isNotSettled);
  const isConfirmed = useSelector(emailChangeSelectors.isConfirmed);
  const hasExpired = useSelector(emailChangeSelectors.hasExpired);
  const hasFailed = useSelector(emailChangeSelectors.hasFailed);
  const changeRequestStatus = useSelector(emailChangeSelectors.getEmailChangeStatus);
  const newEmail = useSelector(emailChangeSelectors.getNewEmail);
  const bannerType = resolveBannerType(changeRequestStatus);
  // we should poll for the change request status is IDLE or PENDING
  const shouldBePolling = shouldPoll && isAuthenticated && isStillPending;
  const checkEmailChangeStatus = useCallback(() => {
    if (!isStillPending) {
      return;
    }

    dispatch(emailChangeActions.fetchEmailChangeStatus(accessToken));
  }, [isStillPending, accessToken, dispatch]);
  const onChangeConfirmed = useCallback(() => {
    userAuthActions.updateProvider(Provider.email);
    dispatch(emailChangeActions.resetStoredCredentials(newEmail, selectedCountry, accessToken));
    setTimeout(() => {
      dispatch(emailChangeActions.resetEmailChangeStatus());
    }, pollingFreq);
  }, [accessToken, newEmail, selectedCountry, dispatch]);
  const [isPolling, startPolling, stopPolling] = usePolling(checkEmailChangeStatus, pollingFreq);
  useEffect(() => {
    if (shouldBePolling && !isPolling) {
      startPolling();
    }

    if (isConfirmed) {
      onChangeConfirmed();
      stopPolling();
    }

    if (hasExpired || hasFailed) {
      stopPolling();
    }

    return () => {
      if (isPolling) {
        stopPolling();
      }
    };
  }, [
    shouldBePolling,
    isPolling,
    startPolling,
    stopPolling,
    isConfirmed,
    hasExpired,
    hasFailed,
    onChangeConfirmed,
  ]);

  if (!isAuthenticated || !bannerType) {
    return fallbackElement;
  }

  if (shouldBePolling && !showWhenPending) {
    return fallbackElement;
  }

  return (
    <NotificationBannerLegacy
      notificationType={bannerType}
      values={{
        email: newEmail,
      }}
      isError={hasFailed}
      bannerSize={bannerSize}
    />
  );
};

export default EmailChangeBanner;
