export enum ScreenName {
  AccountScreen = 'AccountScreen',
  BillingAddress = 'BillingAddress',
  ContactSupport = 'ContactSupport',
  Dashboard = 'Dashboard',
  DebugResponses = 'DebugResponses',
  DebugCWBIUrl = 'DebugCWBIUrl',
  DigitalCafSubCategory = 'DigitalCafSubCategory',
  HomeScreen = 'HomeScreen',
  ItalyAccount = 'ItalyAccount',
  ItalyChat = 'ItalyChat',
  ItalyCloseAccount = 'ItalyCloseAccount',
  ItalyDeleteAccountScreen = 'ItalyDeleteAccountScreen',
  ItalyDonation = 'ItalyDonation',
  ItalyGDPRConsent = 'ItalyGDPRConsent',
  ItalyGDPRSPIDConsent = 'ItalyGDPRSPIDConsent',
  ItalyOnboardingPreSeason = 'ItalyOnboardingPreSeason',
  ItalyPostSubmission = 'ItalyPostSubmission',
  ItalyPrivacySettings = 'ItalyPrivacySettings',
  ItalySubmission = 'ItalySubmission',
  ITDashboard = 'IT.Dashboard',
  ITDigicafProfileOccupation = 'IT.DigicafProfileOccupation',
  ITDigicafProfilePersonalData = 'IT.DigicafProfilePersonalData',
  ITDonation = 'IT.Donation',
  ITOnboarding = 'IT.Onboarding',
  ITOnboardingPreSeason = 'IT.OnboardingPreSeason',
  ITPaymentCreditCard = 'IT.PaymentCreditCard',
  ITQuestions = 'IT.Questions',
  ITRequiredDocuments = 'IT.RequiredDocuments',
  ITRequiredDocumentsInfoGuidance = 'IT.RequiredDocumentsInfoGuidance',
  ITRequiredDocumentsUpload = 'IT.RequiredDocumentsUpload',
  ITRequiredDocumentsUploadGuidance = 'IT.RequiredDocumentsUploadGuidance',
  ITSubmission = 'IT.Submission',
  ITTaxEpert = 'IT.TaxExpert',
  ITTaxGuide = 'IT.TaxGuide',
  IdCardUpload = 'IdCardUpload',
  DrivingLicenseUpload = 'DrivingLicenseUpload',
  PassportUpload = 'PassportUpload',
  ImportResponses = 'ImportResponses',
  Information = 'Information',
  ItalySPIDScreen = 'SPIDScreen',
  ItalySPIDConsentScreen = 'SPIDConsentScreen',
  LockScreen = 'LockScreen',
  Login = 'Login',
  LogoutSuccess = 'LogoutSuccess',
  NotificationScreen = 'NotificationScreen',
  Notifications = 'Notifications',
  Onboarding = 'Onboarding',
  PaymentCreditCardNative = 'PaymentCreditCard.Native',
  PaymentItaly = 'PaymentItaly',
  PhoneNumber = 'PhoneNumber',
  PrefillDocumentUpload = 'PrefillDocumentUpload',
  InstantFilingNextSteps = 'InstantFilingNextSteps',
  PrivacyPolicy = 'PrivacyPolicy',
  PrivacySettings = 'PrivacySettings',
  ProfileDetails = 'ProfileDetails',
  ProfileEdit = 'ProfileEdit',
  ProfileOccupation = 'ProfileOccupation',
  ProfilePersonalData = 'ProfilePersonalData',
  QuizMaster = 'QuizMaster',
  RequiredDocumentsDigitalCaf = 'RequiredDocumentsDigitalCaf',
  RequiredDocumentsPreview = 'RequiredDocumentsPreview',
  RequiredDocumentsUpload = 'RequiredDocumentsUpload',
  SignUp = 'SignUp',
  SkippedSectionReminder = 'SkippedSectionReminder',
  StatusScreen = 'StatusScreen',
  SubmissionsDownload = 'SubmissionsDownload',
  TaxExpert = 'TaxExpert',
  TaxGuide = 'TaxGuide',
  ItalyIFrameSPIDLogin = 'ItalyIFrameSPIDLogin',
  PrefillDocumentsResult = 'PrefillDocumentsResult',
  PrefillDocumentsResultList = 'PrefillDocumentsResultList',
  SectionDeclarationResult = 'SectionDeclarationResult',
  ProductBundle = 'ProductBundle',
  ProductBundleDetails = 'ProductBundleDetails',
  ExpenseItemDeleteOverlay = 'ExpenseItemDeleteOverlay',
  SelfSignDocument = 'SelfSignDocument',
  ModelloRedditiSign = 'ModelloRedditiSign',
  CUDocumentResult = 'CUDocumentResult',
  ResultOptimization = 'ResultOptimization',
  SPIDConsentSign = 'SPIDConsentSign',
  TailoredExperienceIntro = 'TailoredExperienceIntro',
  TailoredExperienceFinal = 'TailoredExperienceFinal',
  SVGIconsBrowser = 'SVGIconsBrowser',
  SpidTimeoutNextSteps = 'SpidTimeoutNextSteps',
  SpidTimeout = 'SpidTimeout',
}
