import { useEffect, useRef, useCallback } from 'react';

type UseTimeout = {
  start(): void;
  clear(): void;
};

export const useTimeout = (callback: () => void, delay: number): UseTimeout => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const start = useCallback(() => {
    clear();
    timeoutRef.current = setTimeout(callback, delay);
  }, [callback, clear, delay]);

  useEffect(() => {
    return () => clear();
  }, [clear]);

  return { start, clear };
};
