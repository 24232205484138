import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import Config from 'react-native-config';
import { Prefill, PrefillTypes as PrefillTypesSDK } from '@taxfix/italy-sdk';
import { CountryCodes } from '@taxfix/types';
import { WebViewNavigation } from 'react-native-webview';
import { Linking } from 'react-native';
import { Prefill as PrefillTypes } from '@taxfix/italy-types';

import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';
import {
  getNodesByIds,
  getQuizmasterLight,
  QuizmasterLight,
  withQuizmasterLight,
} from 'src/utils/with-quizmaster-light';
import { flagsQuestionIds, prefillQuestionIds } from 'src/common/constants-it';
import { useNavigation } from 'src/hooks/navigation-hook';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { getQuestionStoreByYearAndCountry } from 'src/stores-legacy/helpers';
import { getStore } from 'src/stores/util';
import { isIOS } from 'src/utils/platform';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { logger } from 'src/taxfix-business-logic/utils/logger';
import { getDefaultTaxYear } from 'src/services/country-and-year';
import { useTimeout } from 'src/hooks/use-timeout';

import { IFrameSPIDLoginComponent } from './iframe-spid-login-component';
import { CWBIMockServerSPIDMessage, SpidMessage } from './constants';
import { SPIDLoginError, SPIDLoginErrorMessages } from './errors';
import { useNoSPIDExperience } from './use-no-spid-experience';

type Props = {
  quizmasterLight: QuizmasterLight;
};

const DOWNLOAD_PREFILL_RESPONSE_STATUS = 'success';

const IFrameSPIDLoginContainer: React.FC<Props> = ({ quizmasterLight }) => {
  const [identityProvider, setIdentityProvider] = useState('');
  const [identifierMethod, setIdentifierMethod] = useState('');

  const { getNavigationActions, safeResetNavigation } = useNavigation();
  const dispatch = useDispatch();

  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const email = useSelector(userAuthSelectors.getEmailAddress) || '';
  const year = useSelector(settingsSelectors.selectedYear) ?? getDefaultTaxYear();
  const requestedDocumentsList = useSelector(firebaseSelectors.getItalyCWBIDocumentsDownloadConfig);
  const SPIDUrlLoadErrorTimeout = useSelector(firebaseSelectors.getItalySPIDUrlErrorTimeout);
  const { messages, messageInterval } = useSelector(firebaseSelectors.getItalyLoadingOverlayConfig);

  const { mockServerEnabled, whitelistedTaxIds } = useSelector(
    firebaseSelectors.getCwbiMockServerConfig,
  );

  const { value: cwbiPrivacyPolicyUrlIdentifier } = useSelector(
    firebaseSelectors.getCwbiPrivacyPolicyUrlIdentifier,
  );

  const {
    startInitialLoadTimeout,
    clearInitialLoadTimeout,
    startProviderSelectionTimeout,
    clearProviderSelectionTimeout,
    startLoginWithProviderTimeout,
    clearLoginWithProviderTimeout,
  } = useNoSPIDExperience();

  const [SPIDLoginUrl, setSPIDLoginUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // populate tax ID answer - at the moment when we render screen, tax ID was not yes available
  // it throws error as descripbed in https://taxfix.atlassian.net/browse/ITA-2970
  const questionStore = getQuestionStoreByYearAndCountry(getStore()?.getState());
  const nodes = getNodesByIds(questionStore, [
    prefillQuestionIds.preseasonTaxId,
    prefillQuestionIds.taxId,
  ]);
  const quizmaster = getQuizmasterLight(questionStore, nodes);
  const taxId = quizmaster[prefillQuestionIds.taxId]?.answer;
  const isMock = mockServerEnabled && whitelistedTaxIds.includes(taxId);

  const { start: startSPIDInitErrorTimeout, clear: clearSPIDInitErrorTimeout } = useTimeout(() => {
    logger.error(new SPIDLoginError(SPIDLoginErrorMessages.spidLoginUrlLoadError));
  }, SPIDUrlLoadErrorTimeout);

  /////// Overlay actions
  const handleOnPressOverlayRetryNowButton = useCallback(
    (callback: () => void) => {
      dispatch(overlayActions.hide());
      callback();
    },
    [dispatch],
  );

  const handleOnPressOverlayRetryLaterButton = useCallback(() => {
    dispatch(overlayActions.hide());
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);
  }, [dispatch, getNavigationActions, safeResetNavigation]);

  const showOverlayError = useCallback(
    (handleOnPressRetryNowButton: () => void) => {
      dispatch(
        overlayActions.show('SPIDError', {
          handleOnPressRetryNowButton,
          handleOnPressRetryLaterButton: handleOnPressOverlayRetryLaterButton,
        }),
      );
    },
    [dispatch, handleOnPressOverlayRetryLaterButton],
  );

  //////// Request URL for SPID login
  const getIframeUrl = useCallback(async () => {
    setIsLoading(true);

    try {
      const folderRequest = await Prefill.tokenizeCredentialsRequestCreate(
        Config.API_BASE_URL,
        accessToken,
        {
          taxId,
          isMock: isMock ? 'true' : 'false',
        },
      );
      setSPIDLoginUrl(folderRequest.tokenizationRequestUrl);
    } catch (error) {
      logger.error(new SPIDLoginError(SPIDLoginErrorMessages.tokenizeCredentialsRequestError), {
        error,
      });
      showOverlayError(() => handleOnPressOverlayRetryNowButton(getIframeUrl));
    } finally {
      setIsLoading(false);
    }
  }, [accessToken, showOverlayError, handleOnPressOverlayRetryNowButton, isMock, taxId, dispatch]);

  useEffect(() => {
    getIframeUrl();
  }, [accessToken, email, getIframeUrl, taxId, year]);

  useEffect(
    () => {
      if (SPIDLoginUrl && !isMock) {
        startSPIDInitErrorTimeout();
        startInitialLoadTimeout();
      }
      return () => {
        clearSPIDInitErrorTimeout();
        clearInitialLoadTimeout();
      };
    },
    // ITA-4335: Adding the timeout dependencies to the useEffect hook
    // causes the pop up to be triggered later than expected
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isMock, SPIDLoginUrl],
  );

  //////// Download prefill documents
  const onDownloadSuccess = useCallback(
    (
      estimatedRefund: string,
      taxScenario: PrefillTypes.PrefillResultScenarios,
      optimizations?: PrefillTypesSDK.DownloadPrefillDocuments.Optimization[],
      reasonWhyRefundIsZero?: string,
    ) => {
      quizmasterLight[flagsQuestionIds.prefillDocumentsDownloaded].saveAnswer(true);
      quizmasterLight[flagsQuestionIds.prefilledCreditResult].saveAnswer(estimatedRefund);
      quizmasterLight[flagsQuestionIds.taxScenario].saveAnswer(taxScenario);

      if (optimizations?.length) {
        quizmasterLight[flagsQuestionIds.taxOptimizations].saveAnswer(
          JSON.stringify(optimizations),
        );
      }

      if (reasonWhyRefundIsZero) {
        quizmasterLight[flagsQuestionIds.reasonWhyRefundIsZero].saveAnswer(reasonWhyRefundIsZero);
      }

      dispatch(overlayActions.hide());
      safeResetNavigation([
        getNavigationActions().toDashboard('screen'),
        getNavigationActions().toPrefillDocumentsResult('screen'),
      ]);
    },
    [getNavigationActions, quizmasterLight, safeResetNavigation, dispatch],
  );

  const downloadPrefillDocuments = useCallback(async () => {
    try {
      const response = await Prefill.downloadPrefillDocuments(Config.API_BASE_URL, accessToken, {
        documentMap: requestedDocumentsList,
        countryCode: CountryCodes.IT,
        taxId,
        email,
        taxYear: year,
        isMock,
      });

      if (response.status === DOWNLOAD_PREFILL_RESPONSE_STATUS) {
        const { estimatedRefund, taxScenario, optimizations, reasonWhyRefundIsZero } = response;

        onDownloadSuccess(
          String(estimatedRefund),
          taxScenario,
          optimizations,
          reasonWhyRefundIsZero,
        );
      }
    } catch (error) {
      logger.error(new SPIDLoginError(SPIDLoginErrorMessages.downloadPrefillDocumentsError), {
        error,
      });
      dispatch(overlayActions.hide());
      showOverlayError(() => handleOnPressOverlayRetryNowButton(getIframeUrl));
    }
  }, [
    accessToken,
    onDownloadSuccess,
    showOverlayError,
    handleOnPressOverlayRetryNowButton,
    getIframeUrl,
    email,
    isMock,
    requestedDocumentsList,
    taxId,
    year,
    dispatch,
  ]);

  //////// SPID login Webview's events handlers
  const onProviderSelection = useCallback(
    (event: string) => {
      // e.g. tokenizeCredentialRequestStep2A/submitForm/SPID/POSTE
      const eventParams = event.split('/');
      const identifierMethod = eventParams[2];
      const provider = eventParams[3];

      setIdentifierMethod(identifierMethod);
      setIdentityProvider(provider);

      Analytics.log(AnalyticsEvent.identityProviderSelected, {
        provider,
        identifierMethod,
      });

      startProviderSelectionTimeout();
    },
    [startProviderSelectionTimeout],
  );

  const logSectionShown = (sectionName: string) => {
    Analytics.log(AnalyticsEvent.sectionShown, {
      sectionName,
    });
  };

  const onError = useCallback(() => {
    logSectionShown('cwbiInternalError');
    showOverlayError(() => handleOnPressOverlayRetryNowButton(getIframeUrl));
  }, [getIframeUrl, handleOnPressOverlayRetryNowButton, showOverlayError]);

  const onSpidCredentialsErrorShown = useCallback((isValidationFailed: boolean) => {
    if (isValidationFailed) {
      Analytics.log(AnalyticsEvent.spidCredentialsValidationFailed);
    } else {
      Analytics.log(AnalyticsEvent.spidCredentialsErrorShown);
    }
  }, []);

  const onShouldStartLoadWithRequest = useCallback(
    (event: WebViewNavigation) => {
      // a workaround for iOS to open the privacy policy of CWBI in the mobile browser
      // iOS requires opening the link in a new page explicitly,
      // otherwise it will open the link in the same iFrame
      if (isIOS && event.url.toLowerCase().includes(cwbiPrivacyPolicyUrlIdentifier)) {
        Linking.openURL(event.url);
        return false;
      }

      return true;
    },
    [cwbiPrivacyPolicyUrlIdentifier],
  );

  const onSpidFlowFinished = useCallback(
    (event: string) => {
      const status = event.split('/')[2];
      Analytics.log(AnalyticsEvent.spidFlowStatus, {
        status,
      });
      dispatch(
        overlayActions.show('LoadingOverlayWithLogo', {
          messages,
          messageInterval,
        }),
      );
      downloadPrefillDocuments();
    },
    [downloadPrefillDocuments, dispatch, messageInterval, messages],
  );

  const onMessage = useCallback(
    (event: { nativeEvent: { data: any } }): void => {
      if (typeof event.nativeEvent.data !== 'string') return;

      const data: string = event.nativeEvent.data;

      if (data.startsWith(SpidMessage.identityProviderSelected)) {
        onProviderSelection(data);
        return;
      }

      switch (data) {
        case SpidMessage.spidURLLoaded:
          clearSPIDInitErrorTimeout();
          clearInitialLoadTimeout();
          break;
        case SpidMessage.providerSelectionScreenShown:
          logSectionShown('identityProviderScreen');
          break;
        case SpidMessage.providerScreenShown:
          clearProviderSelectionTimeout();
          logSectionShown(`${identifierMethod}/${identityProvider}LandingScreen`);
          break;
        case SpidMessage.requestLoginWithProvider:
          startLoginWithProviderTimeout();
          break;
        case SpidMessage.errorScreenShown:
          onError();
          break;
        case SpidMessage.credentialsValidationFailed:
          onSpidCredentialsErrorShown(true);
          break;
        case SpidMessage.credentialsErrorShown:
          onSpidCredentialsErrorShown(false);
          break;
        case SpidMessage.spidFlowAuthorized:
        case SpidMessage.spidFlowRejected:
        case SpidMessage.spidFlowExpired:
        case SpidMessage.spidFlowError:
        case CWBIMockServerSPIDMessage.authorized:
          clearLoginWithProviderTimeout();
          onSpidFlowFinished(data);
          break;
      }
    },
    [
      onError,
      onSpidCredentialsErrorShown,
      onSpidFlowFinished,
      onProviderSelection,
      identifierMethod,
      identityProvider,
      clearSPIDInitErrorTimeout,
      clearInitialLoadTimeout,
      clearProviderSelectionTimeout,
      startLoginWithProviderTimeout,
      clearLoginWithProviderTimeout,
    ],
  );

  return (
    <IFrameSPIDLoginComponent
      SPIDLoginUrl={SPIDLoginUrl}
      onError={onError}
      onMessage={onMessage}
      onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
      isLoading={isLoading}
    />
  );
};

export const IFrameSPIDLogin = compose<React.ComponentType>(
  withQuizmasterLight([
    flagsQuestionIds.prefillDocumentsDownloaded,
    flagsQuestionIds.productBundleSelection,
    flagsQuestionIds.prefilledCreditResult,
    flagsQuestionIds.reasonWhyRefundIsZero,
    flagsQuestionIds.taxScenario,
    flagsQuestionIds.taxOptimizations,
  ]),
)(IFrameSPIDLoginContainer);
