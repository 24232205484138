import { useBreakpointValue } from 'native-base';
import React from 'react';

import { Button, Collapsable, Container, Text } from 'src/taxfix-components/src';

import { Image } from '../../general/Image';

import { TaxYearCardProps } from './types';

/**
 * The card give information about the status of the submission
 */

export const TaxYearCard: React.FC<TaxYearCardProps> = ({
  onCtaPress,
  title,
  titleColor,
  buttonText,
  sourceImageName,
  bgColor,
  buttonVariant,
  collapsableTitle,
  collapsableSubtitle,
  subtitle,
  mainButtonTestId,
  ...otherProps
}) => {
  const paddingTop = useBreakpointValue({ base: '30px', md: '56px' });
  const collapsableHeight = useBreakpointValue({ base: '70px', md: '80px' });
  const paddingXChild = useBreakpointValue({ base: '26px' });
  const maxWidthButton = useBreakpointValue({ md: '385px' });
  const photoHeight = useBreakpointValue({ base: '97px', md: '129px' });
  const photoWidth = useBreakpointValue({ base: '199px', md: '265px' });
  const marginBottomPhoto = useBreakpointValue({ base: '32px', md: '44px' });
  const marginBottomContent = useBreakpointValue({ base: '30px', md: '56px' });
  const marginBottomButton = useBreakpointValue({ base: '32px', md: '76px' });
  const heightButton = useBreakpointValue({ base: 'm', md: 'l' });
  const textFont = useBreakpointValue({ base: 'titleSBold', md: 'titleMMedium' });
  return (
    <Collapsable
      title={collapsableTitle}
      minHeight={collapsableHeight}
      titleFont="titleXSBold"
      subtitle={collapsableSubtitle}
      backgroundColor="greytones.white"
      startOpen
      maxWidth="580px"
      {...otherProps}
    >
      <Container
        alignItems="center"
        pt={paddingTop}
        paddingX={paddingXChild}
        justifyContent="center"
        borderRadius={15}
        backgroundColor={bgColor ?? 'greytones.white'}
      >
        <Container maxW="400px" marginBottom={marginBottomContent} alignItems="center">
          <Container marginBottom={marginBottomPhoto}>
            <Image name={sourceImageName} width={photoWidth} height={photoHeight} />
          </Container>
          <Text
            variant={textFont}
            value={title}
            textAlign="center"
            color={titleColor ?? 'greytones.textDefault'}
          />
          {subtitle && (
            <Text
              variant={textFont}
              textAlign="center"
              color={titleColor ?? 'greytones.textDefault'}
              value={subtitle}
            />
          )}
        </Container>
        {buttonText && (
          <Button
            onPress={onCtaPress}
            variant={buttonVariant ?? 'primary'}
            title={buttonText}
            verticalPaddingSize={heightButton}
            marginBottom={marginBottomButton}
            testID={mainButtonTestId}
            maxW={maxWidthButton}
          />
        )}
      </Container>
    </Collapsable>
  );
};
