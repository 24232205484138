import React, { useReducer, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';

import { Provider, selectors as userAuthSelectors } from '../../stores/modules/user-auth';
import { BodySecondaryText, Box, DisplayTitleText, ScrollView } from '../../components/core';
import { Footer } from '../../components/footer';
import { isEmailLike, passwordLength } from '../../utils/credentials';
import { useInputScrollHelper } from '../../hooks/useInputScrollHelper';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import {
  actions,
  ErrorKeys,
  initialState,
  reducer as emailChangeStateReducer,
  states,
} from '../../components/auth/RequestEmailChangeReducer';
import EmailAddress from '../../components/auth/EmailAddress';
import PinInput from '../../components/auth/PinInput';

type Props = {
  currentEmail: string;
  onConfirmChange: (arg0: { email: string; password?: string }) => Promise<any>;
};
const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  padding: {
    paddingHorizontal: 20,
  },
  titleText: {
    paddingTop: 20,
    paddingBottom: 15,
  },
  leadContainer: {
    paddingBottom: 30,
  },
  pinLeadText: {
    marginBottom: 16,
  },
});
export const RequestEmailChange = ({ currentEmail, onConfirmChange }: Props) => {
  const [emailVal, setEmail] = useState('');
  const [pinVal, setPin] = useState('');
  const [state, dispatch] = useReducer(emailChangeStateReducer, initialState);
  const { registerScrollViewRef, registerContainer, registerLabel, withScrollToLabel } =
    useInputScrollHelper();
  const { status, errorKey } = state;
  const isLoading = status === states.Loading;
  const isError = status === states.Error;
  const emailHasError = isError && errorKey !== ErrorKeys.Unauthorized;
  const pinHasError = isError && errorKey === ErrorKeys.Unauthorized;
  const provider = useSelector(userAuthSelectors.getProvider);
  const isSSOLogin = provider === Provider.apple || provider === Provider.google;
  const confirmDisabled = isError || emailVal === '' || (pinVal === '' && !isSSOLogin);

  const handleConfirm = () => {
    const { email: newEmail, pwd } = state;

    dispatch(actions.submit());
    Analytics.log(AnalyticsEvent.requestEmailChangeButtonPressed);

    const payload = !isSSOLogin ? { password: pwd } : {};

    onConfirmChange({
      email: newEmail,
      ...payload,
    })
      .then(() => {
        dispatch(actions.submitSuccess());
      })
      .catch((err) => {
        const key = err?.response?.data?.code as keyof typeof ErrorKeys;
        const errorMessage = ErrorKeys[key] ?? ErrorKeys.Unknown;
        dispatch(
          actions.submitFail({
            errorKey: errorMessage,
          }),
        );
      });
  };

  const editField = (field: 'email' | 'pwd', value: string) => {
    dispatch(
      actions.startEditing({
        field,
        value,
      }),
    );
  };

  const handleEmailChange = (emailAddress: string) => {
    setEmail(emailAddress);
    editField('email', emailAddress);
  };

  const handleEmailBlur = () => {
    let actionPayload = null;

    if (!isEmailLike(emailVal)) {
      actionPayload = {
        errorKey: ErrorKeys.InvalidArgument,
      };
    }

    if (emailVal === currentEmail) {
      actionPayload = {
        errorKey: ErrorKeys.Conflict,
      };
    }

    if (actionPayload) {
      dispatch(actions.markInvalid(actionPayload));
    }
  };

  const handlePinChange = (enteredPin: string) => {
    setPin(enteredPin);
    editField('pwd', enteredPin);
  };

  const handlePinFocus = () => {};

  const handlePinBlur = () => {
    if (pinVal.length < passwordLength) {
      dispatch(
        actions.markInvalid({
          errorKey: ErrorKeys.Unauthorized,
        }),
      );
    }
  };

  return (
    <Box flex>
      <ScrollView // @ts-ignore
        ref={registerScrollViewRef}
        onLayout={registerContainer}
        contentContainerStyle={[styles.container]}
        keyboardShouldPersistTaps="handled"
      >
        <Box flex style={[styles.padding]} right={2} left={2}>
          <DisplayTitleText id="account.request-email-change.title" style={[styles.titleText]} />
          <View style={[styles.leadContainer]}>
            <BodySecondaryText id="account.request-email-change.lead" />
          </View>
          <EmailAddress
            value={emailVal}
            autoFocus
            onChange={handleEmailChange}
            onBlur={handleEmailBlur}
            hasError={emailHasError}
            errorKey={emailHasError ? errorKey : null}
            titleKey="account.request-email-change.email-input-label"
            testId="account.request-email-change.email"
          />
          {isSSOLogin ? null : (
            <>
              <BodySecondaryText
                id="account.request-email-change.pin-confirm"
                style={[styles.pinLeadText]}
              />
              <Box onLayout={registerLabel('PIN')} bottom={3}>
                <PinInput
                  type="sign-up"
                  value={pinVal}
                  onChange={handlePinChange}
                  onFocus={withScrollToLabel(handlePinFocus, 'PIN')}
                  onBlur={handlePinBlur}
                  hasError={pinHasError}
                  errorKey={pinHasError ? errorKey : null}
                  labelKey="account.request-email-change.pin-input-label"
                  testId="account.request-email-change.pin"
                />
              </Box>
            </>
          )}
        </Box>
      </ScrollView>
      <View>
        <Footer
          isLoading={isLoading}
          isDisabled={confirmDisabled}
          onPress={handleConfirm}
          translationKey="account.request-email-change.confirm"
          testId="account.request-email-change.confirm"
        />
      </View>
    </Box>
  );
};
