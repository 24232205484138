import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { compose } from 'redux';

import { SubmissionConsent, withSubmissionConsent } from 'src/hocs/with-submission-consent';
import { useNavigation } from 'src/hooks/navigation-hook';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { ScreenName } from 'src/types/screen-name';
import { useCWBIDocumentsConfig } from 'src/hooks/use-cwbi-documents-config';

import { actions as overlayActions } from '../../stores/modules/overlay';

import { SPIDConsentSign } from './spid-consent-sign';

type Props = SubmissionConsent;

const UndecoratedSPIDConsentSign: React.FC<Props> = ({ createSpidConsentDocument }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSignError, setIsSignError] = useState(false);

  const dispatch = useDispatch();

  const { getNavigationActions, safeResetNavigation, navigationActions, navigation } =
    useNavigation();

  const { previewRequestedDocsList } = useCWBIDocumentsConfig();

  const handleOpenDocList = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.SPIDConsentSign,
      buttonName: 'SPIDDocumentsInfo',
    });
    dispatch(overlayActions.show('SPIDConsentDocumentsInfo'));
  };

  const handleShowSPIDConsentDocument = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.SPIDConsentSign,
      buttonName: 'SPIDPrivacyPolicy',
    });
    navigationActions.toSPIDConsentScreen('modal');
  };

  const handleSignSPIDConsent = async () => {
    if (isSignError) setIsSignError(false);
    setIsLoading(true);

    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: ScreenName.SPIDConsentSign,
      buttonName: 'SPIDConsentAccepted',
    });

    try {
      await createSpidConsentDocument();

      safeResetNavigation([
        getNavigationActions().toDashboard('screen'),
        getNavigationActions().toiFrameSPIDLoginScreen('screen'),
      ]);
    } catch (error) {
      setIsLoading(false);
      setIsSignError(true);
    }
  };

  const handleBackButtonPress = () => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      navigationActions.toDashboard('screen');
    }
  };

  return (
    <SPIDConsentSign
      onOpenDocList={handleOpenDocList}
      onShowSPIDConsentDocument={handleShowSPIDConsentDocument}
      onSignSPIDConsent={handleSignSPIDConsent}
      onPressBackButton={handleBackButtonPress}
      isSignError={isSignError}
      isLoading={isLoading}
      previewRequestedDocsList={previewRequestedDocsList}
    />
  );
};

export const SPIDConsentSignContainer = compose<React.ComponentType>(withSubmissionConsent)(
  UndecoratedSPIDConsentSign,
);
