import { flagsQuestionIds } from 'src/common/constants-it';
import { getNavigationActions } from 'src/routes/config-util';
import { getAnswer } from 'src/stores-legacy/helpers';
import {
  StatusMiddleware,
  StatusSkipFunction,
  StatusStepRedirect,
} from 'src/stores/modules/status';

export const checkPrefillDocumentsDownloaded: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  // check if the user has downloaded prefill documents from CWBI and
  // if user chose to continue without SPID
  const hasPrefillDocuments = getAnswer(getState(), flagsQuestionIds.prefillDocumentsDownloaded);

  if (hasPrefillDocuments) {
    next();
  } else {
    dispatch(breakAction());
  }
};

export const redirectSPIDLogin: StatusStepRedirect = (state, dispatch) => (navigation) => {
  navigation.reset({
    index: 1,
    actions: [
      getNavigationActions().toDashboard('screen'),
      getNavigationActions().toiFrameSPIDLoginScreen('screen'),
    ],
  });
};

export const checkPreseasonSPID: StatusMiddleware = (
  dispatch,
  getState,
  config,
  breakAction,
  failureAction,
  next,
) => {
  // check if the user has CU document
  const hasDocument = getAnswer(getState(), flagsQuestionIds.preseasonCUDocument);
  if (hasDocument) {
    next();
  } else {
    dispatch(breakAction());
  }
};

export const redirectPreseasonSPID: StatusStepRedirect = (state, dispatch) => (navigation) => {
  navigation.reset({
    index: 1,
    actions: [
      getNavigationActions().toDashboard('screen'),
      getNavigationActions().toiFrameSPIDLoginScreen('screen'),
    ],
  });
};

export const skipIfNoSPIDFlow: StatusSkipFunction = async (getState) => {
  const isSPIDTimeoutFlow = getAnswer(getState(), flagsQuestionIds.SPIDTimeoutFlow);
  return isSPIDTimeoutFlow ? true : false;
};
