import { IBoxProps } from 'native-base';

import { Variants as TextVariants } from '../../../theme/it/fonts/fontTypes';
import { StepIconType, NumberStylesProps } from '../../general/Step/types';

type StepperDirection = 'horizontal' | 'vertical';

export enum STEP_STATUS {
  COMPLETED = 'completed',
  IN_PROGRESS = 'inProgress',
  NOT_STARTED = 'notStarted',
}

export type Step = {
  titleFont?: TextVariants;
  titleText?: string;
  labelFont?: TextVariants;
  labelText?: string;
  stepIcon?: StepIconType;
  active?: boolean;
  status?: STEP_STATUS;
};

export type StepperProps = {
  steps: Array<Step>;
  showConnector?: boolean;
  testID?: string;
  numberStyles?: NumberStylesProps;
  borderStyle?: string;
  borderColor?: string;
  direction?: StepperDirection;
};

export type Props = StepperProps & IBoxProps;

export type ConnectorWrapperProps = {
  showConnector: boolean;
  children: React.ReactNode;
  borderStyle?: string;
  borderColor?: string;
  direction?: StepperDirection;
  stepStatus?: STEP_STATUS;
};
