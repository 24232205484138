import * as React from 'react';
import { Stack, useBreakpointValue } from 'native-base';
import { ScrollView } from 'react-native';

import { useLargeScreenQuery } from 'src/components/core';
import { Container, Image, NavButton, Text } from 'src/taxfix-components/src';
import { isWeb } from 'src/utils/platform';

import { useItalyIntl } from '../../_italy/_hooks';

const TOTAL_BULLET_POINTS = 11;
const INSTANT_ONLY_BULLET_KEYS = [2, 10];
const BULLET_POINTS_KEYS = Array.from(
  { length: TOTAL_BULLET_POINTS },
  (_, i) => `it.product-bundle-details-screen.bullet.${i + 1}`,
);

const getBoldText = (text: string) => <Text fontWeight="bold" value={text} />;

const Check = () => <Image name="faCheck" resizeMode="contain" width="16px" height="16px" />;
const Null = () => <Image name="faNull" resizeMode="contain" width="16px" height="16px" />;

const BulletPoint: React.FC<{ item: number; text: string }> = ({ item, text }) => {
  return (
    <Container flexDirection="row" alignItems="center">
      <Container flexGrow={2} flexBasis="55%" mr="16px">
        <Text value={text} variant="bodySBook" color="rgba(15,12,15,0.8)" />
      </Container>
      <Container width="65px" alignItems="center">
        {INSTANT_ONLY_BULLET_KEYS.includes(item) ? <Check /> : <Null />}
      </Container>
      <Container width="65px" alignItems="center">
        <Check />
      </Container>
    </Container>
  );
};

type ProductBundleComponentProps = {
  onBackButtonPress?(): void;
};

export const ProductBundleDetailsComponent: React.FC<ProductBundleComponentProps> = ({
  onBackButtonPress,
}) => {
  const { getTranslationText } = useItalyIntl();
  const isLargeScreen = useLargeScreenQuery();

  const titleVariant = useBreakpointValue({
    base: 'titleSBold',
    md: 'titleMBold',
  });
  const pageTitleMarginTop = useBreakpointValue({ base: isWeb ? '24px' : 0, md: '48px' });
  const pageTitleMarginBottom = useBreakpointValue({ base: '20px', md: '12px' });
  const pageContainerWidth = useBreakpointValue({ md: '580px' });
  const pageContainerHorizontalMargin = useBreakpointValue({ md: 'auto' });
  const contentHorizontalMargin = useBreakpointValue({ base: '24px', lg: 'auto' });
  const contentMarginBottom = useBreakpointValue({ base: '28px', lg: '24px' });
  const contentDescriptionVariant = useBreakpointValue({ base: 'bodyXSBook', md: 'bodySBook' });

  const comparisonHeaderProps = {
    variant: 'overlineS',
    fontWeight: 'medium',
    color: 'rgba(15,12,15,0.8)',
  };

  return (
    <>
      {isLargeScreen && onBackButtonPress && (
        <Container position="absolute" top={0} zIndex={1}>
          <NavButton
            onPress={onBackButtonPress}
            text={getTranslationText('it.product-bundle-details-screen.back-button.text')}
          />
        </Container>
      )}
      <ScrollView>
        <Container mx={pageContainerHorizontalMargin} width={pageContainerWidth}>
          <Container mx={contentHorizontalMargin} mb={contentMarginBottom}>
            <Text
              mt={pageTitleMarginTop}
              mb={pageTitleMarginBottom}
              variant={titleVariant}
              value={getTranslationText('it.product-bundle-details-screen.title')}
            />
            <Text
              variant={contentDescriptionVariant}
              value={getTranslationText('it.product-bundle-details-screen.content-description', {
                bold: getBoldText,
              })}
            />
          </Container>

          <Stack space={4} height="full" p="24px" backgroundColor="greytones.backgroundLight">
            <Container flexDirection="row" ml="auto">
              <Text
                {...comparisonHeaderProps}
                value={getTranslationText('it.product-bundle-details-screen.bundle-name.instant')}
                mr="10px"
              />
              <Text
                {...comparisonHeaderProps}
                value={getTranslationText('it.product-bundle-details-screen.bundle-name.guided')}
              />
            </Container>
            {BULLET_POINTS_KEYS.map((bulletKey, index) => (
              <BulletPoint key={index} item={index + 1} text={getTranslationText(bulletKey)} />
            ))}
          </Stack>
        </Container>
      </ScrollView>
    </>
  );
};
