import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { useBreakpointValue } from 'native-base';

import { Button, Container, Image, Text } from 'src/taxfix-components/src';
import Overlay from 'src/components/overlay';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';

export type Props = {
  handleOnPressRetryNowButton: () => void;
  handleOnPressRetryLaterButton: () => void;
};

const styles = StyleSheet.create({
  overlay: {
    justifyContent: 'center',
    alignSelf: 'center',
  },
});

const defaultTranlationKeys = {
  title: 'it.spid-error-overlay.title',
  description: 'it.spid-error-overlay.description',
  primaryButton: 'it.spid-error-overlay.button-primary',
  secondaryButton: 'it.spid-error-overlay.button-secondary',
};

export const SPIDError: React.FC<Props> = ({
  handleOnPressRetryLaterButton,
  handleOnPressRetryNowButton,
}) => {
  const { getTranslationText } = useItalyIntl();
  const isLargeScreen = useBreakpointValue({ base: false, md: true });

  const getBoldText = (text: string) => (
    <Text variant="bodySBook" fontWeight="bold" value={text} color="greytones.textLight" />
  );

  useEffect(() => {
    Analytics.log(AnalyticsEvent.popupShown, {
      popupName: 'SPIDError',
    });
  }, []);

  const handleOnPressRetryLater = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: 'SPIDError',
      buttonName: 'spidRetryLater',
    });

    handleOnPressRetryLaterButton && handleOnPressRetryLaterButton();
  };

  const handleOnPressRetryNow = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      screenName: 'SPIDError',
      buttonName: 'spidRetryNow',
    });
    handleOnPressRetryNowButton();
  };

  return (
    <Overlay disableBackgroundTouch style={styles.overlay} appearFromTop>
      {() => (
        <Container
          borderRadius={1}
          maxWidth="600px"
          maxHeight="600px"
          backgroundColor="white"
          p={isLargeScreen ? 5 : 2}
          m={1}
        >
          <Image name="errorBarrier" alignSelf="center" width={100} />

          <Text
            value={getTranslationText(defaultTranlationKeys.title)}
            variant="titleMBold"
            textAlign="center"
            color="greytones.textDefault"
            mb={2}
            mx={1}
          />

          <Text
            value={getTranslationText(defaultTranlationKeys.description, {
              bold: getBoldText,
            })}
            variant="bodyMBook"
            textAlign="center"
            color="greytones.textLight"
          />

          <Button
            variant="primary"
            title={getTranslationText(defaultTranlationKeys.primaryButton)}
            onPress={handleOnPressRetryNow}
            width={isLargeScreen ? 'l' : 'xl'}
            mt={[1, 1, 4]}
          />

          <Button
            variant="tertiary"
            title={getTranslationText(defaultTranlationKeys.secondaryButton)}
            onPress={handleOnPressRetryLater}
            width={isLargeScreen ? 'l' : 'xl'}
            mt={1}
          />
        </Container>
      )}
    </Overlay>
  );
};
