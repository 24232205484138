import React from 'react';
import { StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
import { CountryCodes } from '@taxfix/types';

import { Box, theme, Image, IconButton } from '../components/core';
import Overlay from '../components/overlay';
import { selectors as settingsSelectors } from '../stores/modules/settings';
import { FooterLinksItaly, MenuItem } from '../components/sidebar';
import {
  safeResetNavigation,
  getNavigationActions,
  getBoundNavigationsActions,
} from '../routes/config-util';

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: theme.color.lightFill,
    width: 'calc(100% - 42px)',
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  header: {
    justifyContent: 'space-between',
    margin: 20,
  },
  closeButton: {
    padding: 20,
  },
  menu: {
    marginHorizontal: 25,
  },
  footer: {
    marginVertical: 45,
    marginHorizontal: 28,
  },
});
type Props = {
  onClose?: () => any;
};
export const MenuOverlay = ({ onClose: onCloseComplete }: Props) => {
  const selectedCountry = useSelector(settingsSelectors.selectedCountry);

  const handleAccountPress = (onClose: () => any) => {
    getBoundNavigationsActions().toAccount('screen');
    onClose();
  };

  const handleHomePress = (onClose: () => any) => {
    safeResetNavigation([getNavigationActions().toUniversalStatus('screen')]);
    onClose();
  };

  return (
    <Overlay onCloseComplete={onCloseComplete} style={styles.overlay} position="left">
      {({ onClose }: any) => (
        <Box style={[styles.container]}>
          {selectedCountry === CountryCodes.IT && (
            <>
              <Box>
                <Box flex direction="row" style={styles.header}>
                  <Image name="logo-svg.taxfix" testId="test.button.home" />
                  <Box style={styles.closeButton}>
                    <IconButton
                      iconKey="cross"
                      onPress={onClose}
                      tintColor={theme.color.primaryText}
                    />
                  </Box>
                </Box>
                <Box style={styles.menu}>
                  <MenuItem
                    translationId="web.header.account"
                    iconName="interface-icons-svg.icon-account-circle-small"
                    onPress={() => handleAccountPress(onClose)}
                  />
                  <MenuItem
                    translationId="web.menu.home"
                    iconName="interface-icons-svg.icon-home"
                    onPress={() => handleHomePress(onClose)}
                  />
                </Box>
              </Box>
              <Box style={[styles.footer]}>
                <FooterLinksItaly />
              </Box>
            </>
          )}
        </Box>
      )}
    </Overlay>
  );
};
