import { Prefill } from '@taxfix/italy-types';

import { OptimizationVersion } from './result-optimization/result-optimization-types';
import { ScreenState } from './types';

export const FORCED_ADVANCED_BUNDLE = 'forcedAdvanced';

const positiveNoOptimizationCommonContent: ScreenState = {
  screenTitle: 'prefill-result-screen.positiveNoOptimization.title-experiment',
  cardBgColor: 'secondaryFeedback.greenLight',
  cardBgColorSmallScreen: 'secondaryCategory.greenBackground',
  linkTranslationKey: 'prefill-result-screen.result-card.positiveNoOptimization.link-experiment',
  taxExpertCardHeaderText:
    'prefill-result-screen.tax-expert-card.positiveNoOptimization.header-experiment',
  cardDescription:
    'prefill-result-screen.result-card.positiveNoOptimization.description-experiment',
  taxExpertCardTitle:
    'prefill-result-screen.tax-expert-card.positiveNoOptimization.title-experiment',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.positiveNoOptimization.description-experiment',
  buttonTranslationKey: 'prefill-result-screen.positiveNoOptimization.button-experiment',
  showConfetti: true,
  showEstimatedResult: true,
};

const positiveOptimizationPossibleCommonContent: ScreenState = {
  screenTitle: 'prefill-result-screen.positiveOptimizationPossible.title-experiment',
  cardBgColor: 'secondaryFeedback.greenLight',
  cardBgColorSmallScreen: 'secondaryCategory.greenBackground',
  taxExpertCardHeaderText:
    'prefill-result-screen.tax-expert-card.positiveOptimizationPossible.header-experiment',
  cardDescription:
    'prefill-result-screen.result-card.positiveOptimizationPossible.description-experiment',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.positiveOptimizationPossible.description-experiment',
  taxExpertCardLinkText:
    'prefill-result-screen.tax-expert-card.positiveOptimizationPossible.taxExpertCardLinkText-experiment',
  buttonTranslationKey: 'prefill-result-screen.positiveOptimizationPossible.button-experiment',
  resultOptimizationScreenVersion: OptimizationVersion.OptimizationPossible,
  showConfetti: true,
  showEstimatedResult: true,
};

const negativeNoOptimizationMandatoryNoCedolareCommonContent: ScreenState = {
  screenTitle: 'prefill-result-screen.negativeNoOptimizationMandatoryNoCedolare.title-experiment',
  cardBgColor: 'secondaryCategory.skyHalf',
  cardBgColorSmallScreen: 'secondaryCategory.blueBackground',
  taxExpertCardHeaderText:
    'prefill-result-screen.tax-expert-card.negativeNoOptimizationMandatoryNoCedolare.header-experiment',
  cardDescription:
    'prefill-result-screen.result-card.negativeNoOptimizationMandatoryNoCedolare.description-experiment',
  taxExpertCardTitle:
    'prefill-result-screen.tax-expert-card.negativeNoOptimizationMandatoryNoCedolare.title-experiment',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.negativeNoOptimizationMandatoryNoCedolare.description-experiment',
  buttonTranslationKey:
    'prefill-result-screen.negativeNoOptimizationMandatoryNoCedolare.button-experiment',
  taxExpertCardLinkText:
    'prefill-result-screen.negativeNoOptimizationMandatoryNoCedolare.taxExpertCardLinkText-experiment',
  taxExpertCardWarning:
    'prefill-result-screen.negativeNoOptimizationMandatoryNoCedolare.taxExpertWarningText-experiment',
  taxExpertAverageRefundChipText:
    'prefill-result-screen.tax-expert-card.negativeNoOptimizationMandatoryNoCedolare.averageRefundChip-experiment',
  resultOptimizationScreenVersion: OptimizationVersion.NoOptimization,
  showEstimatedResult: true,
};

const negativeOptimizationPossibleMandatoryNoCedolareCommonContent: ScreenState = {
  screenTitle:
    'prefill-result-screen.negativeOptimizationPossibleMandatoryNoCedolare.title-experiment',
  cardBgColor: 'secondaryCategory.skyHalf',
  cardBgColorSmallScreen: 'secondaryCategory.blueBackground',
  taxExpertCardHeaderText:
    'prefill-result-screen.result-card.negativeOptimizationPossibleMandatoryNoCedolare.header-experiment',
  cardDescription:
    'prefill-result-screen.result-card.negativeOptimizationPossibleMandatoryNoCedolare.description-experiment',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.negativeOptimizationPossibleMandatoryNoCedolare.description-experiment',
  buttonTranslationKey:
    'prefill-result-screen.negativeOptimizationPossibleMandatoryNoCedolare.button-experiment',
  taxExpertCardLinkText:
    'prefill-result-screen.negativeOptimizationPossibleMandatoryNoCedolare.taxExpertCardLinkText-experiment',
  taxExpertCardWarning:
    'prefill-result-screen.negativeOptimizationPossibleMandatoryNoCedolare.taxExpertWarningText-experiment',
  resultOptimizationScreenVersion: OptimizationVersion.OptimizationPossible,
  showEstimatedResult: true,
};

const resultZeroNoOptimizationContent: ScreenState = {
  screenTitle: 'prefill-result-screen.resultZeroNoOptimization.title-experiment',
  cardBgColor: 'secondaryFeedback.greenLight',
  cardBgColorSmallScreen: 'secondaryCategory.greenBackground',
  cardDescription:
    'prefill-result-screen.result-card.resultZeroNoOptimization.description-experiment',
  taxExpertCardHeaderText:
    'prefill-result-screen.tax-expert-card.resultZeroNoOptimization.header-experiment',
  linkTranslationKey: 'prefill-result-screen.result-card.resultZeroNoOptimization.link-experiment',
  taxExpertCardTitle:
    'prefill-result-screen.tax-expert-card.resultZeroNoOptimization.title-experiment',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.resultZeroNoOptimization.description-experiment',
  buttonTranslationKey: 'prefill-result-screen.resultZeroNoOptimization.button-experiment',
  showConfetti: true,
  showEstimatedResult: true,
};

const resultZeroOptimizationPossibleContent: ScreenState = {
  screenTitle: 'prefill-result-screen.resultZeroOptimizationPossible.title-experiment',
  cardBgColor: 'secondaryFeedback.greenLight',
  cardBgColorSmallScreen: 'secondaryCategory.greenBackground',
  cardDescription:
    'prefill-result-screen.result-card.resultZeroOptimizationPossible.description-experiment',
  taxExpertCardHeaderText:
    'prefill-result-screen.tax-expert-card.resultZeroOptimizationPossible.header-experiment',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.resultZeroOptimizationPossible.description-experiment',
  buttonTranslationKey: 'prefill-result-screen.resultZeroOptimizationPossible.button-experiment',
  taxExpertCardLinkText:
    'prefill-result-screen.resultZeroOptimizationPossible.taxExpertCardLinkText-experiment',
  resultOptimizationScreenVersion: OptimizationVersion.OptimizationPossible,
  showConfetti: true,
  showEstimatedResult: true,
};

const NAMandatoryContent: ScreenState = {
  screenTitle: 'prefill-result-screen.nAMandatory.title-experiment',
  cardBgColor: 'secondaryCategory.skyHalf',
  cardBgColorSmallScreen: 'secondaryCategory.blueBackground',
  cardDescription: 'prefill-result-screen.result-card.nAMandatory.description-experiment',
  linkTranslationKey: 'prefill-result-screen.result-card.nAMandatory.link-experiment',
  taxExpertCardHeaderText: 'prefill-result-screen.tax-expert-card.nAMandatory.header-experiment',
  taxExpertCardTitle: 'prefill-result-screen.tax-expert-card.nAMandatory.title-experiment',
  taxExpertCardDescription:
    'prefill-result-screen.tax-expert-card.nAMandatory.description-experiment',
  taxExpertAverageRefundChipText:
    'prefill-result-screen.tax-expert-card.nAMandatory.averageRefundChip-experiment',
  buttonTranslationKey: 'prefill-result-screen.nAMandatory.button-experiment',
  showEstimatedResult: false,
};

export const prefillResultScenariosExperiment: Record<Prefill.PrefillResultScenarios, ScreenState> =
  {
    [Prefill.PrefillResultScenarios.PositiveNoOptimization]: {
      ...positiveNoOptimizationCommonContent,
    },
    [Prefill.PrefillResultScenarios.PositiveNoOptimizationMandatoryNoCedolare]: {
      ...positiveNoOptimizationCommonContent,
      taxExpertCardWarning:
        'prefill-result-screen.positiveNoOptimizationMandatoryNoCedolare.taxExpertWarningText-experiment',
    },
    [Prefill.PrefillResultScenarios.PositiveNoOptimizationMandatoryCedolare]: {
      ...positiveNoOptimizationCommonContent,
      taxExpertCardWarning:
        'prefill-result-screen.positiveNoOptimizationMandatoryCedolare.taxExpertWarningText-experiment',
    },
    [Prefill.PrefillResultScenarios.PositiveOptimizationPossible]: {
      ...positiveOptimizationPossibleCommonContent,
    },
    [Prefill.PrefillResultScenarios.PositiveOptimizationPossibleMandatoryNoCedolare]: {
      ...positiveOptimizationPossibleCommonContent,
      taxExpertCardWarning:
        'prefill-result-screen.positiveOptimizationPossibleMandatoryNoCedolare.taxExpertWarningText-experiment',
    },
    [Prefill.PrefillResultScenarios.PositiveOptimizationPossibleMandatoryCedolare]: {
      ...positiveOptimizationPossibleCommonContent,
      taxExpertCardWarning:
        'prefill-result-screen.positiveOptimizationPossibleMandatoryCedolare.taxExpertWarningText-experiment',
    },
    [Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryNoCedolare]: {
      ...negativeNoOptimizationMandatoryNoCedolareCommonContent,
    },
    [Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryCedolare]: {
      ...negativeNoOptimizationMandatoryNoCedolareCommonContent,
      taxExpertCardTitle:
        'prefill-result-screen.tax-expert-card.negativeNoOptimizationMandatoryCedolare.title-experiment',
      taxExpertCardWarning:
        'prefill-result-screen.negativeNoOptimizationMandatoryCedolare.taxExpertWarningText-experiment',
    },
    [Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryCedolareNoDays]: {
      ...negativeNoOptimizationMandatoryNoCedolareCommonContent,
      taxExpertCardTitle:
        'prefill-result-screen.tax-expert-card.negativeNoOptimizationMandatoryCedolare.title-experiment',
      taxExpertCardWarning:
        'prefill-result-screen.negativeNoOptimizationMandatoryCedolareNoDays.taxExpertWarningText-experiment',
    },
    [Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryNoCedolareNoDays]: {
      ...negativeNoOptimizationMandatoryNoCedolareCommonContent,
    },

    [Prefill.PrefillResultScenarios.NegativeOptimizationPossibleMandatoryNoCedolare]: {
      ...negativeOptimizationPossibleMandatoryNoCedolareCommonContent,
    },
    [Prefill.PrefillResultScenarios.NegativeOptimizationPossibleMandatoryCedolare]: {
      ...negativeOptimizationPossibleMandatoryNoCedolareCommonContent,
      taxExpertCardWarning:
        'prefill-result-screen.negativeOptimizationPossibleMandatoryCedolare.taxExpertWarningText-experiment',
    },
    [Prefill.PrefillResultScenarios.NegativeOptimizationPossibleMandatoryCedolareNoDays]: {
      ...negativeOptimizationPossibleMandatoryNoCedolareCommonContent,
      taxExpertCardWarning:
        'prefill-result-screen.negativeOptimizationPossibleMandatoryCedolareNoDays.taxExpertWarningText-experiment',
    },
    [Prefill.PrefillResultScenarios.NegativeOptimizationPossibleMandatoryNoCedolareNoDays]: {
      ...negativeOptimizationPossibleMandatoryNoCedolareCommonContent,
    },

    [Prefill.PrefillResultScenarios.ResultZeroNoOptimization]: {
      ...resultZeroNoOptimizationContent,
    },

    [Prefill.PrefillResultScenarios.ResultZeroOptimizationPossible]: {
      ...resultZeroOptimizationPossibleContent,
    },
    [Prefill.PrefillResultScenarios.NAMandatory]: {
      ...NAMandatoryContent,
    },
  };
