export class SPIDLoginError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'IFrameSPIDLoginError';
  }
}

export const SPIDLoginErrorMessages = {
  tokenizeCredentialsRequestError: 'Error while creating tokenized credentials request',
  downloadPrefillDocumentsError: 'Error while downloading prefill documents from CWBI',
  spidLoginUrlLoadError: 'SPIDLoginUrl failed to load',
};
