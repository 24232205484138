import { skipIfIDUploaded } from 'src/screens/status-screen/middlewares/identification';
import {
  checkTaxIdQF,
  redirectTaxIdQF,
} from 'src/screens/status-screen/middlewares/tax-id-question-flow';
import { AnalyticsEvent } from 'src/biz-logic/analytics';
import { DocumentUploadMode, questionFlowCategories } from 'src/common/constants-it';
import { getBoundNavigationsActions, getNavigationActions } from 'src/routes/config-util';
import { StatusConfig, StatusStates } from 'src/stores/modules/status';
import { navigation } from 'src/services/navigation-service';
import {
  checkPrefillDocumentsDownloaded,
  redirectSPIDLogin,
  skipIfNoSPIDFlow,
} from 'src/screens/status-screen/middlewares/spid-login';

import { checkEmailConfirmed } from '../middlewares/email';
import { flagsMigration } from '../middlewares/flags-migration';
import {
  checkNotificationsPermissions,
  shouldSkipAskPermission,
} from '../middlewares/notifications';
import { checkIdentification, fetchPrefills } from '../middlewares/prefill';
import { prefillQF } from '../middlewares/prefill-qf';
import { profileMigration } from '../middlewares/profile-migration';
import { QFChangeMigration } from '../middlewares/qf-change-migration';
import { redirectToNotifications } from '../middlewares/redirect';
import {
  addCompleteExpenseUploadStatus,
  addCompleteIncomeUploadStatus,
  checkExpenseDocUploadCompleteFlow,
  checkIncomeDocUploadCompleteFlow,
  hasSkippedExpenseUploadSection,
  hasSkippedIncomeUploadSection,
} from '../middlewares/required-documents';
import { checkQFProgressPaymentBeforeQF } from '../middlewares/season-question-flow';
import {
  checkSkippedStepsForCompletion,
  statusMiddlewareFetch as fetchSubmissions,
  hasActiveSubmissions,
  isLatestSubmissionSubmitted,
  resubmissionInfoBanner,
  skipAlreadySubmitted,
  skipIfResubmission,
  statusMiddlewareCompleteIT as submitComplete,
  updateLatestSubmissionStatusAndPayment,
  statusMiddlewareUpdateIT as updateSubmission,
} from '../middlewares/submission';
import {
  endOfSeasonDisableNavigation,
  endOfSeasonDisableSteps,
  endOfSeasonStatusBanner,
  endOfSeasonSubmitComplete,
  skipIfEndOfSeason,
} from '../middlewares/tax-season';
import { checkAndUpdateUserData } from '../middlewares/user-profile';
import { checkPayment, redirectContactAndPaymentBeforeQF } from '../middlewares/payment';
import { redirectResultScreen } from '../middlewares/result-screen';
import { checkSPIDConsentSigned, redirectSPIDConsentSign } from '../middlewares/spid-consent';
import {
  checkTailoredExperienceStatus,
  redirectTailoredExperience,
  shouldSkipTailoredExperience,
} from '../middlewares/tailored-experience';

// INFO: Config for the complete flow when the Payment is before the QuestionFlow
export const statusConfig: StatusConfig = {
  title: {
    id: 'status.it.title',
  },
  subtitle: {
    id: 'status.it.subtitle.payment-qf.flow',
  },
  steps: [
    {
      id: StatusStates.StoresMigrations,
      middlewares: [profileMigration, flagsMigration, QFChangeMigration],
    },
    {
      id: StatusStates.FetchSubmissions,
      middlewares: [fetchSubmissions],
    },
    {
      id: StatusStates.UpdateSubmissionAndPaymentStatus,
      middlewares: [updateLatestSubmissionStatusAndPayment()],
    },
    {
      id: StatusStates.EnableNotifications,
      skip: [shouldSkipAskPermission],
      middlewares: [checkNotificationsPermissions],
      redirect: redirectToNotifications,
    },
    {
      id: StatusStates.CheckAndUpdateUserData,
      middlewares: [checkAndUpdateUserData],
    },
    {
      id: StatusStates.TailoredExperience,
      skip: [skipAlreadySubmitted, shouldSkipTailoredExperience],
      middlewares: [checkTailoredExperienceStatus],
      redirect: redirectTailoredExperience,
    },
    {
      id: StatusStates.TaxIdQF,
      skip: [skipAlreadySubmitted],
      middlewares: [checkTaxIdQF],
      redirect: redirectTaxIdQF,
    },
    {
      id: StatusStates.SPIDConsent,
      skip: [skipAlreadySubmitted],
      middlewares: [checkSPIDConsentSigned],
      redirect: redirectSPIDConsentSign,
    },
    {
      id: StatusStates.FetchPrefills,
      middlewares: [fetchPrefills],
    },
    {
      id: StatusStates.PrefillSPIDLogin,
      skip: [skipAlreadySubmitted, skipIfIDUploaded, skipIfNoSPIDFlow],
      middlewares: [checkPrefillDocumentsDownloaded],
      redirect: redirectSPIDLogin,
    },
    {
      id: StatusStates.ResultScreen,
      skip: [skipAlreadySubmitted, skipIfIDUploaded, skipIfNoSPIDFlow],
      middlewares: [checkPayment],
      redirect: redirectResultScreen,
    },
    {
      id: StatusStates.Payment,
      skip: [skipAlreadySubmitted],
      middlewares: [checkPayment],
      redirect: redirectContactAndPaymentBeforeQF,
    },
    {
      id: StatusStates.SeasonQF,
      skip: [hasActiveSubmissions],
      middlewares: [prefillQF, checkQFProgressPaymentBeforeQF],
      postBreakProcessing: [endOfSeasonStatusBanner, resubmissionInfoBanner],
      chatBubble: true,
      section: {
        todo: {
          title: {
            id: 'status.it.mandatory-questions.title',
          },
          icon: 'PersonalInfo',
        },
        new: {
          title: {
            id: 'status.it.mandatory-questions.title',
          },
          icon: 'PersonalInfo',
        },
        complete: {
          title: {
            id: 'status.it.mandatory-questions.title',
          },
          icon: 'PersonalInfo',
          navigation: {
            id: 'status.section.completed.edit-button',
            link: {
              action: 'toQuizMaster',
              config: 'screen',
              params: {
                root: questionFlowCategories.livingSituation,
                editing: false,
                onNext: () => {
                  getBoundNavigationsActions().toQuizMaster('screen', {
                    root: questionFlowCategories.residenceAddress,
                    editing: false,
                    onNext: () => {
                      getBoundNavigationsActions().toQuizMaster('screen', {
                        root: questionFlowCategories.property,
                        editing: false,
                        onNext: () => {
                          getBoundNavigationsActions().toQuizMaster('screen', {
                            root: questionFlowCategories.dependants,
                            editing: false,
                            onNext: () => {
                              getBoundNavigationsActions().reset({
                                index: 0,
                                actions: [getNavigationActions().toStatus('screen')],
                              });
                            },
                          });
                        },
                      });
                    },
                  });
                },
              },
            },
          },
        },
      },
    },
    {
      id: StatusStates.IncomeDocUpload,
      skip: [
        hasActiveSubmissions,
        skipIfEndOfSeason,
        hasSkippedIncomeUploadSection,
        skipIfResubmission,
      ],
      middlewares: [checkIncomeDocUploadCompleteFlow],
      postBreakProcessing: [
        checkEmailConfirmed('email-new-user'),
        endOfSeasonStatusBanner,
        resubmissionInfoBanner,
      ],
      chatBubble: true,
      section: {
        todo: {
          title: {
            id: 'status.it.income-doc-upload.title',
          },
          icon: 'Income',
        },
        complete: {
          title: {
            id: 'status.it.income-doc-upload.title',
          },
          icon: 'Income',
          navigation: {
            id: 'status.section.completed.edit-button',
            link: {
              action: 'toRequiredDocumentsDigitalCaf',
              config: 'screen',
              params: {
                onNext: () => {
                  getBoundNavigationsActions().reset({
                    index: 0,
                    actions: [getNavigationActions().toStatus('screen')],
                  });
                },
                screenMode: DocumentUploadMode.INCOME_STEP,
              },
            },
          },
        },
        categoryNotComplete: {
          title: {
            id: 'status.it.expense-doc-upload.title',
          },
          icon: 'Income',
        },
        new: {
          title: {
            id: 'status.it.income-doc-upload.title',
          },
          icon: 'Income',
        },
      },
      sectionResolver: addCompleteIncomeUploadStatus,
    },
    {
      id: StatusStates.ExpenseDocUpload,
      skip: [
        hasActiveSubmissions,
        skipIfEndOfSeason,
        hasSkippedExpenseUploadSection,
        skipIfResubmission,
      ],
      middlewares: [checkExpenseDocUploadCompleteFlow],
      postBreakProcessing: [
        checkEmailConfirmed('email-new-user'),
        endOfSeasonStatusBanner,
        resubmissionInfoBanner,
      ],
      chatBubble: true,
      section: {
        todo: {
          title: {
            id: 'status.it.expense-doc-upload.title',
          },
          icon: 'Expense',
        },
        new: {
          title: {
            id: 'status.it.expense-doc-upload.title',
          },
          icon: 'Expense',
        },
        complete: {
          title: {
            id: 'status.it.expense-doc-upload.title',
          },
          icon: 'Expense',
          navigation: {
            id: 'status.section.completed.edit-button',
            link: {
              action: 'toRequiredDocumentsDigitalCaf',
              config: 'screen',
              params: {
                onNext: () => {
                  getBoundNavigationsActions().reset({
                    index: 0,
                    actions: [getNavigationActions().toStatus('screen')],
                  });
                },
                screenMode: DocumentUploadMode.EXPENSES_STEP,
              },
            },
          },
        },
        receipt: {
          title: {
            id: 'status.it.expense-doc-upload.title',
          },
          inProgress: true,
          icon: 'Expense',
          tracking: {
            eventName: AnalyticsEvent.sectionJourney,
            params: {
              journeyStatus: 'next-receipts',
            },
          },
        },
        categoryNotComplete: {
          title: {
            id: 'status.it.expense-doc-upload.title',
          },
          icon: 'Expense',
          inProgress: true,
        },
      },
      sectionResolver: addCompleteExpenseUploadStatus,
    },
    {
      id: StatusStates.FetchPrefills,
      middlewares: [fetchPrefills],
    },
    {
      id: StatusStates.Submit,
      skip: [isLatestSubmissionSubmitted, skipIfEndOfSeason],
      middlewares: [checkIdentification, checkSkippedStepsForCompletion, updateSubmission],
      postBreakProcessing: [
        checkEmailConfirmed('email'),
        endOfSeasonStatusBanner,
        endOfSeasonDisableSteps,
        endOfSeasonDisableNavigation,
        resubmissionInfoBanner,
      ],
      chatBubble: true,
      navigation: {
        //starts with ID upload
        default: {
          title: 'status.it.navigation.default',
          link: {
            action: 'toPrefillDocumentUpload',
            config: 'screen',
            params: {
              italyWebFooterButtonTitleId: 'status.it.start.button.title',
              onNext: () =>
                navigation.reset({
                  index: 2,
                  actions: [
                    getNavigationActions().toDashboard('screen'),
                    getNavigationActions().toStatus('screen'),
                    getNavigationActions().toItalyDonation('screen'),
                  ],
                }),
            },
          },
          tracking: {
            eventName: AnalyticsEvent.prefillStarted,
          },
        },
        //Id upload rejection case
        rejectedInfo: {
          title: 'status.it.identification.navigation.rejected',
          link: {
            action: 'toPrefillDocumentUpload',
            config: 'screen',
            params: {
              italyWebFooterButtonTitleId: 'status.it.continue.button.title',
              onNext: () =>
                navigation.reset({
                  index: 2,
                  actions: [
                    getNavigationActions().toDashboard('screen'),
                    getNavigationActions().toStatus('screen'),
                    getNavigationActions().toItalyDonation('screen'),
                  ],
                }),
            },
          },
        },
        rejectedDocument: {
          title: 'status.it.identification.navigation.rejected',
          link: {
            action: 'toPrefillDocumentUpload',
            config: 'screen',
            params: {
              italyWebFooterButtonTitleId: 'status.it.continue.button.title',
              onNext: () =>
                navigation.reset({
                  index: 2,
                  actions: [
                    getNavigationActions().toDashboard('screen'),
                    getNavigationActions().toStatus('screen'),
                    getNavigationActions().toItalyDonation('screen'),
                  ],
                }),
            },
          },
        },
        reminder: {
          title: 'status.navigation.draft.title',
          link: {
            action: 'toSkippedSectionReminder',
            config: 'screen',
          },
        },
        phoneNumber: {
          title: 'status.navigation.draft.title',
          link: {
            action: 'toPhoneNumber',
            config: 'screen',
          },
        },
        draftSubmission: {
          title: 'status.navigation.draft.title',
          link: {
            action: 'toItalyDonation',
            config: 'screen',
          },
        },
        pending: null,
        blocked: null,
      },
      section: {
        todo: {
          title: {
            id: 'status.it.lastStep.title',
          },
          icon: 'Identification',
        },
        new: {
          title: {
            id: 'status.it.lastStep.title',
          },
          icon: 'Identification',
          tracking: {
            eventName: AnalyticsEvent.sectionJourney,
            params: {
              journeyStatus: 'next-prefill',
            },
          },
          navigation: {
            id: 'status.section.completed.edit-button',
            link: {
              action: 'toPrefillDocumentUpload',
              config: 'screen',
              params: {
                onNext: () =>
                  getBoundNavigationsActions().reset({
                    index: 2,
                    actions: [
                      getNavigationActions().toDashboard('screen'),
                      getNavigationActions().toStatus('screen'),
                      getNavigationActions().toItalyDonation('screen'),
                    ],
                  }),
              },
            },
          },
        },
        rejected: {
          title: {
            id: 'status.it.lastStep.title',
          },
          icon: 'Identification',
          warning: true,
          navigation: {
            id: 'status.section.completed.edit-button',
            link: {
              action: 'toPrefillDocumentUpload',
              config: 'screen',
              params: {
                onNext: () =>
                  getBoundNavigationsActions().reset({
                    index: 2,
                    actions: [
                      getNavigationActions().toDashboard('screen'),
                      getNavigationActions().toStatus('screen'),
                      getNavigationActions().toItalyDonation('screen'),
                    ],
                  }),
              },
            },
          },
        },
        draftSubmission: {
          title: {
            id: 'status.it.lastStep.title',
          },
          icon: 'Identification',
          inProgress: true,
          tracking: {
            eventName: AnalyticsEvent.filingFlowStarted,
          },
          navigation: {
            link: {
              action: 'toItalyDonation',
              config: 'screen',
            },
          },
        },
      },
    },
    {
      id: StatusStates.Complete,
      middlewares: [submitComplete, endOfSeasonSubmitComplete],
      postBreakProcessing: [
        endOfSeasonStatusBanner,
        endOfSeasonDisableSteps,
        resubmissionInfoBanner,
      ],
      chatBubble: true,
    },
  ],
};
