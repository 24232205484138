import * as React from 'react';
import { ScrollView } from 'react-native';
import { compose } from 'redux';
import { CountryCodes } from '@taxfix/types';

import { NavButton } from 'src/taxfix-components/src';
import { FooterOptions } from 'src/components/footer.web';
import { useItalyIntl } from 'src/_italy/_hooks';
import { useNavigation } from 'src/hooks/navigation-hook';
import { getNavigationActions } from 'src/routes/config-util';

import { SimpleQFLayout } from '../../components/simple-qf-layout';
import FormField from '../../components/form-field-extended';
import {
  Box,
  TextInput,
  BodySecondaryText,
  theme,
  useLargeScreenQuery,
} from '../../components/core';
import { validatePhoneNumber } from '../../services/phone-number';
import { QuizmasterLight, withQuizmasterLight } from '../../utils/with-quizmaster-light';
import { wayQuestionIds } from '../../common/constants-it';

const validate = (phoneNumber: string) => {
  const formErrors: any = {};

  if (phoneNumber && !validatePhoneNumber(phoneNumber, CountryCodes.IT)) {
    formErrors.phoneNumber = {
      key: 'it.digital-caf.qf.profile.phone-number.screen.select.phone-number.invalid',
    };
  }

  return formErrors;
};

type Props = {
  onNext: () => void;
  quizmasterLight: QuizmasterLight;
  footerOptions?: FooterOptions;
};

const inputTitleStyle = [
  {
    fontFamily: 'CircularStd-Book',
    fontSize: 14,
    color: theme.color.greyFillIcon,
  },
];

export const PhoneNumberUndecorated: React.FC<Props> = ({
  onNext,
  quizmasterLight,
  footerOptions,
}) => {
  const [phoneNumber, setPhoneNumber] = React.useState<string>(
    quizmasterLight[wayQuestionIds.phoneNumber].answer || '',
  );
  const [errors, setErrors]: any = React.useState({});

  const isLargeScreen = useLargeScreenQuery();
  const { getTranslationText } = useItalyIntl();
  const { navigation, safeResetNavigation } = useNavigation();

  const handleOnContinue = React.useCallback(() => {
    setErrors({});
    const formErrors = validate(phoneNumber);

    if (Object.keys(formErrors).length) {
      setErrors(formErrors);
      return;
    }

    quizmasterLight[wayQuestionIds.phoneNumber].saveAnswer(phoneNumber);

    if (onNext) {
      onNext();
    }
  }, [phoneNumber, quizmasterLight, onNext]);

  const handlePhoneNumberFocus = React.useCallback(
    (onFocus: any) => () => {
      setErrors({});
      if (onFocus) onFocus();
    },
    [],
  );

  const handlePhoneNumberBlur = React.useCallback(
    (onBlur: any) => () => {
      setErrors(validate(phoneNumber));
      if (onBlur) onBlur();
    },
    [phoneNumber],
  );

  const handleOnBackButtonPress = (): void => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      safeResetNavigation([getNavigationActions().toStatus('screen')]);
    }
  };

  return (
    <>
      {isLargeScreen && (
        <NavButton
          text={getTranslationText('it.navigation.back-to-home')}
          onPress={handleOnBackButtonPress}
        />
      )}
      <SimpleQFLayout
        headerTranslationKey="it.digital-caf.qf.profile.phone-number.screen.title"
        onPressContinue={handleOnContinue}
        continueButtonTranslationKey="it.digital-caf.qf.profile.phone-number.screen.button.continue"
        centered
        footerOptions={footerOptions}
        hideProgressBar
      >
        <Box flex top={2}>
          <ScrollView keyboardShouldPersistTaps="handled">
            <Box bottom={3}>
              <BodySecondaryText id="it.digital-caf.qf.profile.phone-number.screen.description" />
            </Box>
            <Box bottom={3}>
              <FormField
                label={{
                  key: 'it.digital-caf.qf.profile.phone-number.screen.select.phone-number',
                }}
                error={{
                  key: errors.phoneNumber?.key || '',
                  level: 'Error',
                }}
                labelStyle={inputTitleStyle}
                fieldBoxStyle={{
                  backgroundColor: theme.color.background,
                }}
              >
                {({ onBlur, onFocus }) => (
                  <TextInput
                    value={phoneNumber}
                    onChangeText={setPhoneNumber}
                    onBlur={handlePhoneNumberBlur(onBlur)}
                    onFocus={handlePhoneNumberFocus(onFocus)}
                    style={{
                      flex: 1,
                    }}
                    autoCautoCompleteType="tel"
                    keyboardType="phone-pad"
                  />
                )}
              </FormField>
            </Box>
          </ScrollView>
        </Box>
      </SimpleQFLayout>
    </>
  );
};
export const PhoneNumberItalyContainer = compose(withQuizmasterLight([wayQuestionIds.phoneNumber]))(
  // @ts-ignore
  PhoneNumberUndecorated,
);
