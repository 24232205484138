import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { GestureResponderEvent, Pressable } from 'react-native';

import { Button, Container, Text, Toast } from 'src/taxfix-components/src';
import { ToastBannerLevel } from 'src/taxfix-components/src/components/general/Toast/types';
import { useItalyIntl } from 'src/_italy/_hooks/use-italy-intl';
import Separator from 'src/components/separator';
import { ProductBundleValues } from 'src/common/constants-it';
import NotificationBanner from 'src/components/notification-banner';
import { selectors as remoteConfigSelectors } from 'src/stores/modules/remote-config-firebase';
import { selectors as paymentSelectors } from 'src/stores/modules/payment';

import { BillingDetails } from './billing-details';
import { usePaymentContext } from './payment.context';
import { DisclaimerWithTotal } from './disclaimer-with-total';
import { DiscountCard } from './discount-card';
import { ApplyVoucherResponse } from './stripe/types';

type ChildProps = {
  isPaypalEnabled: boolean;
  isDisablePayment: boolean;
};

type Props = {
  children: React.FC<ChildProps>;
  handleSubmit: (e: GestureResponderEvent) => Promise<void>;
  onApplyVoucher: (couponCode: string) => Promise<ApplyVoucherResponse>;
  onRemoveVoucher: () => void;
  isCardSubmitDisabled: boolean;
  isValidationFailed: boolean;
  isSubmitting: boolean;
  errorMessage?: string;
};

const PaymentFormLayout: React.FC<Props> = ({
  children,
  handleSubmit,
  isCardSubmitDisabled,
  isSubmitting,
  onApplyVoucher,
  onRemoveVoucher,
  isValidationFailed,
  errorMessage = '',
}) => {
  const { getTranslationText } = useItalyIntl();
  const { selectedProductBundle, billingAddress, paypalError } = usePaymentContext();
  const [showAddressWarning, setShowAddressWarning] = useState(false);
  const isPaypalEnabled = useSelector(remoteConfigSelectors.isPaypalEnabled).enabled;
  const paymentConfig = useSelector(remoteConfigSelectors.getItalyProductsPaymentConfig).default;
  const couponResult = useSelector(paymentSelectors.getCouponResult);

  const isPaymentScreenBannerEnabled = useSelector(
    remoteConfigSelectors.isPaymentScreenBannerEnabled,
  );

  const productIsFree = (selectedProduct: ProductBundleValues) =>
    paymentConfig[selectedProduct].isFree;
  const isInstantBundle = selectedProductBundle === ProductBundleValues.instant;

  const displayError = errorMessage || paypalError;
  const title = isInstantBundle
    ? 'it.payment.instant.screen.title'
    : 'it.payment.guided.screen.title';

  const getChildren = (child: React.FC<ChildProps>) => {
    const childProps = { isPaypalEnabled, isDisablePayment: !billingAddress };
    return billingAddress ? (
      child(childProps)
    ) : (
      <Pressable
        onPress={() => {
          setShowAddressWarning(true);
        }}
      >
        <Container pointerEvents="none">{child(childProps)}</Container>
      </Pressable>
    );
  };

  const getBoldText = (text: string) => <Text fontWeight="bold" value={text} variant="bodySBook" />;

  const userNoticeBannerContent: string = isInstantBundle
    ? 'payment.user-notice.banner.instant'
    : 'payment.user-notice.banner.guided';

  return (
    <>
      {displayError && (
        <NotificationBanner errorType="Error">
          <Text variant="bodySBold" color="greytones.white">
            {getTranslationText(displayError)}
          </Text>
        </NotificationBanner>
      )}
      {showAddressWarning && !billingAddress && (
        <Toast
          marginX={24}
          alignItems={{ md: 'center', base: 'flex-start' }}
          text={getTranslationText('payment.billing-address.banner')}
          contentColor="greytones.textDefault"
          iconName="exclamationMark"
          level={ToastBannerLevel.Info}
        />
      )}
      <Container marginX={{ md: 'auto', base: 24 }}>
        <Container alignItems="flex-start" py={20}>
          <Text
            variant={{ base: 'titleMBold', md: 'titleXLBold' }}
            value={getTranslationText(title)}
          />
          {isPaymentScreenBannerEnabled && (
            <Toast
              width="100%"
              alignItems={{ md: 'center', base: 'flex-start' }}
              text={getTranslationText(userNoticeBannerContent, { bold: getBoldText })}
              iconSize="18px"
              iconName="shieldCheckSolid"
              iconColor="brand.brandGreen"
              contentColor="greytones.textDefault"
              bg="greytones.surface"
            />
          )}
        </Container>
        <BillingDetails billingAddress={billingAddress} isValidaionFailed={isValidationFailed} />
        <Separator style={{ padding: 10, marginBottom: 20 }} />
        {getChildren(children)}
        {!productIsFree(selectedProductBundle) && (
          <DiscountCard
            onApplyVoucher={onApplyVoucher}
            onRemoveVoucher={onRemoveVoucher}
            couponResult={couponResult}
          />
        )}
        <DisclaimerWithTotal />
      </Container>
      <Container
        flexDirection={{ base: 'column', md: 'row' }}
        width="100%"
        justifyContent={{ base: 'center', md: 'space-around' }}
        alignItems="center"
        borderColor="greytones.graphicsLight"
        borderTopWidth="xs"
        py={0}
        px={24}
      >
        <Button
          width={{ base: 'xl', md: '1/4' }}
          title={getTranslationText(
            billingAddress
              ? 'payment.credit-card.button'
              : 'payment.credit-card-fill-address.button',
          )}
          onPress={handleSubmit}
          variant="primary"
          disabled={isCardSubmitDisabled || !billingAddress}
          isLoading={isSubmitting}
          m={0}
        />
      </Container>
    </>
  );
};

export { PaymentFormLayout };
