import React from 'react';

import { Container } from '../../layout/Container';
import { ContactLink } from '../ContactLink';

import { StyledCard } from './styles';
import { CardProps } from './types';

export const Card: React.FC<CardProps> = ({
  children,
  variant,
  withNavigation,
  navigationHandler = () => {},
  navigationLabel = '',
  ...otherProps
}) => {
  return (
    <StyledCard variant={variant} {...otherProps}>
      {withNavigation ? (
        <>
          <Container bg="greytones.white" borderRadius={1} padding={2}>
            {children}
          </Container>
          <ContactLink handleLink={navigationHandler} linkMessage={navigationLabel} />
        </>
      ) : (
        children
      )}
    </StyledCard>
  );
};
