import { $Values, $Keys } from 'utility-types';
import { CountryCodes } from '@taxfix/types';

import { DocumentUploadGuidanceSection } from 'src/containers/document-upload-guidance-overlay';

export enum TabNames {
  Dashboard = 'Dashboard',
  Experts = 'Experts',
  Account = 'Account',
  TaxGuide = 'TaxGuide',
}

export enum DocumentUploadMode {
  INCOME_STEP = 'INCOME_STEP',
  EXPENSES_STEP = 'EXPENSES_STEP',
}

export const CWBIDocumentTypes: Record<string, string> = {
  CU_PDF: 'cu-pdf',
  VISURA_CATASTALE_ANALITICA_PDF: 'visura-catastale-analitica-pdf',
};

export enum CWBIRequestDocumentTypes {
  MOD_CUK = 'MOD_CUK',
  MOD_730 = 'MOD_730',
  MOD_730_PRE = 'MOD_730_PRE',
  MOD_UNI = 'MOD_UNI',
  MOD_UNI_PRE = 'MOD_UNI_PRE',
  SPESA_SANITARIA = 'SPESA_SANITARIA',
  VISURA_CATASTALE_ANALITICA = 'VISURA_CATASTALE_ANALITICA',
  DATI_SINTESI_PRE = 'DATI_SINTESI_PRE',
}

export enum PrefillDocumentsPdfTypes {
  CU_PDF = 'cu-pdf',
  MOD_730_PDF = 'mod-730-pdf',
  MOD_730_PRE_PDF = 'mod-730-pre-pdf',
  MOD_UNI_PDF = 'mod-uni-pdf',
  MOD_UNI_PRE_PDF = 'mod-uni-pre-pdf',
  VISURA_CATASTALE_ANALITICA_PDF = 'visura-catastale-analitica-pdf',
}

export enum PrefillDocumentsJsonTypes {
  CU_JSON = 'cu-json',
  MOD_730_JSON = 'mod-730-json',
  MOD_730_PRE_JSON = 'mod-730-pre-json',
  MOD_UNI_JSON = 'mod-uni-json',
  MOD_UNI_PRE_JSON = 'mod-uni-pre-json',
  VISURA_CATASTALE_ANALITICA_JSON = 'visura-catastale-analitica-json',
  SPESA_SANITARIA_JSON = 'spesa-sanitaria-json',
  DATI_SINTESI_PRE_JSON = 'foglio-informativo-json',
}

export const questionFlowCategories = {
  way: '42b5a9b47d539e2ff9bd',
  livingSituation: 'e56a8c645cde2c1f4821',
  residenceAddress: '321111e04c94bd717fe4',
  property: 'c5670c634ab781330a30',
  dependants: 'ccd24f3beccdfe781ec4',
  preseasonTaxId: '34ddbd615b232285415b',
  taxId: '078209943571bd193728',
  billingAddress: 'c27152f27eaf5e99eda1',
  taxSubstitute: '5859fe52b4f6a63e9094',
  tailoredExperience: '36b76be953663f0c2167',
};

export const migrationQuestionIds = {
  sameCityLastYear: '8e8e32b42df45de02faf',
  sameCityCurrentYear: '8ffc5c1d3cbaee2c200b',
  cityLastYear: 'f059eaa480f7719f5a7a',
  cityCurrentYear: '1d12b6661038d7cc6288',
};

export const questionFlowCategoriesPrefilled = {
  livingSituation: 'f7fa3a5b3be668404562',
  residenceAddress: '82d24a7966ee29eed326',
  property: 'b8199a56469402a93d2d',
};

export const tailoredExperienceQuestionIds = {
  lastyearIncome: 'ddb6113cb123ba2d03dd',
  lastyearExpense: 'e7bb8a2e740db406d0b4',
  importantAspect: 'bb8338b970b79e11256c',
};

export const correlatedPrefilledCategories = {
  [questionFlowCategories.livingSituation]: questionFlowCategoriesPrefilled.livingSituation,
  [questionFlowCategories.residenceAddress]: questionFlowCategoriesPrefilled.residenceAddress,
  [questionFlowCategories.property]: questionFlowCategoriesPrefilled.property,
};

export const prefilledCategoryInfoContent = {
  [questionFlowCategories.livingSituation]: {
    title: 'it.question-flow.prefilled-categories.living-situation.title',
    subtitle: 'it.question-flow.prefilled-categories.living-situation.subtitle',
    infoOverlay: {
      title: 'it.question-flow.prefilled-categories.living-situation.info-overlay.title',
      content: 'it.question-flow.prefilled-categories.living-situation.info-overlay.content',
    },
  },
  [questionFlowCategories.residenceAddress]: {
    title: 'it.question-flow.prefilled-categories.residence-address.title',
    subtitle: 'it.question-flow.prefilled-categories.residence-address.subtitle',
    infoOverlay: {
      title: 'it.question-flow.prefilled-categories.residence-address.info-overlay.title',
      content: 'it.question-flow.prefilled-categories.residence-address.info-overlay.content',
    },
  },
  [questionFlowCategories.property]: {
    title: 'it.question-flow.prefilled-categories.property.title',
    subtitle: 'it.question-flow.prefilled-categories.property.subtitle',
    infoOverlay: {
      title: 'it.question-flow.prefilled-categories.property.info-overlay.title',
      content: 'it.question-flow.prefilled-categories.property.info-overlay.content',
    },
  },
};

export const childLoopCountId = '2d655a27e37bcd8d42e6';
export const childLoopTaxId = '3a5ee969f6fd218975d1';
export const childLoopQuestionIds = [
  childLoopTaxId, // child's taxid
  '8b9c0e3a397aa40a569c', // months dependant
  '3362a2c71ec596e2b40a', // percentage dependency
  'a916cee434769e2acafb', // child description (natural, adopted, foster care)
  'cfebd981d96b71b67fe8', // disability main question
  '153e7cc2a7467486ec8b', // disability silent question (option D)
  '9c6b540e6aa27acbf3ed', // disability silent question (option F)
];

export const wayQuestionIds = {
  occupation: 'bfa8c7ee50ff59739f71',
  firstName: 'b931c1ed60f378d04ad2',
  lastName: 'a660897f028f6aedf366',
  phoneNumber: 'f851e9aba892a4fbeb9c',
  newbie: '340519aeb11162c49daa',
};

// Make sure to udpate the translation keys whenever updating the ProductBundleValues (payment-credit-card.stripe-native.tsx#L162)
export enum ProductBundleValues {
  instant = 'instant',
  guided = 'guided',
}

export const flagsQuestionIds = {
  documentsUploadConsent: 'ca497c3c5177bd7fb734',
  afterSubmissionFeedbackDisplayed: '1cf20bf8570de20a921f',
  flowStarted: '029e99c18ed9a70e197c',
  expensesUploadSkipped: 'bdfe9521becb90fcb2fc',
  prefillUploadSkipped: '48601406f030989bb3c8',
  incomeUploadSkipped: '622f3a0a0ab4951d960e',
  areDocumentConfirmedOnResubmission: '04f5be711eb390b4dd4c',
  onResubmissionConsentGiven: 'bb0e0bee48c0d7c05f94',
  isConfirmationAnsweredOnResubmission: '5bb7109be90b521454b0',
  gdprConsentGiven: '314c5c45d729635a3a15',
  prefillMandateSigned: '335b1768895713a15970', // prefill experiment v2 + v3
  SPIDCredentialsConsentGiven: '6af4e91b16ed44a8c422', // prefill experiment v3
  prefilledCreditResult: '24e0bea1d5ea7a9b750f', // prefill experiment v3 (answer type `string` - used to save the result of prefilled credit
  preseasonCUDocument: '327ee9524885c9001dd7', // preseason to be set when the user gets the CU document successfully
  prefillDocumentsDownloaded: 'd1e8fbd7e73e50689529', // flow to season 2nd May
  commercialistaConsentGivenV500: 'c3a63db68b937c03a105', // commercialista consent in season
  SPIDConsentXMLSigned: 'e67905a28bc46b4f481a',
  expenseUploadScreenSeen: 'a214a235fd1a6833f23c',
  productBundleSelection: '4db3d82d7c51b5e950ec',
  confirmedBundleSelection: '8b15207ed4cc15150ecd',
  submissionComplexity: '4b80ea1870d6a5b04822',
  SPIDTimeoutFlow: '0a82749415cd8731ba4b',
  otherReasonToChooseBundleLater: 'bc921ae91950d03b1725', // not in use anymore - choose later screen removed (ITA-4128)
  reasonWhyRefundIsZero: '5452603d321649d77fc0',
  amlConsent: '0e15b632c9c56d744147',
  taxScenario: '27eb0b956db6bfb9c80b',
  taxOptimizations: '00d79b653b54cf154990',
  tailoredExperienceStatus: 'dbd32ad4e8d147a8bafd',
} as const;

export const specificAnswerTypesQuestionIds = {
  sameResidenceCityYearStart: '8e8e32b42df45de02faf',
  sameResidenceCityYearEnd: '8ffc5c1d3cbaee2c200b',
  prevYearCity: 'f059eaa480f7719f5a7a',
  currentYearCity: '1d12b6661038d7cc6288',
  employerName: '77b9b3c5207160fef123',
  employerTaxCode: '6d22aeeb4bf1690c9fa3',
  employerAddress: '4e77282c4a706c51abe0',
  spouseHasItalianFiscalCode: 'c65f560bd77d60163e47',
  childHasItalianFiscalCode: 'a6e79356eb0d61312803',
  relativeHasItalianFiscalCode: 'a5c7e9e151ab81e0fdce',
  hasDependants: '19d10c7c28f423333e8f',
};

export const prefillQuestionIds = {
  firstTimeFiling: 'ad23e6a6c63474565760', // TODO: cleanup post experiment first time filing
  preseasonTaxId: '1d5553f6c5f0a3c62852',
  taxId: '7cde6a99a2e03707c79c',
  billingAddress: '1c506a5f00e490cd71a0',
};

export const donationQuestionsIds = {
  churchAndState: '972096aaf39021e63205',
  churchAndStateArea: '82139be2850c6d8c26d8',
  nonProfit: '7f12857564acd06def99',
  nonProfitCode: 'e5674f8dba4a80341cb1',
  politicalParty: 'b979b83b10511a0a5f2f',
};

export const inSeasonQFIds = {
  living_situation: '5e77cf2673b1bba26caf',
};
export enum IncomeAnswersKeys {
  owns_residence = 'owns_residence',
  owns_residence_usufruttuario = 'owns_residence_usufruttuario',
  properties_rented = 'properties_rented',
  other = 'other',
}

export enum ExpenseAnswerKey {
  rents_residence = 'rents_residence',
  delete = 'delete',
}

export const renovationExpenses = [
  'DOCUMENT_EXPENSE_HOME_HOUSEHOLD_SUPERBONUS',
  'DOCUMENT_EXPENSE_HOME_HOUSEHOLD_RENOVATION',
  'DOCUMENT_EXPENSE_HOME_HOUSEHOLD_FURNITURE',
  'DOCUMENT_EXPENSE_HOME_HOUSEHOLD_GREEN',
  'DOCUMENT_EXPENSE_HOME_HOUSEHOLD_FACADE',
  'DOCUMENT_EXPENSE_HOME_HOUSEHOLD_SISMA',
  'DOCUMENT_EXPENSE_HOME_HOUSEHOLD_HOUSE_A_B',
  'DOCUMENT_EXPENSE_HOME_HOUSEHOLD_ENERGY',
];

export const propertyIds = {
  owned_some_type_of_property: '130693ca6fcf8af5c3d9',
  usage_of_property: '55f923439c30be8ef1fc',
};

export const shortSummaryAnswersTranslationKeys = [
  inSeasonQFIds.living_situation,
  propertyIds.owned_some_type_of_property,
  propertyIds.usage_of_property,
];

const incomeSilentQuestions: Record<string, string> = {
  cat_other_income: '6f81ef8bfa31a614dffe',
};

const expenseSilentQuestions: Record<string, string> = {
  cat_transportation: '362736e14f00c0f1bc52',
  cat_sport_for_children: 'a2d1b29101e5c0de1c7f',
  cat_agency_fees: 'ed028ec3e081fea1aee0',
  cat_music_bonus: '55346a2d81ef87c08266',
  cat_superbonus: '328ee3716091cd478a9e',
  cat_alimony: '51029342a50a0185c4ee',
  cat_contributions_dependants: 'e14c70e0e944428d617e',
  cat_prepayment_and_carryover: '65aab07469e8e826b06f',
  cat_other_deductible_costs: '49c96e6eaa5b6be46bc0',
  cat_tax_credit: '0e37e49de58d79f354c4',
  cat_medical: '9868545acb64d436a26d',
  cat_donations: 'aa35e965820390ed0e4c',
  cat_green_works: '5baae1d639f0d17fa7e6',
  cat_electric_vehicle_charging: 'd7b8009e8bf1a2806642',
  cat_insurance: 'f20e88bce0e53841149c',
  cat_funeral: '6804d822e3319aa9d0ec',
  cat_facade_bonus: '0e34e5d144b4abce99c5',
  cat_medical_disabled: '4e215534a4b3ace869ce',
  cat_mortgage: '0d0fbdb8f5ee7c34b5a4',
  cat_compensatory_instruments: '07a0936cc37fde47dbe0',
  cat_energy_efficiency_works: 'f9aa53231ef07db28121',
  cat_paid_back_to_employer: '831735ea049861fec4ad',
  cat_veterinary: 'd343ab28d30696255ff6',
  cat_home: '35083e98f2585c33ece0',
  cat_furniture_bonus: 'b2a4926b6481b38887a8',
  cat_pension_contributions: '1e0bed2f82540cf3bf20',
  cat_education: 'ec90d95910b4e9a86c9f',
  cat_sisma: '979f166d06aa324bdd86',
  cat_house_purchase: 'b672eb4424f965b1767b',
};

export const expenseListQuestions: Record<string, string> = {
  cat_medical: '2b02ef924a2d5b798709',
  cat_insurance: '6ca075dec86ae3276982',
  cat_mortgage: 'e0ac3f6527f3b5d0d6d8',
  cat_veterinary: '8c19158e5d7eb2200820',
  cat_education: '708aebfae08f219ce473',
  cat_donations: '0e80c3825f9bc7970a01',
  cat_funeral: '44cf7cd1d6c096f9e0e9',
  cat_compensatory_instruments: '95adf80a481baf976b73',
  cat_home: 'f78fc6bc8a53b0081263',
  cat_sisma: '09b5c89bf84426820d36',
  cat_green_works: '474711b0e3163b0c87c8',
  cat_facade_bonus: 'e80c51415cd06b9abe5d',
  cat_energy_efficiency_works: '753d8685cf48481e1f59',
  cat_furniture_bonus: '5e6dc7166d9b8dcd93d7',
  cat_house_purchase: '6137ddfc07b18abd4349',
  cat_electric_vehicle_charging: '7ab9dfcb31af349bfaca',
  cat_medical_disabled: '1f9c9c455a37e07a18ea',
  cat_paid_back_to_employer: '3001caa14674cc47e18b',
  cat_pension_contributions: '0fa1c8e7a14962573044',
};

const expensePrefilledSilentQuestions: Record<string, string> = {
  cat_medical: '8471f0bece8c492f3a30',
  cat_donations: 'f416b070859e3c360dd2',
  cat_green_works: '158768f2970e0817ba09',
  cat_electric_vehicle_charging: '715e3da8253b448a248e',
  cat_insurance: '59904cd55ddbffcaea7f',
  cat_funeral: 'f589614af99e337ae0b9',
  cat_facade_bonus: '34e280b87ca4f46ccb31',
  cat_medical_disabled: '04532f8668c8c7f165ad',
  cat_mortgage: '217085fdd1450ba0f583',
  cat_compensatory_instruments: 'c80104a9a6ae1c215dbe',
  cat_energy_efficiency_works: 'cc0e6cabe80fd9210384',
  cat_paid_back_to_employer: '37dcca9bace1c48eba9a',
  cat_veterinary: '8131d1a531dbd212d082',
  cat_home: 'c49d802f505a0fa2f405',
  cat_furniture_bonus: 'ed8555709b71bcd59e84',
  cat_pension_contributions: 'fe240c73f6e1742f8ece',
  cat_education: 'b07ec2bda2e248c05bae',
  cat_sisma: 'f6a42f312c652d1b8014',
  cat_house_purchase: '6262493ada5bf9aaf0f9',
};

export const incomeSilentQuestionIds: Array<string> = Object.values(incomeSilentQuestions);
export const expenseSilentQuestionIds: Array<string> = Object.values(expenseSilentQuestions);
export const expenseListQuestionIds: Array<string> = Object.values(expenseListQuestions);
export const expensePrefilledSilentQuestionIds: Array<string> = Object.values(
  expensePrefilledSilentQuestions,
);

export type DonationOption = {
  value: string;
  translationKey: string;
};

export const ITALY_DONATION_OPTIONS_OTTO_X_MILLE: DonationOption[] = [
  {
    value: 'state',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.state',
  },
  {
    value: 'catholic',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.catholic',
  },
  {
    value: '7th_day_adventist',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.7th_day_adventist',
  },
  {
    value: "god's_assemblees",
    translationKey: "it.digital-caf.donation-option.otto_x_mille.god's_assemblees",
  },
  {
    value: 'valdese',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.valdese',
  },
  {
    value: 'lutheran',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.lutheran',
  },
  {
    value: 'jewish',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.jewish',
  },
  {
    value: 'orthodox',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.orthodox',
  },
  {
    value: 'apostolic',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.apostolic',
  },
  {
    value: 'baptist',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.baptist',
  },
  {
    value: 'buddhist',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.buddhist',
  },
  {
    value: 'hinduist',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.hinduist',
  },
  {
    value: 'soka_gakkai',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.soka_gakkai',
  },
  {
    value: 'english',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.english',
  },
  {
    value: 'none',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille.none',
  },
];

export const ITALY_DONATION_FIELDS_OTTO_X_MILLE: any = {
  state: '730/Scelte/Scelta8PerMille/Stato/Firma',
  catholic: '730/Scelte/Scelta8PerMille/ChiesaCattolica',
  '7th_day_adventist': '730/Scelte/Scelta8PerMille/UnioneChieseCristianeAvventiste7giorno',
  "god's_assemblees": '730/Scelte/Scelta8PerMille/AssembleeDiDioInItalia',
  valdese: '730/Scelte/Scelta8PerMille/UnioneDelleChieseMetodisteValdesi',
  lutheran: '730/Scelte/Scelta8PerMille/ChiesaEvangelicaLuteranaInItalia',
  jewish: '730/Scelte/Scelta8PerMille/UnioneComunitaEbraicheItaliane',
  orthodox: '730/Scelte/Scelta8PerMille/SacraArcidiocesiOrtodossaEsarcato',
  apostolic: '730/Scelte/Scelta8PerMille/ChiesaApostolicaItalia',
  baptist: '730/Scelte/Scelta8PerMille/UnioneCristianaEvangelicaBattista',
  buddhist: '730/Scelte/Scelta8PerMille/UnioneBuddhistaItaliana',
  hinduist: '730/Scelte/Scelta8PerMille/UnioneInduistaItaliana',
  soka_gakkai: '730/Scelte/Scelta8PerMille/IstitutoBuddistaIbisg',
  english: '730/Scelte/Scelta8PerMille/ChiesaInghilterraItalia',
  none: '730/Scelte/Scelta8PerMille/SceltaNonEffettuata',
};
export const ITALY_DONATION_FIELD_OTTO_X_MILLE_STATO_CODICE =
  '730/Scelte/Scelta8PerMille/Stato/Codice';
export const ITALY_DONATION_OPTIONS_OTTO_X_MILLE_AREA: DonationOption[] = [
  {
    value: 'fame_nel_mondo',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille_area.fame_nel_mondo',
  },
  {
    value: 'calamità',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille_area.calamità',
  },
  {
    value: 'edilizia_scolastica',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille_area.edilizia_scolastica',
  },
  {
    value: 'assistenza_ai_rifugiati',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille_area.assistenza_ai_rifugiati',
  },
  {
    value: 'beni_culturali',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille_area.beni_culturali',
  },
  {
    value: 'none',
    translationKey: 'it.digital-caf.donation-option.otto_x_mille_area.none',
  },
];

export const ITALY_DONATION_FIELDS_OTTO_X_MILLE_STATE_AREA: any = {
  fame_nel_mondo: 1,
  calamità: 2,
  edilizia_scolastica: 3,
  assistenza_ai_rifugiati: 4,
  beni_culturali: 5,
};

export const ITALY_DONATION_OPTIONS_CINQUE_X_MILLE: DonationOption[] = [
  {
    value: 'onlus',
    translationKey: 'it.digital-caf.donation-option.cinque_x_mille.onlus',
  },
  {
    value: 'scientific',
    translationKey: 'it.digital-caf.donation-option.cinque_x_mille.scientific',
  },
  {
    value: 'medical',
    translationKey: 'it.digital-caf.donation-option.cinque_x_mille.medical',
  },
  {
    value: 'cultural_environmental',
    translationKey: 'it.digital-caf.donation-option.cinque_x_mille.cultural_environmental',
  },
  {
    value: 'social_activities',
    translationKey: 'it.digital-caf.donation-option.cinque_x_mille.social_activities',
  },
  {
    value: 'sports_associations',
    translationKey: 'it.digital-caf.donation-option.cinque_x_mille.sports_associations',
  },
  {
    value: 'protected_areas',
    translationKey: 'it.digital-caf.donation-option.cinque_x_mille.protected_areas',
  },
  {
    value: 'none',
    translationKey: 'it.digital-caf.donation-option.cinque_x_mille.none',
  },
];

export const ITALY_DONATION_FIELDS_CINQUE_X_MILLE_FIRMA: any = {
  onlus: '730/Scelte/Scelta5PerMille/SostegnoVolontariatoNonLucrative/Firma',
  scientific: '730/Scelte/Scelta5PerMille/RicercaScientifica/Firma',
  medical: '730/Scelte/Scelta5PerMille/RicercaSanitaria/Firma',
  cultural_environmental: '730/Scelte/Scelta5PerMille/BeniCulturaliPaesaggistici/Firma',
  social_activities: '730/Scelte/Scelta5PerMille/SostegnoSocialeComune/Firma',
  sports_associations: '730/Scelte/Scelta5PerMille/SostegnoAssociazioniSportive/Firma',
  protected_areas: '730/Scelte/Scelta5PerMille/SostegnoEntiAreeProtette/Firma',
  none: '730/Scelte/Scelta5PerMille/SceltaNonEffetuata',
};

export const ITALY_DONATION_FIELDS_CINQUE_X_MILLE_CODICE_FISCALE: any = {
  onlus: '730/Scelte/Scelta5PerMille/SostegnoVolontariatoNonLucrative/CodiceFiscale',
  scientific: '730/Scelte/Scelta5PerMille/RicercaScientifica/CodiceFiscale',
  medical: '730/Scelte/Scelta5PerMille/RicercaSanitaria/CodiceFiscale',
  cultural_environmental: '730/Scelte/Scelta5PerMille/BeniCulturaliPaesaggistici/CodiceFiscale',
  social_activities: '730/Scelte/Scelta5PerMille/SostegnoSocialeComune/CodiceFiscale',
  sports_associations: '730/Scelte/Scelta5PerMille/SostegnoAssociazioniSportive/CodiceFiscale',
  protected_areas: '730/Scelte/Scelta5PerMille/SostegnoEntiAreeProtette/CodiceFiscale',
};

export const ITALY_DONATION_OPTIONS_DUE_X_MILLE: DonationOption[] = [
  {
    value: 'S48',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.S48',
  },
  {
    value: 'S56',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.S56',
  },
  {
    value: 'A10',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.A10',
  },
  {
    value: 'A52',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.A52',
  },
  {
    value: 'A41',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.A41',
  },
  {
    value: 'B30',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.B30',
  },
  {
    value: 'C12',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.C12',
  },
  {
    value: 'C31',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.C31',
  },
  {
    value: 'C46',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.C46',
  },
  {
    value: 'D13',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.D13',
  },
  {
    value: 'D43',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.D43',
  },
  {
    value: 'E14',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.E14',
  },
  {
    value: 'E54',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.E54',
  },
  {
    value: 'F15',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.F15',
  },
  {
    value: 'E50',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.E50',
  },
  {
    value: 'K18',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.K18',
  },
  {
    value: 'M20',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.M20',
  },
  {
    value: 'R22',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.R22',
  },
  {
    value: 'R45',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.R45',
  },
  {
    value: 'S36',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.S36',
  },
  {
    value: 'S47',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.S47',
  },
  {
    value: 'T44',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.T44',
  },
  {
    value: 'U37',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.U37',
  },
  {
    value: 'U55',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.U55',
  },
  {
    value: 'W26',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.W26',
  },
  {
    value: 'Y29',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.Y29',
  },
  {
    value: 'Z38',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.Z38',
  },
  {
    value: 'Y27',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.Y27',
  },
  {
    value: 'none',
    translationKey: 'it.digital-caf.donation-option.due_x_mille.none',
  },
];

export const ITALY_DONATION_FIELD_DUE_X_MILLE_NONE =
  '730/Scelte/Scelta2PerMille/SceltaNonEffettuata';
export const ITALY_DONATION_FIELD_DUE_X_MILLE_FIRMA = '730/Scelte/Scelta2PerMille/Firma';
export const ITALY_DONATION_FIELD_DUE_X_MILLE_CODICE = '730/Scelte/Scelta2PerMille/Codice';

export type DocumentTranslation = Record<
  string,
  {
    translationKey: string;
    leadTranslationKey?: string;
    documentListTranslationKeys?: string[];
    sections?: Array<DocumentUploadGuidanceSection>;
    headerTitleKey?: string;
    prefilledExpenseSubtitleKey?: string;
    questionConfig?: Array<{
      answerKey?: IncomeAnswersKeys | ExpenseAnswerKey;
      questionType: string;
    }>;
  }
>;

const traceabilitySection: DocumentUploadGuidanceSection = {
  title: 'it.digital-caf.tailored-documents.document.section.traceability.title',
  content: 'it.digital-caf.tailored-documents.document.section.traceability.content',
  icon: 'gearWheel',
};

const traceabilitySectionList: DocumentUploadGuidanceSection = {
  title: 'it.digital-caf.tailored-documents.document.section.traceability.title',
  content: 'it.digital-caf.tailored-documents.document.section.traceability.content.list',
  icon: 'gearWheel',
};

const bonificoSection: DocumentUploadGuidanceSection = {
  title: 'it.digital-caf.tailored-documents.document.section.bonifico.title',
  content: 'it.digital-caf.tailored-documents.document.section.bonifico.content',
  icon: 'gearWheel',
};

export const IncomeTranslationInfoForDocuments = {
  // this object is added to allow rendering of the prefilled CU document (from
  // CWBI) additionally to any CU that user uploads. TO be on the safe side I
  // made this object identical with the one in "DOCUMENT_INCOME_CU"
  [CWBIDocumentTypes.CU_PDF]: {
    translationKey: 'it.digital-caf.tailored-documents.document.income-cu.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.income-cu.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.income-cu.header.title',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.income-cu.employee.section.1.content',
      },
    ],
  },
  DOCUMENT_INCOME_CU: {
    translationKey: 'it.digital-caf.tailored-documents.document.income-cu.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.income-cu.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.income-cu.header.title',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.income-cu.employee.section.1.content',
      },
    ],
  },
  [CWBIDocumentTypes.VISURA_CATASTALE_ANALITICA_PDF]: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdOwnedGeneral.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdOwnedGeneral.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.homeHouseholdOwnedGeneral.header.title',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.homeHouseholdOwnedGeneral.section.1.content',
      },
    ],
  },
  DOCUMENT_INCOME_RESIDENCE_HOUSE_OWNED: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdOwnedGeneral.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdOwnedGeneral.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.homeHouseholdOwnedGeneral.header.title',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.homeHouseholdOwnedGeneral.section.1.content',
      },
    ],
  },
  DOCUMENT_INCOME_PROPERTIES_RENTED_OUT: {
    translationKey: 'it.digital-caf.tailored-documents.document.propertyRentedGeneral.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.propertyRentedGeneral.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.propertyRentedGeneral.header.title',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.propertyRentedGeneral.section.1.content',
      },
    ],
  },
  DOCUMENT_INCOME_OTHER_INCOME: {
    translationKey: 'it.digital-caf.tailored-documents.document.other-income.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.other-income.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.other-income.header.title',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.other-income.sections.content',
      },
    ],
    questionConfig: [
      {
        questionType: incomeSilentQuestions.cat_other_income,
      },
    ],
  },
};

export const ExpenseTranslationInfoForDocuments = {
  DOCUMENT_EXPENSE_FINANCES_PUBLIC_TRANSPORT: {
    translationKey: 'it.digital-caf.tailored-documents.document.transportation.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.transportation.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.transportation.header.title',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.transportation.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.transportation.section.1.title',
        content: 'it.digital-caf.tailored-documents.document.transportation.section.1.content',
        icon: 'bus',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        questionType: expenseSilentQuestions.cat_transportation,
      },
    ],
  },
  DOCUMENT_EXPENSE_HOME_RENT_GENERAL: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeRentGeneral.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeRentGeneral.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.homeRentGeneral.header.title',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.homeRentGeneral.section.1.content',
      },
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.rents_residence,
        questionType: inSeasonQFIds.living_situation,
      },
    ],
  },
  DOCUMENT_EXPENSE_FAMILY_SPORTING_ACTIVITIES: {
    translationKey: 'it.digital-caf.tailored-documents.document.sport_for_children.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.sport_for_children.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.sport_for_children.header.title',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.sport_for_children.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.sport_for_children.section.1.title',
        content: 'it.digital-caf.tailored-documents.document.sport_for_children.section.1.content',
        icon: 'bike',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        questionType: expenseSilentQuestions.cat_sport_for_children,
      },
    ],
  },
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_AGENCY_FEES: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdAgencyFees.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdAgencyFees.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.homeHouseholdAgencyFees.header.title',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.homeHouseholdAgencyFees.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.homeHouseholdAgencyFees.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.homeHouseholdAgencyFees.section.1.content',
        icon: 'houseParagraph',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        questionType: expenseSilentQuestions.cat_agency_fees,
      },
    ],
  },
  DOCUMENT_EXPENSE_MUSIC_BONUS: {
    translationKey: 'it.digital-caf.tailored-documents.document.music.bonus.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.music.bonus.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.music.bonus.header.title',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.music.bonus.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.music.bonus.section.1.title',
        content: 'it.digital-caf.tailored-documents.document.music.bonus.section.1.content',
        icon: 'organKeys',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        questionType: expenseSilentQuestions.cat_music_bonus,
      },
    ],
  },
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_SUPERBONUS: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdBonus.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdBonus.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.homeHouseholdBonus.header.title',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdBonus.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.homeHouseholdBonus.section.1.title',
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdBonus.section.1.content',
        icon: 'lightBulb',
      },
    ],
    questionConfig: [
      {
        questionType: expenseSilentQuestions.cat_superbonus,
      },
    ],
  },
  DOCUMENT_EXPENSE_FINANCES_EX_SPOUSE_ALIMONY: {
    translationKey: 'it.digital-caf.tailored-documents.document.alimony.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.alimony.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.alimony.header.title',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.alimony.section.0.content',
      },
      {
        content: 'it.digital-caf.tailored-documents.document.alimony.section.1.content',
      },
    ],
    questionConfig: [
      {
        questionType: expenseSilentQuestions.cat_alimony,
      },
    ],
  },
  DOCUMENT_EXPENSE_FINANCES_PENSIONS_UNIVERSITY_DEPENDANTS: {
    translationKey: 'it.digital-caf.tailored-documents.document.financesPensionsUniversity.name',
    leadTranslationKey:
      'it.digital-caf.tailored-documents.document.financesPensionsUniversity.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.financesPensionsUniversity.header.title',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.financesPensionsUniversity.section.1.content',
      },
      traceabilitySectionList,
    ],
    questionConfig: [
      {
        questionType: expenseSilentQuestions.cat_contributions_dependants,
      },
    ],
  },
  DOCUMENT_EXPENSE_PREPAYMENT_AND_CARRYOVER: {
    translationKey: 'it.digital-caf.tailored-documents.document.prepayment_and_carryover.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.prepayment_and_carryover.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.prepayment_and_carryover.header.title',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.prepayment_and_carryover.section.0.content',
      },
      {
        title:
          'it.digital-caf.tailored-documents.document.prepayment_and_carryover.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.prepayment_and_carryover.section.1.content',
        icon: 'bagMoney',
      },
    ],
    questionConfig: [
      {
        questionType: expenseSilentQuestions.cat_prepayment_and_carryover,
      },
    ],
  },
  DOCUMENT_EXPENSE_OTHER_DEDUCTIBLE: {
    translationKey: 'it.digital-caf.tailored-documents.document.other_deductible_costs.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.other_deductible_costs.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.other_deductible_costs.header.title',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.other_deductible_costs.section.0.content',
      },
      {
        content:
          'it.digital-caf.tailored-documents.document.other_deductible_costs.section.1.content',
      },
    ],
    questionConfig: [
      {
        questionType: expenseSilentQuestions.cat_other_deductible_costs,
      },
    ],
  },
  DOCUMENT_EXPENSE_FINANCES_TAX_CREDITS_GENERAL: {
    translationKey: 'it.digital-caf.tailored-documents.document.financesTaxCreditsGeneral.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.financesTaxCreditsGeneral.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.financesTaxCreditsGeneral.header.title',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.financesTaxCreditsGeneral.section.1.content',
      },
      {
        content:
          'it.digital-caf.tailored-documents.document.financesTaxCreditsGeneral.section.2.content',
      },
    ],
    questionConfig: [
      {
        questionType: expenseSilentQuestions.cat_tax_credit,
      },
    ],
  },
  DOCUMENT_EXPENSE_HEALTH_HEALTHCARE_GENERAL: {
    translationKey: 'it.digital-caf.tailored-documents.document.healthHealthcareGeneral.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.healthHealthcareGeneral.lead',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.medical',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.healthHealthcareGeneral.header.title',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.healthHealthcareGeneral.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.healthHealthcareGeneral.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.healthHealthcareGeneral.section.1.content',
        icon: 'firstAidBox',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        questionType: expenseListQuestions.cat_medical,
        answerKey: ExpenseAnswerKey.delete,
      },
      {
        questionType: expenseSilentQuestions.cat_medical,
      },
    ],
  },
  DOCUMENT_EXPENSE_FINANCES_DONATIONS_GENERAL: {
    translationKey: 'it.digital-caf.tailored-documents.document.financesDonationsGeneral.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.financesDonationsGeneral.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.financesDonationsGeneral.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.donations',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.financesDonationsGeneral.section.0.content',
      },
      {
        title:
          'it.digital-caf.tailored-documents.document.financesDonationsGeneral.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.financesDonationsGeneral.section.1.content',
        icon: 'handHeart',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_donations,
      },
      {
        questionType: expenseSilentQuestions.cat_donations,
      },
    ],
  },
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_GREEN: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdGreen.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdGreen.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.homeHouseholdGreen.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.green-bonus',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdGreen.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.homeHouseholdGreen.section.1.title',
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdGreen.section.1.content',
        icon: 'wateringCan',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_green_works,
      },
      {
        questionType: expenseSilentQuestions.cat_green_works,
      },
    ],
  },
  DOCUMENT_EXPENSE_FINANCES_CHARGING_STATIONS: {
    translationKey: 'it.digital-caf.tailored-documents.document.electric_vehicle_charging.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.electric_vehicle_charging.lead',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.electric-vehicles',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.electric_vehicle_charging.header.title',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.electric_vehicle_charging.section.0.content',
      },
      {
        title:
          'it.digital-caf.tailored-documents.document.electric_vehicle_charging.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.electric_vehicle_charging.section.1.content',
        icon: 'carTaxi',
      },
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_electric_vehicle_charging,
      },
      {
        questionType: expenseSilentQuestions.cat_electric_vehicle_charging,
      },
    ],
  },
  DOCUMENT_EXPENSE_FINANCES_INSURANCES_GENERAL: {
    translationKey: 'it.digital-caf.tailored-documents.document.financesInsurancesGeneral.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.financesInsurancesGeneral.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.financesInsurancesGeneral.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.insurance',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.financesInsurancesGeneral.section.0.content',
      },
      {
        title:
          'it.digital-caf.tailored-documents.document.financesInsurancesGeneral.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.financesInsurancesGeneral.section.1.content',
        icon: 'handShield',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_insurance,
      },
      {
        questionType: expenseSilentQuestions.cat_insurance,
      },
    ],
  },
  DOCUMENT_EXPENSE_FAMILY_FUNERAL: {
    translationKey: 'it.digital-caf.tailored-documents.document.funeral.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.funeral.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.funeral.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.funeral',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.funeral.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.funeral.section.1.title',
        content: 'it.digital-caf.tailored-documents.document.funeral.section.1.content',
        icon: 'coffin',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_funeral,
      },
      {
        questionType: expenseSilentQuestions.cat_funeral,
      },
    ],
  },
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_FACADE: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdFacade.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdFacade.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.homeHouseholdFacade.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.facades',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdFacade.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.homeHouseholdFacade.section.1.title',
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdFacade.section.1.content',
        icon: 'houseHome',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.homeHouseholdFacade.section.2.title',
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdFacade.section.2.content',
        icon: 'documentFile',
      },
      bonificoSection,
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_facade_bonus,
      },
      {
        questionType: expenseSilentQuestions.cat_facade_bonus,
      },
    ],
  },
  DOCUMENT_EXPENSE_CURRENT_HEALTH_HEALTHCARE_DISABLED: {
    translationKey:
      'it.digital-caf.tailored-documents.document.healthHealthcareDisabled_1.current.name',
    leadTranslationKey:
      'it.digital-caf.tailored-documents.document.healthHealthcareDisabled_1.current.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.healthHealthcareDisabled_1.current.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.disabled',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.healthHealthcareDisabled_1.current.section.0.content',
      },
      {
        title:
          'it.digital-caf.tailored-documents.document.healthHealthcareDisabled_1.current.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.healthHealthcareDisabled_1.current.section.1.content',
        icon: 'wheelchair',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_medical_disabled,
      },
      {
        questionType: expenseSilentQuestions.cat_medical_disabled,
      },
    ],
  },
  DOCUMENT_EXPENSE_HOME_MORTGAGES_GENERAL: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeMortgagesGeneral.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeMortgagesGeneral.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.homeMortgagesGeneral.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.loans',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.homeMortgagesGeneral.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.homeMortgagesGeneral.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.homeMortgagesGeneral.section.1.content',
        icon: 'handBarChart',
      },
      traceabilitySection,
    ],
    documentListTranslationKeys: [
      'it.digital-caf.tailored-documents.document.homeMortgagesGeneral.doc1',
      'it.digital-caf.tailored-documents.document.homeMortgagesGeneral.doc2',
      'it.digital-caf.tailored-documents.document.homeMortgagesGeneral.doc3',
      'it.digital-caf.tailored-documents.document.homeMortgagesGeneral.doc4',
      'it.digital-caf.tailored-documents.document.homeMortgagesGeneral.doc5',
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_mortgage,
      },
      {
        questionType: expenseSilentQuestions.cat_mortgage,
      },
    ],
  },
  DOCUMENT_EXPENSE_FAMILY_EDUCATION_DISABILITY_EQUIPMENT: {
    translationKey:
      'it.digital-caf.tailored-documents.document.familyEducationDisabilityEquipment.name',
    leadTranslationKey:
      'it.digital-caf.tailored-documents.document.familyEducationDisabilityEquipment.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.familyEducationDisabilityEquipment.header.title',
    prefilledExpenseSubtitleKey: 'it.documents-uploaded.prefilled-expenses-list.subtitle.open.dsa',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.familyEducationDisabilityEquipment.section.0.content',
      },
      {
        title:
          'it.digital-caf.tailored-documents.document.familyEducationDisabilityEquipment.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.familyEducationDisabilityEquipment.section.1.content',
        icon: 'book',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_compensatory_instruments,
      },
      {
        questionType: expenseSilentQuestions.cat_compensatory_instruments,
      },
    ],
  },
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_ENERGY: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdEnergy.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdEnergy.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.homeHouseholdEnergy.lead.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.energy',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdEnergy.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.homeHouseholdEnergy.section.1.title',
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdEnergy.section.1.content',
        icon: 'energy',
      },
      bonificoSection,
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_energy_efficiency_works,
      },
      {
        questionType: expenseSilentQuestions.cat_energy_efficiency_works,
      },
    ],
  },
  DOCUMENT_EXPENSE_INCOME_RETURNED_TO_EMPLOYER: {
    translationKey: 'it.digital-caf.tailored-documents.document.paid_back_to_employer.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.paid_back_to_employer.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.paid_back_to_employer.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.previously-taxed',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.paid_back_to_employer.section.0.content',
      },
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_paid_back_to_employer,
      },
      {
        questionType: expenseSilentQuestions.cat_paid_back_to_employer,
      },
    ],
  },
  DOCUMENT_EXPENSE_HEALTH_VETERINARY: {
    translationKey: 'it.digital-caf.tailored-documents.document.veterinary.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.veterinary.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.veterinary.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.veterinary',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.veterinary.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.veterinary.section.1.title',
        content: 'it.digital-caf.tailored-documents.document.veterinary.section.1.content',
        icon: 'cat',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_veterinary,
      },
      {
        questionType: expenseSilentQuestions.cat_veterinary,
      },
    ],
  },
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_RENOVATION: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdRenovation.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdRenovation.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.homeHouseholdRenovation.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.property',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.homeHouseholdRenovation.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.homeHouseholdRenovation.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.homeHouseholdRenovation.section.1.content',
        icon: 'paintRoller',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.homeHouseholdRenovation.section.2.title',
        content:
          'it.digital-caf.tailored-documents.document.homeHouseholdRenovation.section.2.content',
        icon: 'documentFile',
      },
      bonificoSection,
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_home,
      },
      {
        questionType: expenseSilentQuestions.cat_home,
      },
    ],
  },
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_FURNITURE: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdFurniture.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdFurniture.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.homeHouseholdFurniture.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.furnishing',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.homeHouseholdFurniture.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.homeHouseholdFurniture.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.homeHouseholdFurniture.section.1.content',
        icon: 'sofa',
      },
      traceabilitySection,
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_furniture_bonus,
      },
      {
        questionType: expenseSilentQuestions.cat_furniture_bonus,
      },
    ],
  },
  DOCUMENT_EXPENSE_FINANCES_PENSIONS_GENERAL: {
    translationKey: 'it.digital-caf.tailored-documents.document.financesPensionsGeneral.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.financesPensionsGeneral.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.financesPensionsGeneral.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.social',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.financesPensionsGeneral.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.financesPensionsGeneral.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.financesPensionsGeneral.section.1.content',
        icon: 'moneyHand',
      },
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_pension_contributions,
      },
      {
        questionType: expenseSilentQuestions.cat_pension_contributions,
      },
    ],
  },
  DOCUMENT_EXPENSE_EDUCATION_GENERAL: {
    translationKey: 'it.digital-caf.tailored-documents.document.familyEducationGeneral.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.familyEducationGeneral.lead',
    headerTitleKey:
      'it.digital-caf.tailored-documents.document.familyEducationGeneral.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.educational',
    sections: [
      {
        content:
          'it.digital-caf.tailored-documents.document.familyEducationGeneral.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.familyEducationGeneral.section.1.title',
        content:
          'it.digital-caf.tailored-documents.document.familyEducationGeneral.section.1.content',
        icon: 'appleBook',
      },
      traceabilitySection,
    ],
    documentListTranslationKeys: [
      'it.digital-caf.tailored-documents.document.familyEducationGeneral.doc1',
      'it.digital-caf.tailored-documents.document.familyEducationGeneral.doc2',
      'it.digital-caf.tailored-documents.document.familyEducationGeneral.doc3',
      'it.digital-caf.tailored-documents.document.familyEducationGeneral.doc4',
      'it.digital-caf.tailored-documents.document.familyEducationGeneral.doc5',
      'it.digital-caf.tailored-documents.document.familyEducationGeneral.doc6',
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_education,
      },
      {
        questionType: expenseSilentQuestions.cat_education,
      },
    ],
  },
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_SISMA: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdSisma.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdSisma.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.homeHouseholdSisma.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.earthquake',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdSisma.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.homeHouseholdSisma.section.1.title',
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdSisma.section.1.content',
        icon: 'documentFile',
      },
      bonificoSection,
    ],
    questionConfig: [
      {
        answerKey: ExpenseAnswerKey.delete,
        questionType: expenseListQuestions.cat_sisma,
      },
      {
        questionType: expenseSilentQuestions.cat_sisma,
      },
    ],
  },
  DOCUMENT_EXPENSE_HOME_HOUSEHOLD_HOUSE_A_B: {
    translationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdHouse.name',
    leadTranslationKey: 'it.digital-caf.tailored-documents.document.homeHouseholdHouse.lead',
    headerTitleKey: 'it.digital-caf.tailored-documents.document.homeHouseholdHouse.header.title',
    prefilledExpenseSubtitleKey:
      'it.documents-uploaded.prefilled-expenses-list.subtitle.open.classAB',
    sections: [
      {
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdHouse.section.0.content',
      },
      {
        title: 'it.digital-caf.tailored-documents.document.homeHouseholdHouse.section.1.title',
        content: 'it.digital-caf.tailored-documents.document.homeHouseholdHouse.section.1.content',
        icon: 'housePercent',
      },
    ],
    questionConfig: [
      {
        questionType: expenseListQuestions.cat_house_purchase,
        answerKey: ExpenseAnswerKey.delete,
      },
      {
        questionType: expenseSilentQuestions.cat_house_purchase,
      },
    ],
  },
};

export const translationInfoForDocuments: DocumentTranslation = {
  ...IncomeTranslationInfoForDocuments,
  ...ExpenseTranslationInfoForDocuments,

  // TODO: NOT CLEAR USAGE OF FOLLOWING DOCUMENTS
  DOCUMENT_FISCAL_CODE_DEPENDANT_50: {
    translationKey: 'it.digital-caf.required-documents.document.FiscalCodeDependant50.name',
  },
  DOCUMENT_FISCAL_CODE_DEPENDANT_100: {
    translationKey: 'it.digital-caf.required-documents.document.FiscalCodeDependant100.name',
  },
};
export const digitalCafDocsInfoTranslationKeys: any = {
  DOCUMENT_FISCAL_CODE_DEPENDANT_50:
    'it.digital-caf.required-documents.document.FiscalCodeDependant50.info',
  DOCUMENT_FISCAL_CODE_DEPENDANT_100:
    'it.digital-caf.required-documents.document.FiscalCodeDependant100.info',
};

export const IncomeDocumentsCategoryMapping: any = {
  cat_income_cu_employee: 'cat_income_cu_employee',
  cat_residence_house_owned: 'cat_residence_house_owned',
  cat_properties_rented: 'cat_properties_rented',
  cat_other_income: 'cat_other_income',
};

export const ExpenseDocumentsCategoryMapping: any = {
  cat_transportation: 'cat_transportation',
  cat_residence_house_rented: 'cat_residence_house_rented',
  cat_sport_for_children: 'cat_sport_for_children',
  cat_agency_fees: 'cat_agency_fees',
  cat_music_bonus: 'cat_music_bonus',
  cat_superbonus: 'cat_superbonus',
  cat_alimony: 'cat_alimony',
  cat_contributions_dependants: 'cat_contributions_dependants',
  cat_prepayment_and_carryover: 'cat_prepayment_and_carryover',
  cat_other_deductible_costs: 'cat_other_deductible_costs',
  cat_tax_credit: 'cat_tax_credit',
  cat_medical: 'cat_medical',
  cat_donations: 'cat_donations',
  cat_green_works: 'cat_green_works',
  cat_electric_vehicle_charging: 'cat_electric_vehicle_charging',
  cat_insurance: 'cat_insurance',
  cat_funeral: 'cat_funeral',
  cat_facade_bonus: 'cat_facade_bonus',
  cat_medical_disabled: 'cat_medical_disabled',
  cat_mortgage: 'cat_mortgage',
  cat_compensatory_instruments: 'cat_compensatory_instruments',
  cat_energy_efficiency_works: 'cat_energy_efficiency_works',
  cat_paid_back_to_employer: 'cat_paid_back_to_employer',
  cat_veterinary: 'cat_veterinary',
  cat_home: 'cat_home',
  cat_furniture_bonus: 'cat_furniture_bonus',
  cat_pension_contributions: 'cat_pension_contributions',
  cat_education: 'cat_education',
  cat_sisma: 'cat_sisma',
  cat_house_purchase: 'cat_house_purchase',
};

export const AllDocumentsCategoryMapping: any = {
  ...IncomeDocumentsCategoryMapping,
  ...ExpenseDocumentsCategoryMapping,
};
export const mapSilentQuestionCategories = (cat: string) => {
  const result = AllDocumentsCategoryMapping[cat];
  if (!result) return cat;
  return result;
};
export type DocumentGuidanceCategoryName = $Values<typeof AllDocumentsCategoryMapping>;

export const getTranslationKey = (
  map: DocumentTranslation,
  documentID: string,
  key: 'translationKey' | 'leadTranslationKey',
) => (map[documentID] && map[documentID][key] ? map[documentID][key] : 'translation_key_not_found');
export const ITALY_DONATION_PREFERENCE_DEFAULT_VALUE = 'none';
export const ITALY_DONATION_PREFERENCE_STATE_VALUE = 'state';
export const ITALY_DONATION_NON_PROFIT_WITHOUT_CODE = [
  ITALY_DONATION_PREFERENCE_DEFAULT_VALUE,
  'social_activities',
];
export const ITALY_DONATION_FIELD_PREFIX = '730/Scelte/';
export const translationInfoForCUFields: Record<string, string> = {
  employment_income: 'answers.cu-employment.fields.employment_income',
  number_of_days: 'answers.cu-employment.fields.number_of_days',
  type_of_contract: 'answers.cu-employment.fields.type_of_contract',
  withholding_irpef: 'answers.cu-employment.fields.withholding_irpef',
  withholding_prep_municipal: 'answers.cu-employment.fields.withholding_prep_municipal',
  bonus_code: 'answers.cu-employment.fields.bonus_code',
  amount_performance_bonus_ord_tax: 'answers.cu-employment.fields.amount_performance_bonus_ord_tax',
  amount_performance_bonus_subst_tax:
    'answers.cu-employment.fields.amount_performance_bonus_subst_tax',
  withholding_substitute_tax: 'answers.cu-employment.fields.withholding_substitute_tax',
  amount_benefit: 'answers.cu-employment.fields.amount_benefit',
  amount_benefit_ord_tax: 'answers.cu-employment.fields.amount_benefit_ord_tax',
  withholding_regional: 'answers.cu-employment.fields.withholding_regional',
  withholding_prep_municipal_tax_year:
    'answers.cu-employment.fields.withholding_prep_municipal_tax_year',
  withholding_balance_municipal_tax_year:
    'answers.cu-employment.fields.withholding_balance_municipal_tax_year',
  withholding_prep_municipal_current_year:
    'answers.cu-employment.fields.withholding_prep_municipal_current_year',
  first_prepayment_IRPEF: 'answers.cu-employment.fields.first_prepayment_IRPEF',
  second_prepayment_IRPEF: 'answers.cu-employment.fields.second_prepayment_IRPEF',
  amount_bonus_irpef_is_paid: 'answers.cu-employment.fields.amount_bonus_irpef_is_paid',
  bonus_irpef_code: 'answers.cu-employment.fields.bonus_irpef_code',
  bonus_irpef_exemption: 'answers.cu-employment.fields.bonus_irpef_exemption',
  amount_bonus_irpef_paid: 'answers.cu-employment.fields.amount_bonus_irpef_paid',
  comparto_sicurezza_fruita_ordinaria:
    'answers.cu-employment.fields.comparto_sicurezza_fruita_ordinaria',
  comparto_sicurezza_non_fruita_ordinaria:
    'answers.cu-employment.fields.comparto_sicurezza_non_fruita_ordinaria',
  comparto_sicurezza_fruita_separata:
    'answers.cu-employment.fields.comparto_sicurezza_fruita_separata',
  other_data_income: 'answers.cu-employment.fields.other_data_income',
};
// Sorted in asc order
export const getOrderedStreetTypes = () => [
  'strada intercomunale',
  'strada interpoderale',
  'strada accorciatoia',
  'strada carrareccia',
  'strada consorziale',
  'strada di bonifica',
  'strada perimetrale',
  'strada provinciale',
  'strada traversante',
  'passaggio privato',
  'piazzetta privata',
  'strada cantoniera',
  'strada consortile',
  'strada panoramica',
  'via intercomunale',
  'circonvallazione',
  'circumvallazione',
  'lungo Castellano',
  'piazza inferiore',
  'salita inferiore',
  'salita superiore',
  'strada consolare',
  'strada inferiore',
  'strada litoranea',
  'strada nazionale',
  'strada regionale',
  'traversa privata',
  'via accorciatoia',
  'vico estramurale',
  'cortile privato',
  'passo di piazza',
  'strada arginale',
  'strada militare',
  'strada pedonale',
  'strada poderale',
  'strada rotabile',
  'strada vicinale',
  'via estramurale',
  'via provinciale',
  'via trasversale',
  'viale lungomare',
  'vicoletto cieco',
  'capo di piazza',
  'murazzi del Po',
  'piazza privata',
  'strada esterna',
  'strada interna',
  'strada privata',
  'vico inferiore',
  'vico superiore',
  'calle stretta',
  'cupa vicinale',
  'lungotorrente',
  'prolungamento',
  'rampa privata',
  'strada alzaia',
  'strada antica',
  'strada rurale',
  'via circolare',
  'via consolare',
  'via inferiore',
  'via lungomare',
  'via nazionale',
  'via regionale',
  'via superiore',
  'viale privato',
  'vicolo chiuso',
  'lungobisagno',
  'lungo Brenta',
  'lungobusento',
  'lungofrigido',
  'lungotalvera',
  'sottoportico',
  'strada bassa',
  'stradoncello',
  'via arginale',
  'via comunale',
  'via laterale',
  'via militare',
  'via pedonale',
  'via rotabile',
  'via sinistra',
  'via vicinale',
  'vicolo cieco',
  'vico privato',
  'vico stretto',
  'calle larga',
  'calle lunga',
  'case sparse',
  'diramazione',
  'estramurale',
  'lungoargine',
  'lungocalore',
  'lungocanale',
  'lungocurone',
  'lungofoglia',
  'lungoisarco',
  'lungoisonzo',
  'lungomazaro',
  'lungosabato',
  'lungotanaro',
  'lungotevere',
  'lungoticino',
  'lungotronto',
  'lungovelino',
  'passeggiata',
  'sotoportego',
  'trasversale',
  'via al mare',
  'via interna',
  'via privata',
  'via stretta',
  'via vecchia',
  'vico storto',
  'archivolto',
  'callesèlla',
  'callesèllo',
  'cavalcavia',
  'chiassetto',
  'chiassuola',
  'complanare',
  'corticella',
  'crocicchio',
  'fondamenta',
  'lungocrati',
  'lungofiume',
  'lungogesso',
  'lungolario',
  'lungolinea',
  'lungoparco',
  'lungoporto',
  'lungostura',
  'sdrucciolo',
  'sopportico',
  'supportico',
  'traversale',
  'via alzaia',
  'via antica',
  'via destra',
  'via rurale',
  'vico lungo',
  'vico nuovo',
  'vico rotto',
  'angiporto',
  'belvedere',
  'borgoloco',
  'campiello',
  'carrarone',
  'cortesela',
  'gradinata',
  'granviale',
  'larghetto',
  'litoranea',
  'lungadige',
  'lungoadda',
  'lungodora',
  'lungolago',
  'lungoliri',
  'lungomare',
  'lungomolo',
  'lungonera',
  'lungosile',
  'parallela',
  'passaggio',
  'passeggio',
  'penninata',
  'piazzetta',
  'rettifilo',
  'scalinata',
  'stradella',
  'stradello',
  'stradetta',
  'strettoia',
  'strettola',
  'via bassa',
  'via nuova',
  'vicoletto',
  'villaggio',
  'baluardo',
  'banchina',
  'barbarìa',
  'bastione',
  'bastioni',
  'callétta',
  'claustro',
  'contrada',
  'corsetto',
  'distacco',
  'emiciclo',
  'frazione',
  'galleria',
  'laterale',
  'località',
  'lungarno',
  'lungo Po',
  'masseria',
  'merceria',
  'piazzale',
  'quadrato',
  'rio terà',
  'salizada',
  'scalette',
  'selciato',
  'sentiero',
  'spiaggia',
  'spianata',
  'stradale',
  'stradone',
  'terrazza',
  'traversa',
  'tratturo',
  'trazzera',
  'tresanda',
  'via alta',
  'via cupa',
  'via erta',
  'vialetto',
  'vicinale',
  'viottolo',
  'vocabolo',
  'accesso',
  'androna',
  'borgata',
  'cantone',
  'carraia',
  'carrara',
  'cascina',
  'chiasso',
  'cortile',
  'discesa',
  'fondaco',
  'fossato',
  'gradini',
  'gradoni',
  'pendice',
  'pendino',
  'piaggia',
  'piscina',
  'portico',
  'rampari',
  'recinto',
  'regione',
  'regaste',
  'riviera',
  'rotonda',
  'rugheta',
  'sagrato',
  'scalone',
  'spiazzo',
  'stretta',
  'stretto',
  'vanella',
  'vallone',
  'vialone',
  'voltone',
  'alinea',
  'alzaia',
  'argine',
  'bacino',
  'banchi',
  'calata',
  'canale',
  'cavone',
  'clivio',
  'contrà',
  'corsia',
  'pendio',
  'piazza',
  'raggio',
  'riello',
  'salita',
  'scalea',
  'slargo',
  'spalto',
  'strada',
  'tronco',
  'vicolo',
  'viella',
  'vietta',
  'viuzza',
  'viuzzo',
  'allea',
  'arena',
  'bivio',
  'borgo',
  'calle',
  'campo',
  'carro',
  'clivo',
  'corso',
  'corte',
  'costa',
  'crosa',
  'fondo',
  'fossa',
  'isola',
  'largo',
  'lista',
  'lungo',
  'passo',
  'ponte',
  'porto',
  'prato',
  'rampa',
  'rampe',
  'ratto',
  'rione',
  'rondò',
  'sacca',
  'scesa',
  'svoto',
  'tondo',
  'viale',
  'volti',
  'arco',
  'cupa',
  'erta',
  'lido',
  'molo',
  'mura',
  'ramo',
  'ripa',
  'riva',
  'ruga',
  'saia',
  'vial',
  'vico',
  'cal',
  'rio',
  'rua',
  'via',
];
export type DigitalCafDocument = $Keys<typeof translationInfoForDocuments>;
export type DigitalCafSubCategory = {
  key: DocumentGuidanceCategoryName;
  items: DigitalCafDocument[];
};
export type DigitalCafCategory = {
  key: DocumentGuidanceCategoryName;
  items: Array<DigitalCafSubCategory | DigitalCafDocument>;
};
export const digitalCafDocuments: DigitalCafDocument[] = Object.keys(translationInfoForDocuments);
export const mandatoryDocuments = [
  'DOCUMENT_INCOME_CU',
  'DOCUMENT_INCOME_RESIDENCE_HOUSE_OWNED',
  'DOCUMENT_INCOME_PROPERTIES_RENTED_OUT',
  'DOCUMENT_INCOME_OTHER_INCOME',
];

export const incomeDocumentCategories = [
  'cat_income_cu_employee',
  'cat_residence_house_owned',
  'cat_properties_rented',
  'cat_other_income',
];
export const expenseDocumentCategories = [
  'cat_transportation',
  'cat_residence_house_rented',
  'cat_sport_for_children',
  'cat_agency_fees',
  'cat_music_bonus',
  'cat_superbonus',
  'cat_alimony',
  'cat_contributions_dependants',
  'cat_prepayment_and_carryover',
  'cat_other_deductible_costs',
  'cat_tax_credit',
  'cat_medical',
  'cat_donations',
  'cat_green_works',
  'cat_electric_vehicle_charging',
  'cat_insurance',
  'cat_funeral',
  'cat_facade_bonus',
  'cat_medical_disabled',
  'cat_mortgage',
  'cat_compensatory_instruments',
  'cat_energy_efficiency_works',
  'cat_paid_back_to_employer',
  'cat_veterinary',
  'cat_home',
  'cat_furniture_bonus',
  'cat_pension_contributions',
  'cat_education',
  'cat_sisma',
  'cat_house_purchase',
];

export const socialLinks = [
  {
    url: 'https://www.facebook.com/taxfix.it',
    iconName: 'social.facebook',
    linkName: 'facebook',
  },
  {
    url: 'https://www.youtube.com/channel/UCYQu6kOLCZTOKj5aIsqY6Og',
    iconName: 'social.youtube',
    linkName: 'youtube',
  },
  {
    url: 'https://www.instagram.com/taxfix.it/',
    iconName: 'social.instagram',
    linkName: 'instagram',
  },
  {
    url: 'https://www.linkedin.com/company/taxfix/mycompany/',
    iconName: 'social.linkedin',
    linkName: 'linkedin',
  },
];
export const externalLinks = {
  imprint: 'http://taxfix.it/index.php/impronta/',
  privacyPolicy:
    'https://taxfix.it/wp-content/uploads/2021/07/TAXFIX_Informativa-sulla-Privacy_June2021.pdf',
};
export type DocumentsGuidanceSubCategory = {
  key: DocumentGuidanceCategoryName;
  items: DigitalCafDocument[];
};

export const documentsCategories: Array<
  DocumentsGuidanceSubCategory | DocumentGuidanceCategoryName
> = [
  // INCOME
  {
    key: 'cat_income_cu_employee',
    items: ['DOCUMENT_INCOME_CU', CWBIDocumentTypes.CU_PDF],
  },
  {
    key: 'cat_residence_house_owned',
    items: [
      'DOCUMENT_INCOME_RESIDENCE_HOUSE_OWNED',
      CWBIDocumentTypes.VISURA_CATASTALE_ANALITICA_PDF,
    ],
  },
  {
    key: 'cat_properties_rented',
    items: ['DOCUMENT_INCOME_PROPERTIES_RENTED_OUT'],
  },
  {
    key: 'cat_other_income',
    items: ['DOCUMENT_INCOME_OTHER_INCOME'],
  },
  //
  // EXPENSE
  //
  {
    key: 'cat_transportation',
    items: ['DOCUMENT_EXPENSE_FINANCES_PUBLIC_TRANSPORT'],
  },
  {
    key: 'cat_residence_house_rented',
    items: ['DOCUMENT_EXPENSE_HOME_RENT_GENERAL'],
  },
  {
    key: 'cat_sport_for_children',
    items: ['DOCUMENT_EXPENSE_FAMILY_SPORTING_ACTIVITIES'],
  },
  {
    key: 'cat_agency_fees',
    items: ['DOCUMENT_EXPENSE_HOME_HOUSEHOLD_AGENCY_FEES'],
  },
  {
    key: 'cat_music_bonus',
    items: ['DOCUMENT_EXPENSE_MUSIC_BONUS'],
  },
  {
    key: 'cat_superbonus',
    items: ['DOCUMENT_EXPENSE_HOME_HOUSEHOLD_SUPERBONUS'],
  },
  {
    key: 'cat_alimony',
    items: ['DOCUMENT_EXPENSE_FINANCES_EX_SPOUSE_ALIMONY'],
  },
  {
    key: 'cat_contributions_dependants',
    items: ['DOCUMENT_EXPENSE_FINANCES_PENSIONS_UNIVERSITY_DEPENDANTS'],
  },
  {
    key: 'cat_prepayment_and_carryover',
    items: ['DOCUMENT_EXPENSE_PREPAYMENT_AND_CARRYOVER'],
  },
  {
    key: 'cat_other_deductible_costs',
    items: ['DOCUMENT_EXPENSE_OTHER_DEDUCTIBLE'],
  },
  {
    key: 'cat_tax_credit',
    items: ['DOCUMENT_EXPENSE_FINANCES_TAX_CREDITS_GENERAL'],
  },
  {
    key: 'cat_medical',
    items: ['DOCUMENT_EXPENSE_HEALTH_HEALTHCARE_GENERAL'],
  },
  {
    key: 'cat_donations',
    items: ['DOCUMENT_EXPENSE_FINANCES_DONATIONS_GENERAL'],
  },
  {
    key: 'cat_green_works',
    items: ['DOCUMENT_EXPENSE_HOME_HOUSEHOLD_GREEN'],
  },
  {
    key: 'cat_electric_vehicle_charging',
    items: ['DOCUMENT_EXPENSE_FINANCES_CHARGING_STATIONS'],
  },
  {
    key: 'cat_insurance',
    items: ['DOCUMENT_EXPENSE_FINANCES_INSURANCES_GENERAL'],
  },
  {
    key: 'cat_funeral',
    items: ['DOCUMENT_EXPENSE_FAMILY_FUNERAL'],
  },
  {
    key: 'cat_facade_bonus',
    items: ['DOCUMENT_EXPENSE_HOME_HOUSEHOLD_FACADE'],
  },
  {
    key: 'cat_medical_disabled',
    items: ['DOCUMENT_EXPENSE_CURRENT_HEALTH_HEALTHCARE_DISABLED'],
  },
  {
    key: 'cat_mortgage',
    items: ['DOCUMENT_EXPENSE_HOME_MORTGAGES_GENERAL'],
  },
  {
    key: 'cat_compensatory_instruments',
    items: ['DOCUMENT_EXPENSE_FAMILY_EDUCATION_DISABILITY_EQUIPMENT'],
  },
  {
    key: 'cat_energy_efficiency_works',
    items: ['DOCUMENT_EXPENSE_HOME_HOUSEHOLD_ENERGY'],
  },
  {
    key: 'cat_paid_back_to_employer',
    items: ['DOCUMENT_EXPENSE_INCOME_RETURNED_TO_EMPLOYER'],
  },
  {
    key: 'cat_veterinary',
    items: ['DOCUMENT_EXPENSE_HEALTH_VETERINARY'],
  },
  {
    key: 'cat_home',
    items: ['DOCUMENT_EXPENSE_HOME_HOUSEHOLD_RENOVATION'],
  },
  {
    key: 'cat_furniture_bonus',
    items: ['DOCUMENT_EXPENSE_HOME_HOUSEHOLD_FURNITURE'],
  },
  {
    key: 'cat_pension_contributions',
    items: ['DOCUMENT_EXPENSE_FINANCES_PENSIONS_GENERAL'],
  },
  {
    key: 'cat_education',
    items: ['DOCUMENT_EXPENSE_EDUCATION_GENERAL'],
  },
  {
    key: 'cat_sisma',
    items: ['DOCUMENT_EXPENSE_HOME_HOUSEHOLD_SISMA'],
  },
  {
    key: 'cat_house_purchase',
    items: ['DOCUMENT_EXPENSE_HOME_HOUSEHOLD_HOUSE_A_B'],
  },
];
export const occupationInfoLink =
  'https://taxfix.it/faq-domande-frequenti/chi-puo-utilizzare-taxfix-2/';
export const appStoreLinks = {
  apple:
    'https://xygh.adj.st/?adjust_engagement_type=fallback_click&adjust_t=fw7lml6&adjust_campaign=mweb_document_link',
  google:
    'https://xygh.adj.st/?adjust_engagement_type=fallback_click&adjust_t=54ycelr&adjust_campaign=mweb_document_link',
};
export const IT_POSTAL_CODE_LENGTH = 5;
export const provinceList = [
  {
    code: 'AG',
    translationKey: 'it.state.AG',
  },
  {
    code: 'AL',
    translationKey: 'it.state.AL',
  },
  {
    code: 'AN',
    translationKey: 'it.state.AN',
  },
  {
    code: 'AR',
    translationKey: 'it.state.AR',
  },
  {
    code: 'AP',
    translationKey: 'it.state.AP',
  },
  {
    code: 'AT',
    translationKey: 'it.state.AT',
  },
  {
    code: 'AV',
    translationKey: 'it.state.AV',
  },
  {
    code: 'BA',
    translationKey: 'it.state.BA',
  },
  {
    code: 'BT',
    translationKey: 'it.state.BT',
  },
  {
    code: 'BL',
    translationKey: 'it.state.BL',
  },
  {
    code: 'BN',
    translationKey: 'it.state.BN',
  },
  {
    code: 'BG',
    translationKey: 'it.state.BG',
  },
  {
    code: 'BI',
    translationKey: 'it.state.BI',
  },
  {
    code: 'BO',
    translationKey: 'it.state.BO',
  },
  {
    code: 'BZ',
    translationKey: 'it.state.BZ',
  },
  {
    code: 'BS',
    translationKey: 'it.state.BS',
  },
  {
    code: 'BR',
    translationKey: 'it.state.BR',
  },
  {
    code: 'CA',
    translationKey: 'it.state.CA',
  },
  {
    code: 'CL',
    translationKey: 'it.state.CL',
  },
  {
    code: 'CB',
    translationKey: 'it.state.CB',
  },
  {
    code: 'CE',
    translationKey: 'it.state.CE',
  },
  {
    code: 'CT',
    translationKey: 'it.state.CT',
  },
  {
    code: 'CZ',
    translationKey: 'it.state.CZ',
  },
  {
    code: 'CH',
    translationKey: 'it.state.CH',
  },
  {
    code: 'CO',
    translationKey: 'it.state.CO',
  },
  {
    code: 'CS',
    translationKey: 'it.state.CS',
  },
  {
    code: 'CR',
    translationKey: 'it.state.CR',
  },
  {
    code: 'KR',
    translationKey: 'it.state.KR',
  },
  {
    code: 'CN',
    translationKey: 'it.state.CN',
  },
  {
    code: 'EN',
    translationKey: 'it.state.EN',
  },
  {
    code: 'FM',
    translationKey: 'it.state.FM',
  },
  {
    code: 'FE',
    translationKey: 'it.state.FE',
  },
  {
    code: 'FI',
    translationKey: 'it.state.FI',
  },
  {
    code: 'FG',
    translationKey: 'it.state.FG',
  },
  {
    code: 'FC',
    translationKey: 'it.state.FC',
  },
  {
    code: 'FR',
    translationKey: 'it.state.FR',
  },
  {
    code: 'GE',
    translationKey: 'it.state.GE',
  },
  {
    code: 'GO',
    translationKey: 'it.state.GO',
  },
  {
    code: 'GR',
    translationKey: 'it.state.GR',
  },
  {
    code: 'IM',
    translationKey: 'it.state.IM',
  },
  {
    code: 'IS',
    translationKey: 'it.state.IS',
  },
  {
    code: 'AQ',
    translationKey: 'it.state.AQ',
  },
  {
    code: 'SP',
    translationKey: 'it.state.SP',
  },
  {
    code: 'LT',
    translationKey: 'it.state.LT',
  },
  {
    code: 'LE',
    translationKey: 'it.state.LE',
  },
  {
    code: 'LC',
    translationKey: 'it.state.LC',
  },
  {
    code: 'LI',
    translationKey: 'it.state.LI',
  },
  {
    code: 'LO',
    translationKey: 'it.state.LO',
  },
  {
    code: 'LU',
    translationKey: 'it.state.LU',
  },
  {
    code: 'MC',
    translationKey: 'it.state.MC',
  },
  {
    code: 'MN',
    translationKey: 'it.state.MN',
  },
  {
    code: 'MS',
    translationKey: 'it.state.MS',
  },
  {
    code: 'MT',
    translationKey: 'it.state.MT',
  },
  {
    code: 'ME',
    translationKey: 'it.state.ME',
  },
  {
    code: 'MI',
    translationKey: 'it.state.MI',
  },
  {
    code: 'MO',
    translationKey: 'it.state.MO',
  },
  {
    code: 'MB',
    translationKey: 'it.state.MB',
  },
  {
    code: 'NA',
    translationKey: 'it.state.NA',
  },
  {
    code: 'NO',
    translationKey: 'it.state.NO',
  },
  {
    code: 'NU',
    translationKey: 'it.state.NU',
  },
  {
    code: 'OR',
    translationKey: 'it.state.OR',
  },
  {
    code: 'PD',
    translationKey: 'it.state.PD',
  },
  {
    code: 'PA',
    translationKey: 'it.state.PA',
  },
  {
    code: 'PR',
    translationKey: 'it.state.PR',
  },
  {
    code: 'PV',
    translationKey: 'it.state.PV',
  },
  {
    code: 'PG',
    translationKey: 'it.state.PG',
  },
  {
    code: 'PU',
    translationKey: 'it.state.PU',
  },
  {
    code: 'PE',
    translationKey: 'it.state.PE',
  },
  {
    code: 'PC',
    translationKey: 'it.state.PC',
  },
  {
    code: 'PI',
    translationKey: 'it.state.PI',
  },
  {
    code: 'PT',
    translationKey: 'it.state.PT',
  },
  {
    code: 'PN',
    translationKey: 'it.state.PN',
  },
  {
    code: 'PZ',
    translationKey: 'it.state.PZ',
  },
  {
    code: 'PO',
    translationKey: 'it.state.PO',
  },
  {
    code: 'RG',
    translationKey: 'it.state.RG',
  },
  {
    code: 'RA',
    translationKey: 'it.state.RA',
  },
  {
    code: 'RC',
    translationKey: 'it.state.RC',
  },
  {
    code: 'RE',
    translationKey: 'it.state.RE',
  },
  {
    code: 'RI',
    translationKey: 'it.state.RI',
  },
  {
    code: 'RN',
    translationKey: 'it.state.RN',
  },
  {
    code: 'RM',
    translationKey: 'it.state.RM',
  },
  {
    code: 'RO',
    translationKey: 'it.state.RO',
  },
  {
    code: 'SA',
    translationKey: 'it.state.SA',
  },
  {
    code: 'SS',
    translationKey: 'it.state.SS',
  },
  {
    code: 'SV',
    translationKey: 'it.state.SV',
  },
  {
    code: 'SI',
    translationKey: 'it.state.SI',
  },
  {
    code: 'SR',
    translationKey: 'it.state.SR',
  },
  {
    code: 'SO',
    translationKey: 'it.state.SO',
  },
  {
    code: 'SU',
    translationKey: 'it.state.SU',
  },
  {
    code: 'TA',
    translationKey: 'it.state.TA',
  },
  {
    code: 'TE',
    translationKey: 'it.state.TE',
  },
  {
    code: 'TR',
    translationKey: 'it.state.TR',
  },
  {
    code: 'TO',
    translationKey: 'it.state.TO',
  },
  {
    code: 'TP',
    translationKey: 'it.state.TP',
  },
  {
    code: 'TN',
    translationKey: 'it.state.TN',
  },
  {
    code: 'TV',
    translationKey: 'it.state.TV',
  },
  {
    code: 'TS',
    translationKey: 'it.state.TS',
  },
  {
    code: 'UD',
    translationKey: 'it.state.UD',
  },
  {
    code: 'AO',
    translationKey: 'it.state.AO',
  },
  {
    code: 'VA',
    translationKey: 'it.state.VA',
  },
  {
    code: 'VE',
    translationKey: 'it.state.VE',
  },
  {
    code: 'VB',
    translationKey: 'it.state.VB',
  },
  {
    code: 'VC',
    translationKey: 'it.state.VC',
  },
  {
    code: 'VR',
    translationKey: 'it.state.VR',
  },
  {
    code: 'VV',
    translationKey: 'it.state.VV',
  },
  {
    code: 'VI',
    translationKey: 'it.state.VI',
  },
  {
    code: 'VT',
    translationKey: 'it.state.VT',
  },
];

export const ACCOUNT_CLOSURE_BANNER_TIMEOUT = 7000;
export enum ERROR_CODES {
  FILE_SIZE_EXCEEDED = 'FILE_SIZE_EXCEEDED',
}

export enum RESULT_SCREEN_EXPERIMENT_VARIANTS {
  Control = 'Control',
  Variant = 'Variant',
}

export enum TailoredExperienceStatus {
  Skipped = 'Skipped',
  Started = 'Started',
  FinishedQF = 'FinishedQF',
  Completed = 'Completed',
}

export const YT_TUTORIAL_URL = 'https://www.youtube.com/embed/pwI5GjKTuZE';

interface Experiment {
  experimentKey: string;
  variants: string[];
  defaultValue: string;
  targetAttributes: Record<string, string>;
}

interface EppoExperiments {
  [key: string]: Experiment;
}

export const eppoExperiment: EppoExperiments = {
  resultScreen: {
    experimentKey: 'it-mob-web-result-screen',
    variants: [
      RESULT_SCREEN_EXPERIMENT_VARIANTS.Control,
      RESULT_SCREEN_EXPERIMENT_VARIANTS.Variant,
    ],
    defaultValue: RESULT_SCREEN_EXPERIMENT_VARIANTS.Control,
    targetAttributes: {
      country_code: CountryCodes.IT,
    },
  },
};
