import React from 'react';

import {
  TailoredExperienceIntro,
  toTailoredExperienceIntro,
} from 'src/screens/tailored-experience-intro/tailored-experience-intro-screen';
import {
  TailoredExperienceFinalScreen,
  toTailoredExperienceFinal,
} from 'src/screens/tailored-experience-final/tailored-experience-final-screen';
import {
  downloadAppScreen,
  toDownloadAppScreen,
} from 'src/screens/download-app/download-app-route';
import { toTaxExpert, TaxExpert } from 'src/_italy/tax-expert';
import { toTaxGuide, TaxGuide } from 'src/_italy/tax-guide/tax-guide-screen';
import { ProductBundle, toProductBundle } from 'src/_italy/product-bundle/product-bundle-screen';
import {
  ProductBundleDetails,
  toProductBundleDetails,
} from 'src/screens/product-bundle-details/product-bundle-details-screen';
import { toSelfSignDocument, SelfSignDocument } from 'src/_italy/self-sign-document';
import { ItalyChat, toItalyChat } from 'src/_italy/chat/italy-chat-screen';
import { CountryRoutesConfigType, ScreenType } from 'src/routes/navigate';
import { Routes } from 'src/routes/routes';
import { ScreenName } from 'src/types/screen-name';
import PrivacySettingsScreenIT, {
  toPrivacySettingsIT,
} from 'src/_italy/privacy-setting/screen/privacy-settings';
import {
  toDeleteAccountIT,
  DeleteAccountScreenIT,
} from 'src/_italy/delete-account-setting/screen/delete-account';
import {
  toCloseAccountIT,
  CloseAccountScreenIT,
} from 'src/screens/close-account/close-account-route';
import { IdCardUpload, toIdCardUpload } from 'src/_italy/id-document-upload/id-card/screen';
import { PassportUpload, toPassportUpload } from 'src/_italy/id-document-upload/passport/screen';
import {
  DrivingLicenseUpload,
  toDrivingLicenseUpload,
} from 'src/_italy/id-document-upload/driving-license/screen';
import {
  PrefillDocumentUploadRoute,
  toPrefillDocumentUpload,
} from 'src/_italy/id-document-upload/main/screen';
import {
  SPIDConsentScreen,
  toSPIDConsentScreen,
} from 'src/_italy/prefill-experiment/spid-consent-screen';
import { ScreenOptions } from 'src/routes/stack-navigator-shared.web';
import {
  IFrameSPIDLoginScreen,
  toiFrameSPIDLoginScreen,
} from 'src/_italy/iframe-spid-login/iframe-spid-login-route';
import {
  InstantFilingNextStepsScreenRoute,
  toInstantFilingNextStepsScreen,
} from 'src/_italy/instant-filing-next-steps/screen';
import {
  PrefillDocumentsResult,
  toPrefillDocumentsResult,
} from 'src/_italy/prefill-documents-result/prefill-documents-result-screen';
import {
  ModelloRedditiSignScreen,
  toModelloRedditiSign,
} from 'src/_italy/modello-redditi-sign/modello-redditi-sign-screen';
import {
  CUDocumentResultScreen,
  toCUDocumentResultScreen,
} from 'src/screens/cu-documents-result/cu-document-result-route';
import {
  toSPIDConsentSign,
  SPIDConsentSign,
} from 'src/screens/spid-consent-sign/spid-consent-sign-route';
import { toDebugCWBIUrl, DebugCWBIUrl } from 'src/screens/debug-cwbi-url/debug-cwbi-url-route';
import {
  ResultOptimization,
  toResultOptimizationScreen,
} from 'src/_italy/prefill-documents-result/result-optimization/result-optimization-screen';

import { SpidTimeout, toSpidTimeout } from '../../../screens/spid-timeout/spid-timeout-container';
import AccountScreen, { accountScreen } from '../../../screens/account-menu/account-route';
import {
  navigationActions as commonNavigationActions,
  screens as commonScreens,
} from '../config-common';
import DebugResponses, {
  navigateTo as toDebugResponses,
} from '../../../screens/debug-responses/debug-responses-route';
import ImportResponses, {
  navigateTo as toImportResponses,
} from '../../../screens/import-responses/import-responses-route';
import InformationScreen, {
  navigateTo as toInformation,
} from '../../../screens/information/information-route';
import { CommonRoutesNavigationActions } from '../../navigation-actions';
import { ItalyRoutesNavigationActions } from '../../navigation-actions/it';
import { PaymentItaly, navigateTo as toItalyPayment } from '../../../screens/payment/payment-route';
import {
  SVGIconsBrowser,
  toSVGIconsBrowser,
} from '../../../screens/svg-icons-browser/svg-icons-browser';
import {
  SpidTimeoutNextSteps,
  toSpidTimeoutNextSteps,
} from '../../../screens/spid-timeout-next-steps/spid-timeout-next-steps-container';

import { ItalyOnboarding, navigateTo as toItalyOnboarding } from './italy-onboarding';
import {
  RequiredDocumentsPreviewRoute,
  navigateTo as toRequiredDocumentsPreview,
} from './required-documents-preview';
import { ItalySubmissionRoute, navigateTo as toItalySubmission } from './italy-submission';
import { ItalyDonationRoute, toItalyDonation } from './italy-donation';
import {
  RequiredDocumentsDigitalCafRoute,
  navigateTo as toRequiredDocumentsDigitalCaf,
} from './digital-caf-required-documents';
import {
  DigitalCafSubCategoryRoute,
  navigateTo as toDigitalCafSubCategory,
} from './digital-caf-sub-category';
import RequiredDocumentsUploadRoute, {
  navigateTo as toRequiredDocumentsUpload,
} from './required-documents-upload';
import {
  toAnswersAddressSearch,
  answersAddressSearchScreen,
} from './answer-address-search-in-italy';
import { ProfilePersonalData, navigateTo as toProfilePersonalData } from './profile-personal-data';
import { ProfileOccupation, navigateTo as toProfileOccupation } from './profile-occupation';
import { ProfileDetails, toProfileDetails } from './profile-details';
import { ProfileEdit, toProfileEdit } from './profile-edit';
import { SubmissionsDownload, toSubmissionsDownload } from './submissions-download';
import { PhoneNumberItaly, toPhoneNumber } from './phone-number-italy';
import { BillingAddressRoute, toBillingAddress } from './billing-address';
import { SkippedSectionReminder, toSkippedSectionReminder } from './skipped-section-reminder';
import { toLogoutSuccess, LogoutSuccessScreen } from './logoutSuccess';
import { QuizMasterRoute, navigateTo as toQuizMaster } from './quiz-master';
import { toDashboard, Dashboard } from './dashboard';
import { ItalyGDPRConsent, toItalyGDPRConsent } from './italy-gdpr-consent';
import {
  toPrefillDocumentsResultList,
  PrefillDocumentsResultListRoute,
} from './prefill-documents-result-list-route';

export const universalActions = {
  toOnboardingSplash: toItalyOnboarding,
  toUniversalStatus: toDashboard,
  toUniversalNextRouteAfterOnboardingLogin: toDashboard,
  toUniversalOnboarding: toItalyOnboarding,
  toUniversalSwitchCountryUnauthenticated: toItalyOnboarding,
  toUniversalSwitchCountryAuthenticated: toDashboard,
};

declare global {
  interface Window {
    hj: (eventName: string, value: unknown) => void;
  }
}

// screens that exist in commonScreens but Italy overrides
const italyDuplicateScreens = [accountScreen];

// common screens without ones that Italy implements
const uniqueCommonScreens =
  commonScreens?.filter((screen) => !italyDuplicateScreens.includes(screen)) || [];

const italyNavigationActions: ItalyRoutesNavigationActions = {
  toPrefillDocumentUpload,
  toDrivingLicenseUpload,
  toPassportUpload,
  toRequiredDocumentsPreview,
  toItalyOnboarding,
  toRequiredDocumentsDigitalCaf,
  toRequiredDocumentsUpload,
  toDigitalCafSubCategory,
  toAnswersAddressSearch,
  toProfilePersonalData,
  toItalyDonation,
  toItalySubmission,
  toProfileOccupation,
  toProfileDetails,
  toProfileEdit,
  toSubmissionsDownload,
  toPhoneNumber,
  toBillingAddress,
  toDebugResponses,
  toDebugCWBIUrl,
  toSVGIconsBrowser,
  toImportResponses,
  toQuizMaster,
  toInformation,
  toLogoutSuccess,
  toItalyPayment,
  toSkippedSectionReminder,
  toDashboard,
  toTaxGuide,
  toDownloadAppScreen,
  toTaxExpert,
  toItalyChat,
  toItalyGDPRConsent,
  toPrivacySettingsIT,
  toDeleteAccountIT,
  toCloseAccountIT,
  toIdCardUpload,
  toSPIDConsentScreen,
  toInstantFilingNextStepsScreen,
  toPrefillDocumentsResult,
  toiFrameSPIDLoginScreen,
  toProductBundle,
  toProductBundleDetails,
  toSelfSignDocument,
  toPrefillDocumentsResultList,
  toModelloRedditiSign,
  toCUDocumentResultScreen,
  toResultOptimizationScreen,
  toSPIDConsentSign,
  toSpidTimeout,
  toSpidTimeoutNextSteps,
  toTailoredExperienceIntro,
  toTailoredExperienceFinal,
};

type Screen =
  | ScreenType
  | [
      string,
      {
        screen: React.FC<unknown>;
        options?: ScreenOptions;
      },
    ];

export const navigationActions: CommonRoutesNavigationActions & ItalyRoutesNavigationActions = {
  ...commonNavigationActions,
  ...universalActions,
  ...italyNavigationActions,
};

export const navigationActionsWithHjTracking = Object.fromEntries(
  Object.entries(navigationActions).map(([actionName, action]) => [
    actionName,
    (...args: unknown[]) => {
      // perform Hotjar update
      window.hj?.('stateChange', actionName);
      return action(...args);
    },
  ]),
);

export const italyCommercialistaTabScreens: CountryRoutesConfigType['screens'] = [
  [
    ScreenName.TaxExpert,
    {
      screen: TaxExpert,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
];

export const italyTaxGuideTabScreens: CountryRoutesConfigType['screens'] = [
  [
    ScreenName.TaxGuide,
    {
      screen: TaxGuide,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
];

export const italyChatTabScreens: CountryRoutesConfigType['screens'] = [
  [
    ScreenName.ItalyChat,
    {
      screen: ItalyChat,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
];

const italyScreens: Screen[] = [
  [
    ScreenName.Onboarding,
    {
      screen: ItalyOnboarding,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ItalyDonation,
    {
      screen: ItalyDonationRoute,
      options: {
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ItalySubmission,
    {
      screen: ItalySubmissionRoute,
    },
  ],
  [
    ScreenName.PrefillDocumentUpload,
    {
      screen: PrefillDocumentUploadRoute,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.DrivingLicenseUpload,
    {
      screen: DrivingLicenseUpload,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.PassportUpload,
    {
      screen: PassportUpload,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.CUDocumentResult,
    {
      screen: CUDocumentResultScreen,
      options: {
        footerShown: false,
        isFullHeight: true,
      },
    },
  ],
  [
    ScreenName.InstantFilingNextSteps,
    {
      screen: InstantFilingNextStepsScreenRoute,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.RequiredDocumentsDigitalCaf,
    {
      screen: RequiredDocumentsDigitalCafRoute,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.RequiredDocumentsUpload,
    {
      screen: RequiredDocumentsUploadRoute,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.DigitalCafSubCategory,
    {
      screen: DigitalCafSubCategoryRoute,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.RequiredDocumentsPreview,
    {
      screen: RequiredDocumentsPreviewRoute,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  answersAddressSearchScreen,
  [
    ScreenName.ProfilePersonalData,
    {
      screen: ProfilePersonalData,
      options: {
        isFullHeight: true,
      },
    },
  ],
  [
    ScreenName.ProfileOccupation,
    {
      screen: ProfileOccupation,
      options: {
        isFullHeight: true,
      },
    },
  ],
  [
    ScreenName.ProfileDetails,
    {
      screen: ProfileDetails,
      options: {
        isFullHeight: true,
      },
    },
  ],
  [
    ScreenName.ProfileEdit,
    {
      screen: ProfileEdit,
    },
  ],
  [
    ScreenName.SubmissionsDownload,
    {
      screen: SubmissionsDownload,
      options: {
        isFullHeight: true,
      },
    },
  ],
  [
    ScreenName.PhoneNumber,
    {
      screen: PhoneNumberItaly,
      options: {
        isFullHeight: true,
      },
    },
  ],
  [
    ScreenName.BillingAddress,
    {
      screen: BillingAddressRoute,
      options: {
        footerOptions: {
          centerContent: true,
        },
      },
    },
  ],
  [
    ScreenName.SkippedSectionReminder,
    {
      screen: SkippedSectionReminder,
    },
  ],
  [
    ScreenName.DebugResponses,
    {
      screen: DebugResponses,
    },
  ],
  [
    ScreenName.DebugCWBIUrl,
    {
      screen: DebugCWBIUrl,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.SVGIconsBrowser,
    {
      screen: SVGIconsBrowser,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ImportResponses,
    {
      screen: ImportResponses,
    },
  ],
  [
    ScreenName.QuizMaster,
    {
      screen: QuizMasterRoute,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.Information,
    {
      screen: InformationScreen,
    },
  ],
  [
    ScreenName.LogoutSuccess,
    {
      screen: LogoutSuccessScreen,
    },
  ],
  [
    ScreenName.PaymentItaly,
    {
      screen: PaymentItaly,
    },
  ],
  [
    ScreenName.Dashboard,
    {
      screen: Dashboard,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ItalyGDPRConsent,
    {
      screen: ItalyGDPRConsent,
      options: {
        footerShown: false,
        isFullHeight: true,
      },
    },
  ],
  [
    ScreenName.PrefillDocumentsResult,
    {
      screen: PrefillDocumentsResult,
      options: {
        footerShown: false,
        isFullHeight: true,
      },
    },
  ],
  downloadAppScreen,
  [
    Routes.Account,
    {
      screen: AccountScreen,
      options: {
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ItalyPrivacySettings,
    {
      screen: PrivacySettingsScreenIT,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ItalyDeleteAccountScreen,
    {
      screen: DeleteAccountScreenIT,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ItalyCloseAccount,
    {
      screen: CloseAccountScreenIT,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.IdCardUpload,
    {
      screen: IdCardUpload,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ItalySPIDConsentScreen,
    {
      screen: SPIDConsentScreen,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ItalyIFrameSPIDLogin,
    {
      screen: IFrameSPIDLoginScreen,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ProductBundle,
    {
      screen: ProductBundle,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ProductBundleDetails,
    {
      screen: ProductBundleDetails,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.SelfSignDocument,
    {
      screen: SelfSignDocument,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.PrefillDocumentsResultList,
    {
      screen: PrefillDocumentsResultListRoute,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ModelloRedditiSign,
    {
      screen: ModelloRedditiSignScreen,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.SPIDConsentSign,
    {
      screen: SPIDConsentSign,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.ResultOptimization,
    {
      screen: ResultOptimization,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.SpidTimeoutNextSteps,
    {
      screen: SpidTimeoutNextSteps,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.SpidTimeout,
    {
      screen: SpidTimeout,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.TailoredExperienceIntro,
    {
      screen: TailoredExperienceIntro,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
  [
    ScreenName.TailoredExperienceFinal,
    {
      screen: TailoredExperienceFinalScreen,
      options: {
        isFullHeight: true,
        footerShown: false,
      },
    },
  ],
];

export const screens = [
  ...uniqueCommonScreens,
  ...italyScreens,
  ...italyCommercialistaTabScreens,
  ...italyTaxGuideTabScreens,
  ...italyChatTabScreens,
];
