import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'redux';
import Config from 'react-native-config';
import { Prefill } from '@taxfix/italy-sdk';
import { CountryCodes } from '@taxfix/types';
import { WebViewNavigation } from 'react-native-webview';
import { Linking } from 'react-native';

import { selectors as settingsSelectors } from 'src/stores/modules/settings';
import { selectors as userAuthSelectors } from 'src/stores/modules/user-auth';
import { selectors as firebaseSelectors } from 'src/stores/modules/remote-config-firebase';
import {
  getNodesByIds,
  getQuizmasterLight,
  QuizmasterLight,
  withQuizmasterLight,
} from 'src/utils/with-quizmaster-light';
import { flagsQuestionIds, prefillQuestionIds } from 'src/common/constants-it';
import { useNavigation } from 'src/hooks/navigation-hook';
import { actions as overlayActions } from 'src/stores/modules/overlay';
import { getQuestionStoreByYearAndCountry } from 'src/stores-legacy/helpers';
import { getStore } from 'src/stores/util';
import { isIOS } from 'src/utils/platform';
import Analytics, { AnalyticsEvent } from 'src/biz-logic/analytics';
import { logErrorMessages } from 'src/common/log-error-messages';
import { logger, ErrorType } from 'src/taxfix-business-logic/utils/logger';

import { IFrameSPIDLoginComponent } from './iframe-spid-login-component';
import { CWBIMockServerSPIDMessage, PreSeasonSpidMessage } from './constants';

type Props = {
  quizmasterLight: QuizmasterLight;
};

enum FolderStatus {
  SUCCEED = 'AUTHORIZED',
  ERROR = 'ERROR_500',
}

const IFrameSPIDLoginContainer: React.FC<Props> = ({ quizmasterLight }) => {
  const { getNavigationActions, safeResetNavigation } = useNavigation();
  const dispatch = useDispatch();

  const accessToken = useSelector(userAuthSelectors.getAccessToken);
  const email = useSelector(userAuthSelectors.getEmailAddress) as string; // TODO fix with a guard
  const year = useSelector(settingsSelectors.selectedYear) as number; // TODO fix with a guard
  const documentList = useSelector(firebaseSelectors.getCWBIFolderRequestFirebase).documentList;
  const { mockServerEnabled, whitelistedTaxIds } = useSelector(
    firebaseSelectors.getCwbiMockServerConfig,
  );

  const { value: cwbiPrivacyPolicyUrlIdentifier } = useSelector(
    firebaseSelectors.getCwbiPrivacyPolicyUrlIdentifier,
  );

  const [SPIDLoginUrl, setSPIDLoginUrl] = useState<string>('');
  const [folderId, setFolderId] = useState<number>(-1);
  const [isLoading, setIsLoading] = useState(false);

  // populate tax ID answer - at the moment when we render screen, tax ID was not yes available
  // it throws error as descripbed in https://taxfix.atlassian.net/browse/ITA-2970
  const questionStore = getQuestionStoreByYearAndCountry(getStore()?.getState());
  const nodes = getNodesByIds(questionStore, [prefillQuestionIds.preseasonTaxId]);
  const quizmaster = getQuizmasterLight(questionStore, nodes);
  const taxId = quizmaster[prefillQuestionIds.preseasonTaxId]?.answer;
  const isMock = mockServerEnabled && whitelistedTaxIds.includes(taxId);

  const handleOnPressOverlayRetryNowButton = useCallback(
    (callback: () => void) => {
      dispatch(overlayActions.hide());
      callback();
    },
    [dispatch],
  );

  const handleOnPressOverlayRetryLaterButton = useCallback(() => {
    dispatch(overlayActions.hide());
    safeResetNavigation([getNavigationActions().toDashboard('screen')]);
  }, [dispatch, getNavigationActions, safeResetNavigation]);

  const showOverlayError = useCallback(
    (handleOnPressRetryNowButton: () => void) => {
      dispatch(
        overlayActions.show('SPIDError', {
          handleOnPressRetryNowButton,
          handleOnPressRetryLaterButton: handleOnPressOverlayRetryLaterButton,
        }),
      );
    },
    [dispatch, handleOnPressOverlayRetryLaterButton],
  );

  const getIframeUrl = useCallback(async () => {
    setIsLoading(true);
    try {
      const folderRequest = await Prefill.getCwbiFolderRequest(Config.API_BASE_URL, accessToken, {
        year: year + 1, // this year parameter can be changed to test previous year documents
        taxId,
        email,
        countryCode: CountryCodes.IT,
        documentList,
        isMock,
      });
      setFolderId(folderRequest.folderRequestId);
      setSPIDLoginUrl(folderRequest.folderRequestURL);
    } catch (error) {
      logger.error(error as ErrorType, {
        message: logErrorMessages.cwbiFolderRequestError,
      });

      showOverlayError(() => handleOnPressOverlayRetryNowButton(getIframeUrl));
    } finally {
      setIsLoading(false);
    }
  }, [
    accessToken,
    year,
    email,
    showOverlayError,
    handleOnPressOverlayRetryNowButton,
    documentList,
    isMock,
    taxId,
  ]);

  const onFolderStatusSuccess = useCallback(
    (numberDocumentsIds: number, estimatedRefund: string, reasonWhyRefundIsZero?: string) => {
      quizmasterLight[flagsQuestionIds.preseasonCUDocument].saveAnswer(numberDocumentsIds > 0);
      quizmasterLight[flagsQuestionIds.prefilledCreditResult].saveAnswer(estimatedRefund);
      if (reasonWhyRefundIsZero) {
        quizmasterLight[flagsQuestionIds.reasonWhyRefundIsZero].saveAnswer(reasonWhyRefundIsZero);
      }

      safeResetNavigation([
        getNavigationActions().toDashboard('screen'),
        getNavigationActions().toCUDocumentResultScreen('screen'),
      ]);
    },
    [getNavigationActions, quizmasterLight, safeResetNavigation],
  );

  const getFolderStatus = useCallback(async () => {
    try {
      const pollFolder = await Prefill.getFolderStatus(
        Config.API_BASE_URL,
        accessToken,
        folderId,
        {},
        isMock,
        true,
      );

      if (pollFolder.status === FolderStatus.SUCCEED) {
        onFolderStatusSuccess(
          pollFolder.documentsIds.length,
          String(pollFolder.estimatedRefund),
          pollFolder?.reasonWhyRefundIsZero,
        );
      }

      if (pollFolder.status === FolderStatus.ERROR) {
        showOverlayError(() => handleOnPressOverlayRetryNowButton(getIframeUrl));
      }
    } catch (error) {
      logger.error(error as ErrorType, {
        message: logErrorMessages.cwbiPollFolderStatusError,
      });
      showOverlayError(() => handleOnPressOverlayRetryNowButton(getIframeUrl));
    }
  }, [
    accessToken,
    folderId,
    onFolderStatusSuccess,
    showOverlayError,
    handleOnPressOverlayRetryNowButton,
    getIframeUrl,
    isMock,
  ]);

  // Step 1
  useEffect(() => {
    getIframeUrl();
  }, [accessToken, email, getIframeUrl, taxId, year]);

  // Error from CWBI FrontEnd
  const onError = useCallback(() => {
    showOverlayError(() => handleOnPressOverlayRetryNowButton(getIframeUrl));
  }, [handleOnPressOverlayRetryNowButton, getIframeUrl, showOverlayError]);

  const onSpidCredentialsErrorShown = useCallback((isValidationFailed: boolean) => {
    if (isValidationFailed) {
      Analytics.log(AnalyticsEvent.spidCredentialsValidationFailed);
    } else {
      Analytics.log(AnalyticsEvent.spidCredentialsErrorShown);
    }
  }, []);

  const onShouldStartLoadWithRequest = useCallback(
    (event: WebViewNavigation) => {
      // a workaround for iOS to open the privacy policy of CWBI in the mobile browser
      // iOS requires opening the link in a new page explicitly,
      // otherwise it will open the link in the same iFrame
      if (isIOS && event.url.toLowerCase().includes(cwbiPrivacyPolicyUrlIdentifier)) {
        Linking.openURL(event.url);
        return false;
      }

      return true;
    },
    [cwbiPrivacyPolicyUrlIdentifier],
  );

  const onSpidFlowFinished = useCallback(() => {
    getFolderStatus();
  }, [getFolderStatus]);

  const onMessage = useCallback(
    (event: { nativeEvent: { data: string } }): void => {
      const data: string = event.nativeEvent.data;
      switch (data) {
        case PreSeasonSpidMessage.errorScreenShown:
          onError();
          break;
        case PreSeasonSpidMessage.credentialsValidationFailed:
          onSpidCredentialsErrorShown(true);
          break;
        case PreSeasonSpidMessage.credentialsErrorShown:
          onSpidCredentialsErrorShown(false);
          break;
        case PreSeasonSpidMessage.spidFlowAuthorized:
        case PreSeasonSpidMessage.spidFlowRejected:
        case PreSeasonSpidMessage.spidFlowExpired:
        case PreSeasonSpidMessage.spidFlowError:
        case CWBIMockServerSPIDMessage.authorized:
          onSpidFlowFinished();
          break;
      }
    },
    [onError, onSpidCredentialsErrorShown, onSpidFlowFinished],
  );

  return (
    <IFrameSPIDLoginComponent
      SPIDLoginUrl={SPIDLoginUrl}
      onError={onError}
      onMessage={onMessage}
      onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
      isLoading={isLoading}
    />
  );
};

export const IFrameSPIDLoginPreseason = compose<React.ComponentType>(
  withQuizmasterLight([
    flagsQuestionIds.productBundleSelection,
    flagsQuestionIds.prefilledCreditResult,
    flagsQuestionIds.reasonWhyRefundIsZero,
    flagsQuestionIds.preseasonCUDocument,
  ]),
)(IFrameSPIDLoginContainer);
