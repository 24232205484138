import * as React from 'react';
import { Keyboard, StyleSheet } from 'react-native';
import { connect } from 'react-redux';

import initial from '../stores/store/initial';
import { Box, BodyTitleText, theme, Image } from '../components/core';
import { selectors } from '../stores/modules/internet-connection';

const styles = StyleSheet.create({
  internetContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'white',
  },
  internetErrorText: {
    marginTop: 30,
    width: '60%',
    fontSize: 18,
    color: theme.color.primaryText,
    textAlign: 'center',
  },
});

const InternetDisconnect = () => (
  <Box alignHorizontally="center" alignVertically="center" style={styles.internetContainer}>
    <Image name="error.error-no-connection" />
    <BodyTitleText style={styles.internetErrorText} id="account.error.no-connection" />
  </Box>
);

type Props = {
  isConnected: boolean;
};

const mapStateToProps = (state: typeof initial) => ({
  isConnected: selectors.isConnected(state),
});

export const withInternetConnection = (WrappedComponent: any) => {
  class Enhancer extends React.PureComponent<any & Props> {
    componentDidUpdate(prevProps: Props) {
      if (prevProps.isConnected && !this.props.isConnected) {
        // internet connection lost
        Keyboard.dismiss();
      }
    }

    render() {
      const { isConnected, ...rest } = this.props;
      return (
        <Box flex>
          <WrappedComponent {...rest} />
          {!isConnected && <InternetDisconnect />}
        </Box>
      );
    }
  }

  return connect(mapStateToProps, null)(Enhancer);
};
