import { Documents as DocumentsSDK } from '@taxfix/taxfix-sdk';
import { DocumentsTypes } from '@taxfix/documents-sdk';
import { CountryCodes, Years } from '@taxfix/types';
import Config from 'react-native-config';
import { Document } from '@taxfix/taxfix-sdk/dist/documents/types';
import { Prefill } from '@taxfix/italy-types';
import { PrefillTypes } from '@taxfix/italy-sdk';

import {
  CWBIRequestDocumentConfig,
  ItalyCWBIDocumentsDownloadConfig,
} from '../../stores/modules/remote-config-firebase';

import { prefillResultScenarios } from './constants';
import { ScreenState } from './types';
import { prefillResultScenariosExperiment } from './constants-experiment';

export enum ResultType {
  Refund = 'refund',
  Shortfall = 'shortfall',
  Even = 'even',
  NotAvailable = 'notAvailable',
}

export const getPrefillDocuments = async (
  documentTypes: DocumentsTypes.v1.Documents.NonReceiptTypes[],
  token: string,
  userId?: number,
  year?: Years | undefined,
): Promise<Document[]> => {
  const url = Config.API_BASE_URL;
  const requestBody = {
    userId,
    year,
    types: documentTypes,
    countryCode: CountryCodes.IT,
    states: [DocumentsTypes.v1.Documents.States.Created],
  };

  // TODO change this to documents-sdk method
  const { data: documents } = await DocumentsSDK.get(url, token, requestBody);

  if (documents.length > 1) {
    // sort given documents by type alphabetically to make sure CU is the first type
    return documents.sort((doc1, doc2) => doc1.type.localeCompare(doc2.type));
  } else {
    return documents;
  }
};

export const getPrefillDocumentTypes = (italyDocumentsConfig: ItalyCWBIDocumentsDownloadConfig) => {
  return italyDocumentsConfig.syncDownload
    .map((document: CWBIRequestDocumentConfig) => document.taxfixPdfType)
    .filter(Boolean);
};

export const mapCreditResultForAnalytics = (result: number): ResultType => {
  switch (true) {
    case result > 0:
      return ResultType.Refund;
    case result < 0:
      return ResultType.Shortfall;
    case result === 0:
      return ResultType.Even;
    default:
      return ResultType.NotAvailable;
  }
};

export const resolveFileName = (document: Document, index: number): string => {
  const { metadata, type, year } = document;
  const originalFileName = metadata ? JSON.parse(metadata.toString())?.originalName || '' : '';
  const originalFileNameSplitted = originalFileName.split('_');

  if (
    [
      DocumentsTypes.v1.Documents.NonReceiptTypes.CUPdf,
      DocumentsTypes.v1.Documents.NonReceiptTypes.CUPreseasonPdf,
    ].includes(type as DocumentsTypes.v1.Documents.NonReceiptTypes)
  ) {
    // e.g T210314112312450450001540.pdf -> CU1, CU1, CU3
    return `CU${index + 1}`;
  } else if (type === DocumentsTypes.v1.Documents.NonReceiptTypes.ModUniPrePdf) {
    return `Modello Redditi ${year + 1} precompilato`;
  } else if (originalFileNameSplitted.length > 2) {
    // e.g. 73023_ precompilato_ZRSSLV83C56C933T -> 73023_ precompilato
    return originalFileNameSplitted.slice(0, 2).join('_');
  } else {
    return originalFileName;
  }
};

export const getPreviewImage = (
  type: DocumentsTypes.v1.Documents.NonReceiptTypes,
): string | undefined => {
  switch (type) {
    case DocumentsTypes.v1.Documents.NonReceiptTypes.CUPdf:
      return 'cuPreview';
    case DocumentsTypes.v1.Documents.NonReceiptTypes.Mod730PrePdf:
      return 'mod730Preview';
    default:
      return undefined;
  }
};

type PrefillResult = {
  screenContent: ScreenState;
  screenContentExperiment: ScreenState;
  estimatedResult: number;
  optimizations: PrefillTypes.DownloadPrefillDocuments.Optimization[];
  totalOptimization: number;
};
export const getPrefillResult = (
  estimatedResultAsString: string,
  taxScenario: Prefill.PrefillResultScenarios,
  taxOptimizations: string | undefined,
): PrefillResult => {
  const estimatedResult: number = parseFloat(estimatedResultAsString);
  const optimizations: PrefillTypes.DownloadPrefillDocuments.Optimization[] = taxOptimizations
    ? JSON.parse(taxOptimizations)
    : [];

  const totalOptimization = optimizations.reduce(
    (acc, optimization) => acc + optimization.value,
    0,
  );

  const screenContent = prefillResultScenarios[taxScenario];
  const screenContentExperiment = prefillResultScenariosExperiment[taxScenario];
  return {
    screenContent,
    screenContentExperiment,
    estimatedResult,
    optimizations,
    totalOptimization,
  };
};

export const isNegativeNoOptimization = (taxScenario: Prefill.PrefillResultScenarios): boolean => {
  return [
    Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryNoCedolare,
    Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryCedolare,
    Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryCedolareNoDays,
    Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryNoCedolareNoDays,
  ].includes(taxScenario);
};

export const isPrefillDocumentsResultListScenario = (
  taxScenario: Prefill.PrefillResultScenarios,
): boolean => {
  return [
    Prefill.PrefillResultScenarios.PositiveNoOptimization,
    Prefill.PrefillResultScenarios.PositiveNoOptimizationMandatoryCedolare,
    Prefill.PrefillResultScenarios.PositiveNoOptimizationMandatoryNoCedolare,
    Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryCedolare,
    Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryCedolareNoDays,
    Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryNoCedolare,
    Prefill.PrefillResultScenarios.NegativeNoOptimizationMandatoryNoCedolareNoDays,
    Prefill.PrefillResultScenarios.ResultZeroNoOptimization,
    Prefill.PrefillResultScenarios.NAMandatory,
  ].includes(taxScenario);
};
