import React from 'react';
import { StyleSheet } from 'react-native';

import EmailChangeBanner from '../../containers/email-change-banner';
import { theme, Box } from '../../components/core';
import { translationKeyForError } from '../login/Login';
import { LoginErrorKey } from '../login/Login.types';
import CredentialScrollAware from '../../components/auth/CredentialScrollAware';

const styles = StyleSheet.create({
  containerError: {
    backgroundColor: theme.color.errorBackground,
  },
  text: {
    color: theme.color.primaryText,
    marginBottom: 30,
  },
  textError: {
    color: theme.color.errorTextDark,
  },
  titleStyle: {
    paddingBottom: 0,
  },
});
type EmailPassword = {
  emailAddress: string;
  password: string;
};
type Props = {
  emailAddress: string;
  password: string;
  confirmDisabled: boolean;
  loading: boolean;
  errorKey?: LoginErrorKey;
  onChange: (value: EmailPassword) => void;
  onConfirm: (value: EmailPassword) => void;
  onForgot: () => void;
  autoFocusPin?: boolean;
  variants?: any;
};

export const Lock = (props: Props): JSX.Element => {
  const {
    emailAddress,
    password,
    confirmDisabled,
    loading,
    onForgot,
    errorKey,
    autoFocusPin = true,
    variants,
    onConfirm,
    onChange,
  } = props;

  const handlePasswordChange = (password: string): void => {
    onChange({
      emailAddress,
      password,
    });
  };

  const handleConfirm = (): void => {
    onConfirm({
      emailAddress,
      password,
    });
  };

  return (
    <Box top={2} flex={1}>
      <EmailChangeBanner shouldPoll showWhenPending={false} />
      <CredentialScrollAware
        scrollable
        confirmKey="account.lock.confirm"
        onConfirm={handleConfirm}
        disabled={confirmDisabled}
        loading={loading}
        imageName={null}
        errorKey={errorKey ? translationKeyForError(errorKey) : null}
        titleKey="account.lock.title"
        titleStyle={styles.titleStyle}
        pinOffset={220}
        isLockedEmail
        email={emailAddress}
        pinType="enter"
        pin={password}
        onPinChange={handlePasswordChange}
        onPinForgot={onForgot}
        autoFocusPin={autoFocusPin}
        centered
        {...variants}
      />
    </Box>
  );
};
