import { actions as settings } from '../modules/settings';
import { actions as identifications } from '../modules/identifications';
import { actions as userConsents } from '../modules/user-consents';
import { actions as places } from '../modules/places';
import { actions as overlay } from '../modules/overlay';
import { actions as user } from '../modules/user';
import { actions as partner } from '../modules/partner';
import { actions as bankDetails } from '../modules/bank-details';
import { actions as syncAnswers } from '../modules/sync-answers';
import { actions as internetConnection } from '../modules/internet-connection';
import { actions as submission } from '../modules/submission';
import { actions as prefill } from '../modules/prefill';
import { actions as status } from '../modules/status';
import { actions as requiredDocuments } from '../modules/required-documents';
import { actions as remoteConfigFirebase } from '../modules/remote-config-firebase';
import { actions as migration } from '../modules/migration';
import { actions as devTools } from '../modules/dev-tools';
import { actions as flow } from '../modules/flow-legacy';
import { actions as dynamicLink } from '../modules/dynamic-link';
import { actions as userAuth } from '../modules/user-auth';
import { actions as referralHistory } from '../modules/referral-history';
import { actions as checkoutFlow } from '../modules/checkout-flow';
import { actions as userEmailChange } from '../modules/user-email-change';
import { actions as lock } from '../modules/lock';
import { actions as taxSystem } from '../modules/tax-system';
import { actions as questions } from '../modules/questions';
import { actions as payment } from '../modules/payment';

export default {
  settings,
  identifications,
  userConsents,
  places,
  overlay,
  user,
  partner,
  bankDetails,
  syncAnswers,
  internetConnection,
  submission,
  prefill,
  status,
  requiredDocuments,
  remoteConfigFirebase,
  migration,
  devTools,
  flow,
  dynamicLink,
  userAuth,
  referralHistory,
  userEmailChange,
  lock,
  taxSystem,
  checkoutFlow,
  questions,
  payment,
};
