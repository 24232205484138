import React, { useEffect, useState, useCallback } from 'react';
import { View, StyleSheet, ScrollView } from 'react-native';

import { LoadingDebugging } from '../containers/loading-debugging';
import { EnhancedLoadingConfig } from '../stores/modules/status';

import { theme } from './core';
import { FancyLoading } from './fancy-loading';

const styles = StyleSheet.create({
  overlay: {
    position: 'absolute',
    zIndex: 0,
    width: '100%',
    height: '100%',
    paddingHorizontal: 32,
    backgroundColor: theme.color.lightFill,
  },
  postLoading: {
    paddingHorizontal: 0,
  },
});
type Props = {
  onClose: () => void;
  duration: number;
  shouldClose?: boolean;
  enhancedLoadingConfig: EnhancedLoadingConfig;
};
const SIMPLE_LOADING_TIMEOUT = 4000;
const BEFORE_CLOSING_TIMEOUT = 1000;
const PROGRESS_UPDATE_INTERVAL = 500;
let intervalId: NodeJS.Timeout;

const defaultProgressTranslationKeys = {
  fewSecondsLeft: 'fancy-loading.progress.few-seconds',
  lessThanOneMin: 'fancy-loading.remaining.singular',
  moreThanOneMin: 'fancy-loading.remaining.plural',
};
export const LoadingOverlay = ({
  onClose,
  duration,
  shouldClose = false,
  enhancedLoadingConfig,
}: Props) => {
  const [timer, setTimer] = useState(0);
  const [showingSimpleLoading, setShowingSimpleLoading] = useState(true);
  const [showPostLoadingScreen, setShowPostLoadingScreen] = useState(false);
  const minutesRemain: number = (duration - timer) / 60000;
  const {
    enabled: isEnhancedLoadingScreenEnabled,
    renderAfterLoadingFinished: PostLoadingScreen,
  }: any = enhancedLoadingConfig;

  if (timer >= duration) {
    clearInterval(intervalId);
  }

  const closeEnhancedLoading = useCallback(() => {
    if (PostLoadingScreen) {
      setShowPostLoadingScreen(true);
    } else {
      onClose();
    }
  }, [onClose, PostLoadingScreen]);

  const getProgressTranslation = () => {
    const { progressSubtitleTranslationKeys = defaultProgressTranslationKeys } =
      enhancedLoadingConfig;
    if (minutesRemain > 1) return progressSubtitleTranslationKeys.moreThanOneMin;
    if (minutesRemain > 0.5) return progressSubtitleTranslationKeys.lessThanOneMin;
    if (minutesRemain > 0 && minutesRemain <= 0.5)
      return progressSubtitleTranslationKeys.fewSecondsLeft;
    return undefined;
  };

  // handle timer
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isEnhancedLoadingScreenEnabled) {
      timeoutId = setTimeout(() => {
        setShowingSimpleLoading(false);
        intervalId = setInterval(
          () => setTimer((c) => c + PROGRESS_UPDATE_INTERVAL),
          PROGRESS_UPDATE_INTERVAL,
        );
      }, SIMPLE_LOADING_TIMEOUT);
    }

    return function cleanup() {
      if (isEnhancedLoadingScreenEnabled) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
      }
    };
  }, [isEnhancedLoadingScreenEnabled]);
  // handle delayed closing
  useEffect(() => {
    if (shouldClose) {
      clearInterval(intervalId);

      if (showingSimpleLoading) {
        onClose();
      } else {
        setTimer(duration);
        setTimeout(closeEnhancedLoading, BEFORE_CLOSING_TIMEOUT);
      }
    }
  }, [shouldClose, showingSimpleLoading, duration, onClose, closeEnhancedLoading]);
  if (showPostLoadingScreen && PostLoadingScreen)
    return (
      <View style={[styles.overlay, styles.postLoading]}>
        <PostLoadingScreen onClose={onClose} />
      </View>
    );
  return showingSimpleLoading ? (
    <View style={styles.overlay}>
      <LoadingDebugging
        titleId="status.screen.loading.title"
        subTitleId="status.screen.loading.subtitle"
      />
    </View>
  ) : (
    <ScrollView style={styles.overlay}>
      <FancyLoading
        titleTranslationKey={enhancedLoadingConfig.titleTranslationKey}
        progress={timer / duration}
        progressSubtitleTranslationKey={getProgressTranslation()}
        values={{
          minutes: Math.ceil(minutesRemain),
        }}
      />
    </ScrollView>
  );
};
