import React from 'react';
import { Text as NBText } from 'native-base';

import { TextProps } from './types';

export const Text = ({ variant, value, children, ...rest }: TextProps) => {
  return (
    <NBText variant={variant} {...rest}>
      {children || value}
    </NBText>
  );
};
export { TextComponentVariants } from './styles';
