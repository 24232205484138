import React, { useMemo } from 'react';
import { ScrollView, Stack, useBreakpointValue } from 'native-base';
import { useSelector } from 'react-redux';

import { Container, NavButton, Text } from 'src/taxfix-components/src';
import LinkableText from 'src/components/auth/LinkableText';
import { ProductBundleValues } from 'src/common/constants-it';
import { useLargeScreenQuery } from 'src/components/core';
import { useNavigation } from 'src/hooks/navigation-hook';
import { isMobileScreen, isWeb } from 'src/utils/platform';

import { useItalyIntl } from '../_hooks';
import Analytics, { AnalyticsEvent } from '../../biz-logic/analytics';
import { ScreenName } from '../../types/screen-name';
import { useNumberFormatter } from '../../utils/useNumberFormatter';
import { selectors as remoteConfigSelectors } from '../../stores/modules/remote-config-firebase';

import {
  ProductBundleCard,
  ProductBundleCardProps,
} from './product-bundle-card/product-bundle-card';
import { ProductBundlesPrices } from './use-product-prices';

type ProductBundleComponentProps = {
  onProductSelect: (selection: ProductBundleValues) => void;
  onBackButtonPress?(): void;
  productBundlesPrices: ProductBundlesPrices;
  recommendedProductBundle: ProductBundleValues;
  isExperiment: boolean;
};

type CreateBundleCard = ProductBundleCardProps & { displayRecommended: boolean };

export const ProductBundleComponent: React.FC<ProductBundleComponentProps> = ({
  onProductSelect,
  onBackButtonPress,
  recommendedProductBundle,
  productBundlesPrices,
  isExperiment,
}) => {
  const { getTranslationText } = useItalyIntl();
  const { navigationActions } = useNavigation();
  const isLargeScreen = useLargeScreenQuery();

  const titleVariant = useBreakpointValue({
    base: 'titleSBold',
    md: 'titleMBold',
    lg: 'titleLBold',
  });
  const pageTitleMarginTop = useBreakpointValue({ base: isWeb ? '24px' : 0, md: '76px' });
  const cardsContainerDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const cardsContainerSpacing = useBreakpointValue({ base: '16px', md: '20px' });
  const cardsContainerWidth = useBreakpointValue({ base: 'full', md: '740px', lg: '780px' });
  const linkButtonMarginTop = useBreakpointValue({ base: '0px', lg: '60px' });
  const linkButtonVerticalPadding = useBreakpointValue({ base: '24px', lg: '0px' });
  const paymentConfig = useSelector(remoteConfigSelectors.getItalyProductsPaymentConfig).default;
  const androidShadowOverflow = '24px';

  const handlePressBundlesDetails = () => {
    Analytics.log(AnalyticsEvent.buttonPressed, {
      buttonName: 'productBundleDetails',
      screenName: ScreenName.ProductBundle,
      bundleRecommendation: recommendedProductBundle,
    });
    navigationActions.toProductBundleDetails('screen');
  };

  const bundleCards = useMemo(() => {
    const createBundleCard = (
      type: ProductBundleValues,
      isRecommended: boolean,
      price: number,
      isFree?: boolean,
      isExperiment?: boolean,
    ): CreateBundleCard => {
      const getFormattedNumber = useNumberFormatter();
      const priceFormated = getFormattedNumber(price);

      return {
        title: `it.product-bundle-screen.cards.${type}-declaration.title`,
        descriptionItems: isRecommended
          ? [
              `it.product-bundle-screen.cards.${type}-declaration.recommended.description-item-1`,
              `it.product-bundle-screen.cards.${type}-declaration.recommended.description-item-2`,
              `it.product-bundle-screen.cards.${type}-declaration.recommended.description-item-3`,
            ]
          : [
              `it.product-bundle-screen.cards.${type}-declaration.description-item-1`,
              `it.product-bundle-screen.cards.${type}-declaration.description-item-2`,
              `it.product-bundle-screen.cards.${type}-declaration.description-item-3`,
            ],
        imageComponentKey: `${type}Declaration`,
        buttonLabel: getTranslationText(
          isFree
            ? 'it.product-bundle-screen.cards.declaration.free'
            : `it.product-bundle-screen.cards.${type}-declaration.price`,
          {
            priceFormated,
          },
        ),
        onPressCard: () => onProductSelect(type),
        recommended: isRecommended && (isExperiment || type !== ProductBundleValues.instant),
        displayRecommended: isRecommended,
      };
    };

    const isRecommendedInstant = recommendedProductBundle === ProductBundleValues.instant;
    const isRecommendedGuided = recommendedProductBundle === ProductBundleValues.guided;

    const bundles = [
      createBundleCard(
        ProductBundleValues.instant,
        isRecommendedInstant,
        productBundlesPrices.instant,
        paymentConfig.instant.isFree,
        isExperiment,
      ),
      createBundleCard(
        ProductBundleValues.guided,
        isRecommendedGuided,
        productBundlesPrices.guided,
        paymentConfig.guided.isFree,
        isExperiment,
      ),
    ];

    // sort the product bundle cards based on the recommended property,
    // showing that one always first
    return bundles.sort((a, b) => Number(b.displayRecommended) - Number(a.displayRecommended));
  }, [
    onProductSelect,
    recommendedProductBundle,
    productBundlesPrices,
    getTranslationText,
    paymentConfig,
    isExperiment,
  ]);

  const LinkButton = () => (
    <Container
      justifyContent="center"
      alignItems="center"
      mt={linkButtonMarginTop}
      py={linkButtonVerticalPadding}
    >
      <LinkableText
        translationKey="it.product-bundle-screen.bundles-details.link-text"
        onPress={handlePressBundlesDetails}
      />
    </Container>
  );

  return (
    <>
      {isLargeScreen && onBackButtonPress && (
        <Container position="absolute" top={0} zIndex={1}>
          <NavButton
            onPress={onBackButtonPress}
            text={getTranslationText('it.product-bundle-screen.back-button.text')}
          />
        </Container>
      )}

      <Text
        mt={pageTitleMarginTop}
        textAlign="center"
        variant={titleVariant}
        value={getTranslationText(
          isExperiment
            ? 'it.product-bundle-screen.experiment.title.select-plan'
            : 'it.product-bundle-screen.title.select-plan',
        )}
      />

      {isExperiment && (
        <Text
          mt="10px"
          textAlign="center"
          variant={isLargeScreen ? 'bodyMBook' : 'bodySBook'}
          color="greytones.textLight"
          value={getTranslationText('it.product-bundle-screen.experiment.description.select-plan')}
        />
      )}
      <ScrollView mt={{ base: '24px', md: '40px' }}>
        <Stack
          direction={cardsContainerDirection}
          alignSelf="center"
          space={cardsContainerSpacing}
          width={cardsContainerWidth}
          pb={androidShadowOverflow}
        >
          {bundleCards.map((bundle) => (
            <ProductBundleCard key={`productBundleCard_${bundle?.title}`} {...bundle} />
          ))}
        </Stack>

        {!isMobileScreen && <LinkButton />}
      </ScrollView>

      {isMobileScreen && <LinkButton />}
    </>
  );
};
